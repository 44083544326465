import React, {useEffect, useState} from 'react';
import './Login.css';
import {InputText} from "primereact/inputtext";
import logo from "../resources/basic-graphics/VisualOKRLogoBlack.png";
import {Button} from "primereact/button";
import {SendForgottenPasswordMail, SendWelcomeMail} from "../global-code/hooks/useCommunication";
import {confirmDialog} from "primereact/confirmdialog";
import {IsValueEmptyOrUndefined} from "../global-code/hooks/useGenericFunctions";
import {deleteAllSessionVariablesAndCookies} from "../global-code/hooks/useSecurity";

export const Login = props => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const [values, setValues] = useState({
        loginName: "",
        password: "",
    });

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validateFormInput(values));
        deleteAllSessionVariablesAndCookies();
        setIsSubmitting(true);
    };

    const showMissingEmailAddressMessage = () => {
        confirmDialog({
            message: 'Please fill in your e-mail in the login name field and try again',
            header: 'Please enter e-mail',
            icon: 'pi pi-flag',
            acceptLabel: 'OK',
            rejectClassName: 'hide'
        });
    };

    const showEmailSentMessage = () => {
        confirmDialog({
            message: 'An e-mail with a link for changing password has been sent',
            header: 'Success',
            icon: 'pi pi-check',
            acceptLabel: 'OK',
            rejectClassName: 'hide'
        });
    };

    const validateUserOnBackend = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "security/validateUser/" + values.loginName + "/" + values.password, {
                method: "GET",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            });

            let resJson = await res.json();

            if (res.status === 200) {
                props.doAfterLogin(resJson);
            }
            
            if (res.status === 401) {
                showUserNotValidatedMessage();
            }
        } catch (err) {
            console.log(err);
            showUserNotValidatedMessage();
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            validateUserOnBackend();
        }
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

    function showUserNotValidatedMessage() {
        alert("Login name or password was inccorrect");
    }

    function validateFormInput(values) {
        let errors = {};

        if (!values.loginName.trim()) {
            errors.loginName = "Please enter your login name";
        }

        if (values.loginName.trim().length > 100) {
            errors.title = "Login name can only be 100 characters long";
        }

        if (!values.password.trim()) {
            errors.password = "Please enter your password";
        }

        return errors;
    }
    
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    function sendForgottenPasswordMail() {
        let email = document.getElementById("loginName").value;
        
        if (IsValueEmptyOrUndefined(email)) {
            showMissingEmailAddressMessage();
        }
        else {
            SendForgottenPasswordMail(email, showEmailSentMessage);
        }
    }

    return (
        <>
            <div className="login-container">
                <div className="login-card">
                    <div className="dialog-header">
                        L O G I N
                    </div>
                    <div className="logo-container">
                        <div className="VOKR-logo"><img src={logo} alt='Company Logo'/></div>
                    </div>
                    <div className="form-container">
                        <form id="loginForm" onSubmit={handleSubmit}>
    
                            <div className="field-wrapper">
                                <div className="input-field-title-container">Login name</div>
                                    <InputText id="loginName" name="loginName" autoFocus className="fill-container-width" type="text" value={values.loginName} onChange={handleChange} />
                                    {errors.loginName && <p className="error-message" style={{top: "260px"}}>{errors.loginName}</p>}
                            </div>

                            <div className="field-wrapper">
                                <div className="input-field-title-container">Password</div>
                                <span>
                                    <InputText name="password" className="fill-container-width" type="password" value={values.password} onChange={handleChange} />
                                    {errors.password && <p className="error-message" style={{top: "360px"}}>{errors.password}</p>}
                                </span>
                            </div>
                            
                            <div className="forgot-password" onClick={sendForgottenPasswordMail}>Forgot password</div>

                            <Button className="login-button" label="Login" type="submit" />

                        </form>
                    </div>
                    <div className="payoff">Strategy In Motion</div>
                </div>
            </div>
        </>
    )
}

