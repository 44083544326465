import React, {useState} from 'react';
import './AddActivityElement.css';
import {InputText} from "primereact/inputtext";
import {UserSelector} from "../UserSelector";
import {IsValueEmptyNullOrUndefined} from "../../../global-code/hooks/useGenericFunctions";
import {Button} from "primereact/button";

export const AddActivityElement = (props) => {
    const [inputMode, setInputMode] = useState(false);
    const [responsible, setResponsible] = useState(null);
    const [newActivityText, setNewActivityText] = useState("");

    const postNewActivity = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "activities/CreateActivity", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    id: "",
                    title: newActivityText,
                    whattodo: '',
                    keyresultid: props.keyResultId,
                    tokenId: props.activeTokenId,
                    organisationId: props.activeOrganisationId,
                    responsibleId: responsible,
                    done: false
                }),
            });

            await res.text();
            if (res.status === 201) {
                props.onSubmit();
                document.getElementById("newActivityInput").focus(); 
                setNewActivityText("");
            } else {
                alert("An error occurred. Your activity may not have been saved");
            }
        } catch (err) {
            console.log(err);
        }
    }

    const raiseSubmitEvent = (e) => {
        if (e.keyCode === 13 && newActivityText.length > 0) {
            postNewActivity();
        }
    }
    
    const getResponsibleUser = (e) => {
        if (!IsValueEmptyNullOrUndefined(e)) {
            setResponsible(e.id);
        }
    }
    
    const changeModeToEdit = () => {
        setInputMode(true);
    }
    
    const cancel = () => {
        setNewActivityText("");
        setInputMode(false);
        setResponsible(null);
    }
    
    return (
        <>
            <div className={"add-activity-element-container"}>
                <div className={inputMode ? "text-field-input-mode" : "text-field-non-input-mode"}><InputText id="newActivityInput" value={newActivityText} onChange={(e) => setNewActivityText(e.target.value)} onFocus={changeModeToEdit} placeholder="Enter text for new activity here" style={props.style} onKeyUp={(e) => raiseSubmitEvent(e)} className={props.className} /></div>
                <div className="choose-responsible-user-container" style={{display: inputMode ? "block" : "none"}}>
                    <UserSelector value={responsible} defaultValue={null} getSelectedValueEventHandler={getResponsibleUser} style={{height: "35px"}} className="user-selector" placeholder="Who's responsible" />
                </div>
                <div className="button-container center-contents-vertically " style={{display: inputMode ? "block" : "none"}}>
                    <Button icon="pi pi-save" className="p-button-rounded p-button-info p-button-outlined" aria-label="save" style={{marginLeft: "10px", width: "25px", height: "25px", float: "right"}} onClick={() => postNewActivity()} title="Save" disabled={newActivityText.length === 0} />
                    <Button icon="pi pi-replay" className="p-button-rounded p-button-info p-button-outlined" aria-label="cancel" style={{marginLeft: "10px", width: "25px", height: "25px"}} onClick={() => cancel()} title="Cancel" />
                </div>
            </div>
        </>
    )
}