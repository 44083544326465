import React, {useState} from "react";
import './ActivityEditLine.css';
import {Button} from "primereact/button";
import {UserSelector} from "../UserSelector";
import {IsValueEmptyNullOrUndefined, UserImage, UserImageHtml} from "../../../global-code/hooks/useGenericFunctions";
import {InputText} from "primereact/inputtext";
import {confirmDialog} from "primereact/confirmdialog";
import {InputTextarea} from "primereact/inputtextarea";

export const ActivityEditLine = (props) => {
    const [inputMode, setInputMode] = useState(false);
    const [activityText, setActivityText] = useState(props.activity.title);
    const [responsibleId, setResponsibleId] = useState(props.activity.responsibleId);
    
    const addUser = () => {
        changeModeToEdit();
        document.getElementById("userSelector_" + props.activity.id).focus();
    }

    const cancel = () => {
        setInputMode(false);
        document.getElementById("buttonContainer_" + props.activity.id).style.display = 'block';
    }

    const deleteActivityEventHandler = async (id) => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "activities/DeleteActivity/" + id + "?tokenId=" + props.tokenId + "&organisationId=" + props.organisationId, {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: "",
            });

            await res.text();
            if (res.status === 202) {
                props.callAfterDelete();
            } else {
                alert("Error occurred activity might not be deleted");
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    const updateActivity = async (id, activityDone) => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "activities/UpdateActivity/", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    id: id,
                    title: activityText,
                    whattodo: '',
                    keyresultid: props.activity.keyResultId,
                    tokenId: props.tokenId,
                    organisationId: props.organisationId,
                    responsibleId: responsibleId,
                    done: activityDone
                }),
            });

            await res.text();
            if (res.status === 202) {
                props.callAfterUpdate();
                if (activityDone) {
                    showMessage('You\'ve made progress!', 'Please create an update below to show the rest of your company the progress you\'ve made');
                }
                cancel();
            } else {
                alert("Error occurred activity might not be deleted");
            }
        } catch (err) {
            console.log(err);
        }
    }

    const changeModeToEdit = () => {
        setInputMode(true);
        console.log(document.getElementById("buttonContainer_" + props.activity.id).style.display);
        document.getElementById("buttonContainer_" + props.activity.id).style.display = 'none';
    }

    const raiseSubmitEvent = (e) => {
        if (e.keyCode === 13 && activityText.length > 0) {
            updateActivity(props.activity.id, props.activity.done);
        }
    }

    const getResponsibleUser = (selectedItem) => {
        if (!IsValueEmptyNullOrUndefined(selectedItem)) {
            setResponsibleId(selectedItem.id);
        }
    }

    const confirmDeleteActivity = () => {
        confirmDialog({
            message: 'Are you sure you want to DELETE this activity?',
            header: 'Delete Activity?',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptUserDelete,
            reject
        });
    };

    const showMessage = (header, message) => {
        confirmDialog({
            message: message,
            header: header,
            icon: 'pi pi-comment',
            accept: reject,
            acceptLabel: 'OK',
            rejectLabel: 'Close'
        });
    };

    const acceptUserDelete = () => {
        deleteActivityEventHandler(props.activity.id);
    }

    const reject = () => {

    }
    
    return (
        <div className="activity-edit-line-component-container">
            <table>
                <tr>
                    <td>
                        <span className="activity-status">
                            <Button icon={"pi" + (props.activity.done === true ? " pi-check" : "")} className={"p-button-rounded p-button-secondary " + (props.activity.done === true ? "" : "p-button-outlined")} aria-label="Status" style={{width: "25px", height: "25px"}} onClick={() => updateActivity(props.activity.id, !props.activity.done)} title="Set activity as NOT done" />
                        </span>
                    </td>
                    <td>
                        <span className="activity-responsible-container">
                            {props.activity.responsibleHasPhoto &&
                                <UserImage userId={props.activity.responsibleHasPhoto ? props.activity.responsibleId : ""} tokenId={props.tokenId} initials={props.activity.responsibleInitials} className="user-image" title={props.activity.responsibleInitials} />
                            }
                            {!props.activity.responsibleHasPhoto &&
                                <div className="missing-photo" style={{backgroundColor: IsValueEmptyNullOrUndefined(props.activity.responsibleInitials) ? "transparent" : "#cccccc"}}>{props.activity.responsibleInitials}</div>
                            }
                        </span>
                    </td>
                    <td style={{width: "480px"}} className="center-contents-vertically">
                        <span className="activity-text">
                            {!inputMode &&
                                <span onClick={() => changeModeToEdit()}>{props.activity.title}</span>
                            }
                            {inputMode &&
                                <>
                                    <span>
                                        <InputTextarea id="editActivityInput" autoResize={true} style={{width: "480px"}} value={activityText} onChange={(e) => setActivityText(e.target.value)} placeholder="Enter text for activity here" onKeyUp={(e) => raiseSubmitEvent(e)} className="edit-activity-input" />
                                    </span>
                                    <span style={{marginTop: "5px", display: "inline-block"}}>
                                        <UserSelector id={"userSelector_" + props.activity.id} value={responsibleId} defaultValue={props.activity.responsibleId} getSelectedValueEventHandler={getResponsibleUser} style={{height: "35px"}} className="user-selector" placeholder="Who's responsible" />
                                    </span>
                                    <span>
                                        <Button icon="pi pi-save" className="p-button-rounded p-button-info p-button-outlined" aria-label="save" style={{marginLeft: "25px", width: "25px", height: "25px", verticalAlign: "middle"}} onClick={() => updateActivity(props.activity.id, props.activity.done)} title="Save" disabled={activityText.length === 0} />
                                        <Button icon="pi pi-replay" className="p-button-rounded p-button-info p-button-outlined" aria-label="cancel" style={{marginLeft: "5px", width: "25px", height: "25px", verticalAlign: "middle"}} onClick={() => cancel()} title="Cancel" />
                                    </span>
                                </>
                            }
                        </span>
                    </td>
                    <td>
                        <span id={"buttonContainer_" + props.activity.id} className="button-container">
                            <Button icon="pi pi-check" className="p-button-rounded p-button-info p-button-outlined" aria-label="Status" style={{width: "25px", height: "25px"}} onClick={() => updateActivity(props.activity.id, !props.activity.done)} title="Toggle done/not done" />
                            <Button icon="pi pi-pencil" className="p-button-rounded p-button-info p-button-outlined" aria-label="Edit" style={{width: "25px", height: "25px"}} onClick={(e) => changeModeToEdit()} title="Edit activity" />
                            {/*<Button icon="pi pi-user" className="p-button-rounded p-button-info p-button-outlined" aria-label="User" style={{width: "25px", height: "25px"}} onClick={(e) => addUser()} title="Add responsible colleague" />*/}
                            <Button icon="pi pi-trash" className="p-button-rounded p-button-info p-button-outlined" aria-label="Delete" style={{width: "25px", height: "25px"}} onClick={confirmDeleteActivity} title="Delete activity" />
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    )
}
