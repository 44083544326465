import React from 'react';
import './UserImage.css';

export const UserImage = (props) => {
    return (
        <div className="user-image-component-container">
            <div className="user-image" title={props.name} style={{height: props.imageSize, width: props.imageSize}}>
                {props.hasPhoto && <img src={process.env.REACT_APP_API_URL + "users/userphoto?tokenId=" + props.tokenId + "&userId=" + props.id} alt={"User Image " + props.initials} style={{height: props.imageSize, width: props.imageSize}} /> }
                {!props.hasPhoto && <div>{props.initials}</div> }
            </div>
        </div>
    )
}