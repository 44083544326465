import React from 'react';
import './InfoMessage.css';

export const InfoMessage = props => {
    return (
        <div className="info-message-component-container" style={{display: props.visible === true ? 'block' : 'none'}}>
            <table>
                <tr>
                    <td>
                        <span className="material-icons-outlined">
                            tips_and_updates
                        </span>
                    </td>
                    <td>
                        <div className="header">
                            {props.title}
                        </div>
                        <div className="message">
                            {props.message}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    )
}