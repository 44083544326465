import React from 'react';

export const StrategicGoalCard = ({id, title, top, left, height, width, clickEventHandler}) => {
    return (
        <div id={id} className="canvas-card strategic-goal-card grow pointer-cursor box-shadow" style={{top: top + "px", left: left + "px", height: height + "px", width: width + "px"}} onClick={clickEventHandler}>
            <div className="strategic-goal-card-title center-contents-vertically-horizontally">{title}</div>
        </div>
    )
}

export const ObjectiveCard = ({id, title, top, left, height, width, clickEventHandler}) => {
    return (
        <div id={id} className="canvas-card objective-card grow pointer-cursor box-shadow" style={{top: top + "px", left: left + "px", height: height + "px", width: width + "px"}} onClick={clickEventHandler}>
            <div className="canvas-card-title center-contents-vertically-horizontally">{title}</div>
        </div>
    )
}

export const KeyResultCard = ({id, title, top, left, height, width, clickEventHandler}) => {
    return (
        <div id={id} className={"canvas-card key-result-card grow pointer-cursor box-shadow"} style={{top: top + "px", left: left + "px", height: height + "px", width: width + "px"}} onClick={clickEventHandler}>
            <div className="canvas-key-result-card-title center-contents-vertically">{title}</div>
        </div>
    )
}

export const AddButtonPopOut = ({id, top, left, width, height, tooltip, buttonColor, clickEventHandler}) => {
    return (
        <div id={id} className="add-button-pop-out-container center-contents-vertically-horizontally grow" title={tooltip} style={{top: top + "px", left: left + "px", width: width + "px", height: height + 'px', borderColor: buttonColor}} onClick={event => clickEventHandler(event.currentTarget.id)}>
            <div className="add-button-pop-out center-contents-vertically-horizontally" style={{backgroundColor: buttonColor}}>
                +
            </div>
        </div>
    )
}