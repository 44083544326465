import React, {useEffect, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import './EditForms.css'
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import {SetFocusOnElement} from "../../global-code/hooks/useGenericFunctions";

export const EditStrategicGoal = props => {

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const GetEditDataObject = () => {
        return {
            title: props.data.title,
            description: props.data.description,
            strategicGoalId: props.data.id
        };
    }

    const [values, setValues] = useState(GetEditDataObject());

    useEffect(() => {
        setValues(GetEditDataObject());
        SetFocusOnElement("strategicGoalTitle");
    }, [props.data]);

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validateFormInput(values));
        setIsSubmitting(true);
    };

    const handleChange = e => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    const postData = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "strategy/CreateUpdateStrategicGoal", {
                method: "POST",
                headers: {'Content-Type': 'application/json;charset=UTF-8'},
                body: JSON.stringify({
                    strategicGoalId: values.strategicGoalId,
                    title: values.title,
                    description: values.description === null ? "" : values.description,
                    organisationId: props.activeOrganisationId,
                    tokenId: props.activeToken,
                    userId: props.activeUserId,
                }),
            });

            await res.text();

            if (res.status === 200) {
                props.callAfterSave();
                props.callToHideDialog();
            } else {
                alert("An error occurred. Your changes were not saved");
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            postData();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, isSubmitting]);

    function validateFormInput(values) {
        let errors = {};

        if (!values.title.trim()) {
            errors.title = "Please enter a title for your Strategic Goal";
        }

        return errors;
    }

    const DeleteStrategicGoalOnServer = async (id) => {
        try {
            fetch(process.env.REACT_APP_API_URL + 'strategy/DeleteStrategicGoal/' + id + '?tokenId=' + props.activeToken)
                .then(() => props.callAfterSave());
        } catch (err) {
            console.log(err);
        }
    }

    function ConfirmDeleteStrategicGoal(id) {
        if (window.confirm("Are you sure you wish to delete this Strategic Goal? It can only be deleted if no objectives are associated with it")) {
            DeleteStrategicGoalOnServer(id);
        }
    }

    return (
        <>
            <div className="edit-container">
                <form id="editStrategicGoal" onSubmit={handleSubmit}>
                    <div className="form-explanation left-separator-strategic-goal">
                        <p>
                            Strategic Goals are the manifistation of your <b>Vision</b> and your <b>Why</b>.
                            What are the goals that you will set to fulfill your vision.</p>
                        <p>
                            Strategic Goals can change over time and are general headlines for the direction
                            you want to move in.
                        </p>
                        <p>
                            Make the title of your strategic goal short but precise. Make sure your
                            headline of your Goal contains <span className="where">where</span> you are
                            going, and <span className="when">when</span> you
                            will have achieved it.
                        </p>
                        <p>
                            As an example: We want to be the <span className="where">leading supplier in Australia</span> with
                            a <span className="when">market share of at least 40%</span>
                        </p>
                        <p>
                            Remember that <i>Objectives</i> for the comming time period and the <i>Key
                            Results</i> are made with reference to this Goal
                            so it is important that it is clear and aligned with your vision/why. for your
                            organisation.
                        </p>
                    </div>

                    <div className="field-wrapper">
                        <Tooltip target=".icoTitle" style={{width: "400px"}} position="left">Concrete, understandable and short</Tooltip>
                        <div className="input-field-title-container"><span className="input-field-title-text">Strategic Goal Title</span><span className="material-icons-outlined icoTitle">info</span></div>
                        <span className="p-float-label">
                                <InputText id="strategicGoalTitle" name="title" className="fill-container-width" type="text" value={values.title} onChange={handleChange}/>
                            {errors.title && <p className="error-message">{errors.title}</p>}
                        </span>
                    </div>

                    <div className="field-wrapper">
                        <Tooltip target=".icoDescription" style={{width: "400px"}} position="left">Explain your Strategic Goal in more detail and also WHY it's important to achieve this goal</Tooltip>
                        <div className="input-field-title-container"><span className="input-field-title-text">Description</span><span className="material-icons-outlined icoDescription">info</span></div>
                        <span className="p-float-label">
                                <InputTextarea name="description" className="fill-container-width" rows={5} cols={30} value={values.description} onChange={handleChange}/>
                            {errors.description && <p className="error-message">{errors.description}</p>}
                        </span>
                    </div>

                    <div className="button-container" style={{padding: "0 40px 0 40px"}}>
                        <Button label="Close" icon="pi pi-times" type="button" className="dialog-button-text p-button-close" onClick={props.callToHideDialog} style={{float: "left", marginLeft: "10px"}}/>
                        <Button label="Save" icon="pi pi-check" className="dialog-button-text p-button-save"/>
                        <Button label="Delete ..." icon="pi pi-minus" type="button" className="dialog-button-text p-button-delete" onClick={(event) => ConfirmDeleteStrategicGoal(values.strategicGoalId)}/>
                    </div>
                </form>
            </div>
        </>
    );
}