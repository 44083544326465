import React, {useEffect, useState} from 'react';
import {useHttp} from "../global-code/hooks/useHttp";
import {LoadingIndicator} from "../components/content-elements/LoadingIndicator";
import {StrategicGoalOverview} from "../components/content-elements/StrategicGoalOverview";
import './StrategicGoalProgressReport.css';
import domtoimage from 'dom-to-image';

const StrategicGoalProgressReport = props => {
    const [isValidating, setIsValidating] = useState(false);
    const [isParametersValid, setIsParametersValid] = useState(false);
    const [getImage, setGetImage] = useState(false);
    const [organisationId, setOrganisationId] = useState(props.activeOrganisationId);
    const [strategicGoalId, setStrategicGoalId] = useState('');
    const [cutOffNumberOfDaysAgo, setCutOffNumberOfDaysAgo] = useState('30');
    const queryParams = new URLSearchParams(window.location.search);

    const [isLoading, strategicGoals] = useHttp(
        process.env.REACT_APP_API_URL + 'KeyResults/ProgressionKeyResults/' + organisationId + '?tokenId=' + props.activeToken + '&cutOffNumberOfDaysAgo=' + cutOffNumberOfDaysAgo,
        [organisationId, cutOffNumberOfDaysAgo, strategicGoalId]
    )
    
    const exportAsPicture = (strategicGoalId) => {
        let scale = 4;
        let node = document.getElementById('root');
        domtoimage.toPng(node,
            {   quality: 1, 
                        bgcolor: "white",
                        width: node.clientWidth * scale,
                        height: node.clientHeight * scale,
                        style: {
                            transform: 'scale('+ scale +')',
                            transformOrigin: 'top left'
                        }
                    })
            .then((dataUrl) => {
                var link = document.createElement('a')
                // link.download = strategicGoalId + '.png'
                link.download = 'Result.png'
                link.href = dataUrl
                link.click()
            })
    }
    
    useEffect( () => {
        if (getImage && !isLoading && strategicGoals && !isValidating) {
            setGetImage(false);
            exportAsPicture(strategicGoalId);
        }
    }, [strategicGoals])

    function ValidateQueryParams() {
        let result = false;
        setIsValidating(true);
        if (queryParams.get('organisationid') !== null && queryParams.get('strategicgoalid') !== null && queryParams.get('cutoffnumberofdaysago') !== null) {
            setOrganisationId(queryParams.get('organisationid'));
            setStrategicGoalId(queryParams.get('strategicgoalid'));
            setCutOffNumberOfDaysAgo(queryParams.get('cutoffnumberofdaysago'));
            setGetImage(queryParams.get('getimage') === '1');
            result = true;
        }

        if (queryParams.get('getimage') === '1' && !isLoading && strategicGoals && !isValidating) {
            setGetImage(false);
            exportAsPicture(strategicGoalId);
        }
        
        setIsValidating(false);
        return result;
    }

    useEffect( () => {
        setIsParametersValid(ValidateQueryParams());
    }, [])

    function snapShot(e) {
        let node = document.getElementById(e.currentTarget.id);
        let position = node.style.position;
        let left = node.style.left;
        let scale = 4;
        node.style.position = 'absolute';
        node.style.left = '-9999px';
        document.body.appendChild(node);

        domtoimage.toPng(node, {
            bgcolor: "white",
            height: node.offsetHeight * scale,
            width: node.offsetWidth * scale,
            style: {
                transform: "scale(" + scale + ")",
                transformOrigin: "top left",
                width: node.offsetWidth + "px",
                height: node.offsetHeight + "px",
                position: position,
                left: left
            }
        }).then((dataUrl) => {
            let img = document.getElementById("img-to-copy");
            img.src = dataUrl;
        });
    }
    
    let content = <LoadingIndicator message="Loading Report Data" />
    
    if (!isLoading && strategicGoals && !isValidating) {
        if (isParametersValid && strategicGoals.length > 0) {
            content = (
                <>
                    { strategicGoals.filter( goal => goal.id.toUpperCase() === strategicGoalId.toUpperCase()).map( goal => (
                        <div key={"click_container_" + goal.id} id={"click_container_" + goal.id} onClick={(e) => snapShot(e)} className="strategic-goal-render-container">
                            <StrategicGoalOverview key={goal.id} data={goal} scale={1} />
                        </div>
                    )) }
                    <img id="img-to-copy" alt="img-to-copy" style={{width: "100%"}} />
                </>
                
            )
        } else {
            content = (
                <>
                    <div className="strategic-goal-progress-report-selection-container">
                        <h1>VOKR Strategic Goal progress report</h1>
                        <div className="page-explanation">
                            <p>This page will display progress information about a single Strategic Goal <br/>
                            You can use this view for presentation in another program like Microsoft Power Point. Use a browser plugin and give the proper url.</p>
                            <p>You have not given all neccesary parameters to display the correct Goal (organisationid, strategicgoalid, cutoffnumberofdaysago), so please choose one below.</p>
                        </div>
                        {strategicGoals.map ( goal => (
                            <div className="link-button">
                                <span className="material-icons-outlined">
                                    file_open
                                </span>
                                <span className="link-button-text">
                                    <a href={"/Reports?organisationid=" + props.activeOrganisationId + "&strategicgoalid=" + goal.id + "&cutoffnumberofdaysago=" + cutOffNumberOfDaysAgo}>{goal.title}</a>
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            )
        }       
    }

    return content;
};

export default StrategicGoalProgressReport;