import React from 'react'

export const SidebarMenuItem = props => {

        if (props.visible) {
        
            return (
                <>
                    <li key={props.id}>
                        <a key={props.id} href={props.url}>
                            <span className="icon"><span className="material-icons">{props.iconName}</span></span>
                            <span className="title">{props.title}</span>
                        </a>
                    </li>
                </>
            )
        } else {
            return ('');
        }
}

