import React from 'react';
import './RoundAddButtonPlus.css';
import {PlusSymbol} from "../svg-symbols/PlusSymbol";
import {Tooltip} from "primereact/tooltip";

export const RoundAddButtonPlus = props => {

    return (
        <>
            <Tooltip target=".add-button-container">{props.toolTip}</Tooltip>
            <div className="add-button-container" onClick={(e) => props.callOnClick(e)} style={{width: props.width, height: props.height}}>
                <span id="symbolContainer"><PlusSymbol width={props.height} height={props.width}/></span>
            </div>
        </>
    )

}