import React, {useEffect, useState} from 'react';
import {InputText} from "primereact/inputtext";
import './EditTeam.css'
import {Tooltip} from "primereact/tooltip";
import {UploadUserImage} from "../../../global-code/Components/UploadUserImage";
import {GetTeamImageAPIEndPointUri} from "../../../global-code/hooks/useSolutionFunctions";
import {ColorPicker} from "primereact/colorpicker";
import {IsValueEmptyOrUndefinedThenUseThisValue} from "../../../global-code/hooks/useGenericFunctions";

export const EditTeam = props => {
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imageBytes, setImageBytes] = useState(null);

    const [values, setValues] = useState({
        id: valueIfNull(props.team.id, ""),
        title: valueIfNull(props.team.title, ""),
        shortTitle: valueIfNull(props.team.shortTitle, ""),
        identificationColorHexValue: IsValueEmptyOrUndefinedThenUseThisValue(props.team.identificationColorHexValue, "275cf6"),
        teamId: valueIfNull(props.team.id, "")
    });

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validateFormInput(values));
        setIsSubmitting(true);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }
   
    const postData = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "Teams/CreateUpdateTeam", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    id: values.id,
                    title: valueIfNull(values.title, ""),
                    shortTitle: valueIfNull(values.shortTitle, ""),
                    description: "",
                    organisationId: props.organisationId,
                    identificationColorHexValue: valueIfNull(values.identificationColorHexValue, ""),
                    teamImage: imageBytes === null ? "" : imageBytes,
                    tokenId: props.activeToken,
                }),
            });

            if (res.status === 200) {
                if (props.callAfterSave != null) {
                    props.callAfterSave();
                }
                props.callToHideDialog();
            }
            
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            postData();
        }
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function valueIfNull(value, valueIfNull) {
        return value === null ? valueIfNull : value;
    }
    
    function validateFormInput(values) {
        let errors = {};

        if (!values.title.trim()) {
            errors.title = "You need to enter a name for the team";
        }
        
        if (values.title.trim().length > 50) {
            errors.title = "Team name can only be 50 characters long";
        }

        if (!values.shortTitle.trim()) {
            errors.shortTitle = "You need to enter an abbreviation for the team (max 4 characters)";
        }
        
        if (values.shortTitle.trim().length > 4) {
            errors.shortTitle = "Team abbreviation can only be 4 characters long";
        }
        
        if (values.identificationColorHexValue === "") {
            errors.identificationColorHexValue = "Please choose a color";
        }
        
        return errors;
    }
    
    return (
            <>
                <div className="edit-team-container">
                    <div className="team-image">
                        { (props.addNew || !props.team.hasImage) && <UploadUserImage imageUrl="/resources/basic-graphics/missingperson.png" imageByteSetState={setImageBytes} frameColor={"#" + values.identificationColorHexValue} /> }
                        { !props.addNew && props.team.hasImage && <UploadUserImage imageUrl={GetTeamImageAPIEndPointUri(props.activeToken, values.id) + "&v=" + new Date().getTime()} imageByteSetState={setImageBytes} frameColor={"#" + values.identificationColorHexValue} /> }
                    </div>

                    <form id="editTeam" onSubmit={handleSubmit}>
                        
                        <div className="field-wrapper">
                            <Tooltip target=".helpName" position="left">Full team name</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpName">Team Name</span></div>
                            <span className="p-float-label">
                                <InputText name="title" className="fill-container-width" type="text" value={values.title} onChange={handleChange} />
                                {errors.title && <p className="error-message">{errors.title}</p>}
                            </span>
                        </div>

                        <div className="field-wrapper">
                            <Tooltip target=".helpShortTitle" position="left">Short team name (max 4 letters)</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpShortTitle">Team abbreviation</span></div>
                            <span className="p-float-label">
                                <InputText name="shortTitle" className="fill-container-width" type="text" value={values.shortTitle} onChange={handleChange} maxLength={4} />
                                {errors.shortTitle && <p className="error-message">{errors.shortTitle}</p>}
                            </span>
                        </div>

                        <div className="field-wrapper">
                            <Tooltip target=".helpTeamColor" position="left">Choose the identification color for this team. Try to match the dominant color of the image</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpTeamColor">Team identification color</span></div>
                            <ColorPicker name="identificationColorHexValue" value={values.identificationColorHexValue} onChange={handleChange} inline></ColorPicker>
                        </div>

                    </form>
                </div>
            </>
    );
}