import React, {createContext, useEffect, useState} from 'react';
import './App.css';
import logo from './resources/basic-graphics/VisualOKRLogoBlack.png';
import {SidebarMenuItem} from './components/navigation/SidebarMenuItem';
import Home from './subpages/home';
import {Help} from "./subpages/Help";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LoadingIndicator} from "./components/content-elements/LoadingIndicator";
import {Sidebar} from "primereact/sidebar";
import {ToBeImplemented} from "./subpages/ToBeImplemented";
import {Organisation} from "./subpages/Organisation";
import {Login} from "./subpages/Login";
import {CheckIfUserIsLoggedIn, getActiveToken, getAndSetActiveOrganisationId, isUserAdministrator, isUserReadOnly, isUserUser, saveToken, userLogOut} from "./global-code/hooks/useSecurity";
import StrategicGoalProgressReport from "./subpages/StrategicGoalProgressReport";
import ProgressTextUpdatesReport from "./subpages/ProgressTextUpdatesReport";
import {GetDefaultTimeSpanValue, GetPersistedValue, GetYearFromSelectedTimeSpan, PersistValue, UserImageHtml} from "./global-code/hooks/useGenericFunctions";
import {ChangePassword} from "./subpages/ChangePassword";
import {SendChangePasswordEMail} from "./global-code/hooks/useCommunication";
import {confirmDialog} from "primereact/confirmdialog";
import {StrategyCanvas} from "./subpages/StrategyCanvas/StrategyCanvas";
import {useHttpOnCondition} from "./global-code/hooks/useHttp";

export const globalStateContext = createContext(undefined);

const App = props =>  {
    const COOKIE_KEY_DISPLAY_CANVAS_YEAR = "DisplayQuarterAndYear";
    const loginPageContent = <Login doAfterLogin={HandleSuccessfullLogin} />;
    const changePasswordPageContent = <ChangePassword />;
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [getUserInformation, setGetUserInformation] = useState(false);
    const [isValidatingToken, userInformation] = CheckIfUserIsLoggedIn(getUserInformation);
    const [chosenQuarterAndYear, setChosenQuarterAndYear] = useState(GetChosenQuarterAndYear());
    const [activeOrganisationId, setActiveOrganisationId] = useState(getAndSetActiveOrganisationId());
    const [updateStrategyList, setUpdateStrategyList] = useState(false);
    const [updateObjectiveList, setUpdateObjectiveList] = useState(false);
    const [updateTeamsList, setUpdateTeamsList] = useState(false);
    
    const showEmailSentMessage = () => {
        confirmDialog({
            message: 'An e-mail with a link for changing password has been sent',
            header: 'Success',
            icon: 'pi pi-check',
            acceptLabel: 'OK',
            rejectClassName: 'hide'
        });
    };
    
    function SetGlobalColors () {
        let r = document.querySelector(':root');
        let chosenOrganisation = userInformation.userOrganisations.filter( org => org.id === activeOrganisationId)[0];
        r.style.setProperty('--green', "#" + chosenOrganisation.levelGreenHexColorValue);
        r.style.setProperty('--yellow', "#" + chosenOrganisation.levelYellowHexColorValue);
        r.style.setProperty('--red', "#" + chosenOrganisation.levelRedHexColorValue);
    }
    
    function GetChosenQuarterAndYear() {
        return GetPersistedValue(COOKIE_KEY_DISPLAY_CANVAS_YEAR, GetDefaultTimeSpanValue());
    }
    
    function CreateGlobalSessionContent(activeOrganisationId, activeTokenId, activeUserId, userIsAdministrator, userIsReadOnly, userIsUser) {
        var result = {
            userInformation: userInformation,
            activeOrganisationId: activeOrganisationId,
            activeTokenId: activeTokenId,
            activeUserId: activeUserId,
            teamList: teamList,
            objectiveList: objectiveList,
            userList: userList,
            strategyList: strategyList,
            updateStrategyList: updateStrategyList, 
            setUpdateStrategyList: setUpdateStrategyList,
            updateObjectiveList: updateObjectiveList,
            setUpdateObjectiveList: setUpdateObjectiveList,
            updateTeamsList: updateTeamsList,
            setUpdateTeamsList: setUpdateTeamsList,
            userIsAdministrator: userIsAdministrator,
            userIsReadOnly: userIsReadOnly,
            userIsUser: userIsUser,
            chosenQuarterAndYear: chosenQuarterAndYear
        }        
        return result;
    }
    
    function HandleSuccessfullLogin(userData) {
        saveToken(userData.userTokenId);
        setGetUserInformation(true);
    }

    function GoToChangePasswordPage() {
        const currentUrl = window.location.href.toUpperCase();
        if (currentUrl.indexOf("CHANGEPASSWORD") > 0 && currentUrl.indexOf("PASSWORDRESETIDENTIFIER") > 0) {
            return true;
        }
        return false;
    }

    function sendChangePasswordMail() {
        SendChangePasswordEMail(getActiveToken(), showEmailSentMessage);
    }

    const handleChangeOfQuarterAndYear = e => {
        let value = e.target.value;
        PersistValue(COOKIE_KEY_DISPLAY_CANVAS_YEAR, value);
        setChosenQuarterAndYear(value);
    }

    const [isLoading, objectiveList] = useHttpOnCondition(userInformation && activeOrganisationId !== null,
        process.env.REACT_APP_API_URL + 'Objectives/OrganisationObjectives/' + activeOrganisationId + "?tokenId=" + getActiveToken() + "&year=" + GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 1),
        [props.year,userInformation,activeOrganisationId,updateObjectiveList]
    );

    const [isLoading2, teamList] = useHttpOnCondition(userInformation && activeOrganisationId !== null,
        process.env.REACT_APP_API_URL + 'Teams/AllTeams/' + activeOrganisationId + "?tokenid=" + getActiveToken(),
        [userInformation,activeOrganisationId, updateTeamsList]
    );

    const [isLoading3, userList] = useHttpOnCondition(userInformation && activeOrganisationId !== null,
        process.env.REACT_APP_API_URL + 'Employee/AllEmployees/' + activeOrganisationId + "?tokenId=" + getActiveToken(),
        [userInformation,activeOrganisationId]
    );

    const [isLoading4, strategyList] = useHttpOnCondition(userInformation && activeOrganisationId !== null,
        process.env.REACT_APP_API_URL + 'Strategy/StrategyList/' + activeOrganisationId + "?tokenId=" + getActiveToken(),
        [userInformation,activeOrganisationId, updateStrategyList]
    );
    
    useEffect(() => {
       let id = getAndSetActiveOrganisationId();
       if (id === null && userInformation) {
           setActiveOrganisationId(userInformation.userOrganisations[0].id);
       }
    }, [userInformation]);
    
    let content = "";
    if (content === "") content = <LoadingIndicator message={"Download Basic Data"} />;

    if (!isValidatingToken && !userInformation && !GoToChangePasswordPage()) content = loginPageContent;
    if (!isValidatingToken && !userInformation && GoToChangePasswordPage()) content = changePasswordPageContent;
    if (!isValidatingToken && userInformation && activeOrganisationId && objectiveList && teamList && userList && strategyList) {
        let activeOrganisationId = getAndSetActiveOrganisationId() === null ? userInformation.userOrganisations[0].id : getAndSetActiveOrganisationId();
        let activeOrganisationTitle = userInformation.userOrganisations.filter( org => org.id === activeOrganisationId)[0].title;
        let userIsAdministrator = isUserAdministrator(userInformation.userRoles, activeOrganisationId);
        let userIsReadOnly = isUserReadOnly(userInformation.userRoles, activeOrganisationId);
        let userIsUser = isUserUser(userInformation.userRoles, activeOrganisationId);
        SetGlobalColors();

        content = (
                <>
                    <globalStateContext.Provider value={CreateGlobalSessionContent(activeOrganisationId, getActiveToken(), userInformation.userId, userIsAdministrator, userIsReadOnly, userIsUser)}>
                    <div className="App">
                        <div className="container">                            
                            <main>
                                <div className="show-sidebar-icon-container" onClick={() => {setSidebarVisible(true)}}>
                                    <i className="pi pi-bars" style={{'fontSize': '2em'}} />
                                </div>
                                
                                <Sidebar key="sidebar" visible={sidebarVisible} onHide={() => setSidebarVisible(!sidebarVisible)}>
                                    <>
                                    <div className="VOKR-logo"><img src={logo} alt='Company Logo'/></div>
                                        <div className="sidebar-menu">
                                            <ul>
                                                <SidebarMenuItem key="home" id="home" iconName="home" title="Dashboard" url="/" visible={true} />
                                                <SidebarMenuItem key="strategyCanvas" id="strategyCanvas" iconName="rocket_launch" title="Strategy Canvas" url="/canvas" visible={userIsAdministrator}/>
                                                <SidebarMenuItem key="organisation" id="organisation" iconName="webhook" title="Organisation" url="/Organisation" visible={userIsAdministrator}/>
                                                <SidebarMenuItem key="help" id="help" iconName='tips_and_updates' title='Help' url="/Help" visible={true}/>
                                            </ul>
                                            
                                            <div className="side-menu-section-header">Your organisations</div>
                                            <ul>
                                                {userInformation.userOrganisations.map(item => (
                                                    <SidebarMenuItem key={item.id} id={item.id} iconName="business" title={item.title} url={"/?activeorganisationid=" + item.id} visible={true} />
                                                ))}
                                            </ul>
                                            
                                            <div className="user-information-container">
                                                <UserImageHtml userId={userInformation.userHasPhoto ? userInformation.userId : ""} tokenId={getActiveToken()} initials={userInformation.userInitials} className="user-image" /> 
                                                <div className="user-information-user-name">{userInformation.userName}</div>
                                                <div className="user-information-logout-link" onClick={userLogOut}>Log Out</div>
                                                <div className="user-information-change-password-link" onClick={sendChangePasswordMail}>Change Password</div>
                                            </div>
                                        </div>
                                    </>
                                </Sidebar>

                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/" element={<Home organisationId={activeOrganisationId} organisationTitle={activeOrganisationTitle} activeUserId={userInformation.userId} activeToken={getActiveToken()} iconName={'home'} isUserAdministrator={userIsAdministrator} isUserReadOnly={userIsReadOnly} isUserUser={userIsUser} startQuarterAndYear={chosenQuarterAndYear} handleChangeOfQuarterAndYear={handleChangeOfQuarterAndYear} />} />
                                        { userIsAdministrator && <Route path="/Organisation" element={<Organisation organisationId={activeOrganisationId} organisationTitle={activeOrganisationTitle} activeToken={getActiveToken()} iconName={'webhook'} isUserAdministrator={userIsAdministrator} />}  /> }
                                        <Route path="/Help" element={<Help organisationId={activeOrganisationId} organisationTitle={activeOrganisationTitle} iconName={'tips_and_updates'} activeToken={getActiveToken()}  />} />
                                        <Route path="/Reports" element={<StrategicGoalProgressReport activeToken={getActiveToken()} activeOrganisationId={activeOrganisationId} />} />
                                        { userIsAdministrator && <Route path="/Canvas" element={<StrategyCanvas isUserAdministrator={userIsAdministrator} isUserReadOnly={userIsReadOnly} isUserUser={userIsUser} />} />} }
                                        <Route path="/ChangePassword" element={<ChangePassword />} />
                                        <Route path="/Reports/ProgressTextUpdates" element={<ProgressTextUpdatesReport activeToken={getActiveToken()} activeOrganisationId={activeOrganisationId} />} />
                                        <Route path="/Settings" element={<ToBeImplemented />} />
                                    </Routes>
                                </BrowserRouter>
                            </main>                            
                            
                            <footer/>
                        </div>
                    </div>
                    </globalStateContext.Provider>
                </>
        )
    }

    return content;
    
};

export default App;
