import React, {useContext, useEffect, useState} from 'react';
import './KeyResultFullInformation.css';
import {LoadingIndicator} from "./LoadingIndicator";
import {RoundAddButtonPlus} from "../dialog-components/RoundAddButtonPlus";
import {useHttp} from "../../global-code/hooks/useHttp";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import {IsValueEmptyOrUndefined, UserImageHtml} from "../../global-code/hooks/useGenericFunctions";
import {CheckIfUserIsLoggedIn} from "../../global-code/hooks/useSecurity";
import {globalStateContext} from "../../App";
import {EditKeyResult} from "../EditComponents/EditKeyResult";

export const KeyResultFullInformation = props =>  {
    const globalContext = useContext(globalStateContext);
    const [errors, setErrors] = useState({});
    const [updateKeyResultStatusUpdatesList, setUpdateKeyResultStatusUpdatesList] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, item] = useHttp(process.env.REACT_APP_API_URL + 'KeyResults/' + props.id + "?tokenId=" + globalContext.activeTokenId, [updateKeyResultStatusUpdatesList]);
    const [showAddStatusUpdateUi, setShowAddStatusUpdateUi] = useState(false);
    const [activeSubform, setActiveSubform] = useState(1);
    
    function ShowAddStatusUpdateUi() {
        setShowAddStatusUpdateUi(!showAddStatusUpdateUi);
    }
    
    function HideAddStatusUpdateUi() {
        setShowAddStatusUpdateUi(false);
    }
    
    const DeleteKeyResultStatusUpdateOnServer = async (id) => {
        try {
            fetch(process.env.REACT_APP_API_URL + 'keyResults/DeleteKeyResultStatusUpdate/' + id + '?tokenId=' + globalContext.activeTokenId)
                .then(() => setUpdateKeyResultStatusUpdatesList(!updateKeyResultStatusUpdatesList))
                .then(() => props.callAfterSave());
        } catch (err) {
            console.log(err);
        }
    }
    
    const postData = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "keyResults/CreateUpdateKeyResultStatusUpdate", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    id: values.keyResultStatusUpdateId,
                    updateText: values.updateText,
                    measurableResult: values.newMeasurableResult,
                    createdByUserId: props.activeUserId,
                    keyResultId: item.id,
                    tokenId: globalContext.activeTokenId,
                }),
            });

            await res.json();

            if (res.status === 201) {
                setUpdateKeyResultStatusUpdatesList(!updateKeyResultStatusUpdatesList); // Changing this will trigger useHttp as dependency is set to updateKeyResultStatusUpdatesList
                setShowAddStatusUpdateUi(false);
                props.callAfterSave();
            } else {
                alert("An error occurred. Your changes were not saved");
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            postData();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const [values, setValues] = useState({
        keyResultStatusUpdateId: "",
        updateText: null,
        newMeasurableResult: null
    });

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validateFormInput(values));
        setIsSubmitting(true);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }
    
    function validateFormInput(values) {
        let errors = {};

        if (values.updateText === null || !values.updateText.trim()) {
            errors.updateText = "Please enter a text for the update";
        }

        if (values.newMeasurableResult === null) {
            errors.newMeasurableResult = "You must enter a number";
        }

        return errors;
    }

    function submitForm(e) {
        if (values.newMeasurableResult === null) values.newMeasurableResult = item.measurableResult;
        document.getElementById('newUpdate').dispatchEvent(new Event('submit', { 'bubbles' : true, 'cancelable': true }));
    }
    
    function ConfirmDeleteOfKeyResultStatusUpdate(id) {
        if (window.confirm("Are you sure you wish to delete this update?")) {
            DeleteKeyResultStatusUpdateOnServer(id);
        }              
    }
    
    function showSubform(tabId) {
        setActiveSubform(tabId);
    }

    let content = <LoadingIndicator />;

    if (!isLoading && item) {
        
        content = (
                        <>
                            <div className="keyresult-full-information-container">

                                {props.isUserAdministrator && 
                                    <div className="top-dialog-navigation-container">
                                        <span className="top-dialog-navigation-button" style={{left: "0"}} onClick={(e) => showSubform(1)}>
                                            Updates
                                        </span>
                                        <span className="top-dialog-navigation-button" style={{left: "110px"}} onClick={(e) => showSubform(2)}>
                                            Activities
                                        </span>
                                        <span className="top-dialog-navigation-button" style={{left: "220px"}} onClick={(e) => showSubform(3)}>
                                            Definition
                                        </span>
                                    </div>
                                }
                                
                                <div id="updateSubform" style={{display: activeSubform === 1 ? "inherit" : "none"}}>
                                    <div className="body">
                                        <div className="header-section">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <UserImageHtml userId={item.responsiblePersonHasPhoto ? item.responsiblePersonId : ""} tokenId={globalContext.activeTokenId} initials={item.responsiblePersonInitials} className="responsible-person" />
                                                    </td>
                                                    <td>
                                                        <div className="objective-title">{item.objectiveTitle}</div>
                                                        <div className="keyresult-title">{item.title}</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="divider" />
                                        {/*<div className="team-container">*/}
                                        {/*    <div className="anchor-team">*/}
                                        {/*        <table>*/}
                                        {/*            <tbody>*/}
                                        {/*                <tr>*/}
                                        {/*                    <td>*/}
                                        {/*                        <span className="material-icons rounded-white-container">home</span>*/}
                                        {/*                    </td>*/}
                                        {/*                    <td>*/}
                                        {/*                        <span className="anchor-team-name">&nbsp;&nbsp;{item.anchorTeamTitle}</span>*/}
                                        {/*                    </td>*/}
                                        {/*                </tr>*/}
                                        {/*            </tbody>*/}
                                        {/*        </table>*/}
                                        {/*    </div>*/}
                                        {/*    {item.teamMembers.map(member => (*/}
                                        {/*        <div className="team-member-container">*/}
                                        {/*            <img src={`/resources/user-images/${member.id}.jpg`} alt={member.name} />*/}
                                        {/*        </div>*/}
                                        {/*    ))}*/}
                                        {/*    <div className="team-label">TEAM</div>*/}
                                        {/*</div>*/}
                                        {!IsValueEmptyOrUndefined(item.whatToDo) && <div className="header2">What-to-do</div>}
                                        <div className="what-to-do content-container" dangerouslySetInnerHTML={{__html: item.whatToDo}} />
                                        <div className="header2">Updates <div style={{float: "right"}}>{(props.isUserAdministrator || props.isUserUser) && <RoundAddButtonPlus width="20px" height="20px" toolTip="Add a status update" callOnClick={ShowAddStatusUpdateUi} /> }</div></div>
                                        { showAddStatusUpdateUi ?
                                            <div className="add-update-ui-container">
                                                <form id="newUpdate" onSubmit={handleSubmit}>
                                                    <InputTextarea name="updateText" autoResize autoFocus style={{width: "700px"}} rows={5} onChange={handleChange}/>
                                                    {errors.updateText && <p className="error-message">{errors.updateText}</p>}
                                                    <div className="add-update-ui-new-measurable-result">
                                                        <table>
                                                            <thead></thead>
                                                            <tr>
                                                                <td style={{minWidth: "585px"}}>
                                                                    <span>
                                                                        The obtained result so far is <span className="emphasize-text">{item.measurableResult}</span>.<br /> out of a goal of <span className="emphasize-text">{item.measurableGoal}.</span> If the result has changed please state the new value.<br />Otherwise keep as the current result
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        <InputNumber name="newMeasurableResult" value={item.measurableResult} mode="decimal" min={0} max={item.measurableGoal} onValueChange={handleChange} />
                                                                    </span>
                                                                    {errors.newMeasurableResult && <p className="error-message">{errors.newMeasurableResult}</p>}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </form>
                                                <div className="add-update-ui-buttons-container">
                                                    <Button className="p-button-text" onClick={() => HideAddStatusUpdateUi()}>Cancel</Button>
                                                    <Button onClick={(event) => submitForm(event)}>Save update</Button>
                                                </div>
                                            </div> 
                                        : null}
                                        <div className="updates">
                                            {item.statusUpdates.map(update => (
                                                <div className="updates-container" style={{borderLeft: '4px solid #' + update.levelColorHex}}>
                                                    <div className="update-information-container">
                                                        <div className="updated-by-user">
                                                            <UserImageHtml userId={update.userHasPhoto ? update.createdByUserId : ""} tokenId={globalContext.activeTokenId} initials={update.userInitials} className="updated-by-user-image" />
                                                            <div className="updated-when">
                                                                Written by {update.createdByUserName} on the <b>{update.friendlyDateText}</b>
                                                            </div>
                                                        </div>
                                                        <div className="updateText content-container" dangerouslySetInnerHTML={{__html: update.updateText}} />
                                                        <div className="update-divider" />
                                                    </div>
                                                    <div key={"percent_done_" + props.id} className="percent-done" style={{backgroundColor: '#' + update.levelColorHex}}>{update.percentDone}%</div>
                                                    { update.allowEditDelete ? <span className="material-icons-outlined edit-symbol" onClick={(e) => ConfirmDeleteOfKeyResultStatusUpdate(update.id)}>delete_outline</span> : null }
                                                </div>
                                            ))}                                
                                        </div>
                                    </div>
                                </div>
                                <div id="activitiesSubform" style={{display: activeSubform === 2 ? "inherit" : "none"}}>
                                    ACTIVITIES
                                </div>
                                <div id="definitionSubform" style={{display: activeSubform === 3 ? "inherit" : "none"}}>
                                    <EditKeyResult
                                        id={props.keyResultData.id}
                                        data={props.keyResultData}
                                        objectiveList={globalContext.objectiveList}
                                        teamList={globalContext.teamList}
                                        userList={globalContext.userList}
                                        callAfterSave={props.callAfterSave}
                                        callToHideDialog={props.callToHideDialog} 
                                        isUserAdministrator={props.isUserAdministrator}
                                        isUserReadOnly={props.isUserReadOnly}
                                        isUserUser={props.isUserUser}
                                        activeToken={globalContext.activeTokenId}
                                        showCloseButton={true}
                                    />
                                </div>
                            </div>
                        </>
        )
    }
    return content;
}
