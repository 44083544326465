import React, {useContext, useState} from 'react';
import './TeamList.css';
import {useHttp} from "../../../global-code/hooks/useHttp";
import {TeamListRow} from "./TeamListRow";
import {RefreshAllImages} from "../../../global-code/hooks/useGenericFunctions";
import {globalStateContext} from "../../../App";

export const TeamList = (props) => {
    const globalContext = useContext(globalStateContext);
    const [refreshTeamsList, setRefreshTeamsList] = useState(false);
    const [isLoadingTeams, teams] = useHttp(
        process.env.REACT_APP_API_URL + 'Teams/AllTeams/' + props.organisationId + "?tokenId=" + props.activeToken,
        [refreshTeamsList]
    );

    let hideClass = props.visible ? "" : " hide";

    function updateTeamsList() {
        setRefreshTeamsList(!refreshTeamsList);
        globalContext.setUpdateTeamsList(!globalContext.updateTeamsList);
        RefreshAllImages("static-image");
    }

    function GetEmptyTeamObject() {
        return (
            {
                "id": "",
                "title": "",
                "shortTitle": "",
                "identificationColorHexValue": "",
                "teamId": ""
            }
        )
    }
    
    if (teams) {
        return (
            <>
                <div className={"teams-list-container" + hideClass}>
                {
                    teams.map ( team => (
                        <TeamListRow key={team.id} team={team} addNew={false} tokenId={props.activeToken} updateTeamsListFunction={updateTeamsList} isUserAdministrator={props.isUserAdministrator} allowTeamDelete={team.allowDelete} organisationId={props.organisationId} />
                    ))
                }
                    <TeamListRow key={'new_team'} team={GetEmptyTeamObject()} addNew={true} tokenId={props.activeToken} updateTeamsListFunction={updateTeamsList} isUserAdministrator={props.isUserAdministrator} allowTeamDelete={false} organisationId={props.organisationId} />
                </div>
            </>
        );
    }
    
    return null;
}