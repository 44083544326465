import React from 'react';
import './KeyResultUpdates.css';
import {UserImageHtml} from "../../../global-code/hooks/useGenericFunctions";
import {KeyResultUpdateRow} from "./KeyResultUpdateRow";

export const KeyResultUpdates = (props) => {
    return (
        <div className="updates">
            {props.updates.map(update => (
                <KeyResultUpdateRow key={update.id} update={update} tokenId={props.tokenId} callAfterDelete={props.callAfterDelete}/>
            ))}
        </div>
    )
}