import React from 'react'
import './PageHeader.css'

class PageHeader extends React.Component {

    render() {
        return (
            <>
                <div className="page-header-container">
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td><span className="page-name-container"><span className="material-icons icon">{this.props.icon}</span><span className="page-name">{this.props.pageName}</span><span className="organisation-name"> | {this.props.organisationName}</span></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}

export {PageHeader}