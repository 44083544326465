import React from 'react';
import './TeamImage.css';

export const TeamImage = (props) => {
    
    return (
        <div className="team-image-component-container">
            <div className="team-image" title={props.name} style={{height: props.imageSize, width: props.imageSize}}>
                {props.hasImage && <img src={process.env.REACT_APP_API_URL + "teams/teamImage?tokenId=" + props.tokenId + "&teamId=" + props.id} alt={"Team Image " + props.title} style={{height: props.imageSize, width: props.imageSize, border: props.borderThickness + "px solid #" + props.borderColor}} /> }
                {!props.hasImage && <img src="/resources/basic-graphics/default-team-bg.jpg" alt={"Team Image " + props.title} className="static-image" style={{height: props.imageSize, width: props.imageSize, border: props.borderThickness + "px solid #" + props.borderColor}} /> }
            </div>
        </div>
    )
}