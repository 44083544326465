import React, {useContext, useState} from 'react';
import './ObjectiveOverviewRowBased.css';
import {LoadingIndicator} from "./LoadingIndicator";
import {FilterButton} from "../navigation/FilterButton";
import {globalStateContext} from "../../App";
import {ObjectiveHeaderCard, TeamCard, TeamHeaderCard} from "../../subpages/Dashboard/useDashboardComponents";
import {KeyResultList} from "./KeyResultList";
import {GetDisplaySlotsArray, GetQuarterFromSelectedTimeSpan, GetYearFromSelectedTimeSpan} from "../../global-code/hooks/useGenericFunctions";
import {ObjectiveCard} from "./ObjectiveCard";

export const ObjectiveOverviewRowBased = props => {
    const globalContext = useContext(globalStateContext);
    const [showResponsible, setShowResponsible] = useState(true);
    const [showLive, setShowLive] = useState(false);
    
    const positionParameters = {
        keyResultCardHeight: 130,
        keyResultCardMarginBottom: 10
    }

    const handleShowHideResponsibleClick = () => {
        setShowResponsible(!showResponsible);
    }

    const handleShowHideLiveClick = () => {
        setShowLive(!showLive);
    }

    const getItemsForQuarter = (objective, slot) => {
        return objective.keyResults.filter((keyresult) => keyresult.year === GetYearFromSelectedTimeSpan(globalContext.chosenQuarterAndYear, slot) && keyresult.quarter === GetQuarterFromSelectedTimeSpan(globalContext.chosenQuarterAndYear, slot));
    }
    
    const refreshAfterSave = () => {
        props.updateDependentData();
    }

    if (!props.data) {
        return <LoadingIndicator message="Organisational Overview Data" />;
    } else {
        return (
            <>
                <div className="objective-overview-row-based-container">
                    <div className={`filter-buttons-container ${props.showFilters ? '' : 'hidden-class'}`}>
                        <div className={`filter-button-container ${props.showFilters ? '' : 'hidden-class'}`}>
                            <FilterButton title="Responsible" description="Show less information but more Key Results" value={showResponsible} onChange={handleShowHideResponsibleClick} />
                        </div>
                        <div className="filter-button-container">
                            <FilterButton title="Alive" description="Fade Key Results not updated last 30 days" value={showLive} onChange={handleShowHideLiveClick} />
                        </div>
                    </div>
                    
                    <table>
                        <tbody>
                            <tr>
                                <td className="center-table-cell-content column-header">
                                    OBJECTIVE
                                </td>
                                {GetDisplaySlotsArray(globalContext.chosenQuarterAndYear).map((slotData, index) => (
                                    <td key={"quarter_header_" + slotData.quarter} className="center-table-cell-content column-header">
                                        Q{slotData.quarter}-{slotData.year.toString().substring(2)}
                                    </td>                                    
                                ))}
                            </tr>
                            <tr>
                                <td></td>
                                {GetDisplaySlotsArray(globalContext.chosenQuarterAndYear).map((slotData, index) => (
                                    <td key={"header_" + slotData.quarter}><div className="column-header-divider" /></td>
                                ))}
                                
                            </tr>
                        {props.data.map( objective => (
                                <tr key={"objective_row_" + objective.id}>
                                    <td>
                                        <ObjectiveHeaderCard key={objective.id} cardClass="objective-card" headerClass="objective-header" subTitleClass="objective-subtitle" tokenId={globalContext.activeTokenId} objective={objective} keyResultCardHeight={positionParameters.keyResultCardHeight} keyResultCardMarginBottom={positionParameters.keyResultCardMarginBottom} />
                                    </td>
                                    {GetDisplaySlotsArray(globalContext.chosenQuarterAndYear).map((slotData, index) => (
                                        <td key={objective.id + slotData.quarter} className="keyresult-list-td">
                                            <KeyResultList
                                                id={objective.id + slotData.quarter}
                                                objectiveId={objective.id}
                                                items={getItemsForQuarter(objective, index+1)}
                                                timeSpanTitle={slotData.quarter + ". QUARTER"}
                                                quarter={slotData.quarter}
                                                showResponsible={showResponsible}
                                                showLive={showLive}
                                                showEditOptions={false}
                                                liveThreshold={25}
                                                callAfterSave={refreshAfterSave}
                                            />
                                        </td>
                                        ))
                                    }
                                </tr>
                            )
                        )}
                        
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

