import './UploadUserImage.css';
import React, {useState} from 'react';
import {IsValueEmptyOrUndefined} from "../hooks/useGenericFunctions";

export const UploadUserImage = props => {
    const [selectedFile, setSelectedFile] = useState(
        { file: '',
                    imagePreviewUrl: props.imageUrl,
                    name:'',
                    status:'',
                    active: 'edit'
        });
    
    const fileSelectedHandler = event => {
        setSelectedFile(event.target.files[0]);
    }
    
    const handleSubmit = e => {
        e.preventDefault();
        let activeP = this.state.active === 'edit' ? 'profile' : 'edit';
        this.setState({
            active: activeP,
        })
    }
    
    const photoUpload = e =>{
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setSelectedFile({
                file: file,
                imagePreviewUrl: reader.result
            });
            props.imageByteSetState(reader.result);
        }
        reader.readAsDataURL(file);
    }
    
    return (
        <div className="upload-user-image-container">
            <label htmlFor="photo-upload" className="custom-file-upload fas" style={{backgroundColor: IsValueEmptyOrUndefined(props.frameColor) ? "#4388b7" : props.frameColor}}>
                <div className="img-wrap img-upload">
                    <img htmlFor="photo-upload" src={selectedFile.imagePreviewUrl} style={{objectFit: "cover"}} />
                </div>
                <input id="photo-upload" type="file" onChange={photoUpload}/>
            </label>
        
            <input type="file"/>
        </div>
    )
}