import React from 'react';

export const CloseButton = ({id, sizePixel, onClick}) => {
  return (
      <span id={id} className="material-icons pointer-cursor grow" style={{fontSize:  sizePixel + "px"}} onClick={onClick}>
        close
      </span>
  );
}

export const GridOnOff = ({id, sizePixel, onClick, on}) => {
    return (
        <span id={id} className="material-symbols-outlined pointer-cursor grow" style={{fontSize: sizePixel + "px", fontWeight: "200"}} onClick={onClick}>
            {on === true && 'grid_on'}
            {on === false && 'grid_off'}
        </span>
    )
}
