import React, {useRef, useState} from 'react';
import {Menu} from "primereact/menu";
import { Dialog } from "primereact/dialog";
import {EditUser} from "../EditComponents/EditUser";
import "./UserListRow.css";
import {Button} from "primereact/button";
import { confirmDialog } from 'primereact/confirmdialog';
import {showMessage, UserImageHtml} from "../../global-code/hooks/useGenericFunctions";
import {SendWelcomeMail} from "../../global-code/hooks/useCommunication";

export const UserListRow = props => {
    const menu = useRef(null);
    const [showUserEditDialog, setShowUserEditDialog] = useState(false);
    const [userInformation, setUserInformation] = useState(null);

    const confirmActiveSettingChange = () => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptChangeOfUserActiveStatus,
            reject
        });
    };

    const confirmDeleteUser = () => {
        confirmDialog({
            message: 'Are you sure you want to DELETE this user?',
            header: 'Delete User?',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptUserDelete,
            reject
        });
    };

    const confirmSendWelcomeMail = () => {
        confirmDialog({
            message: 'Are you sure you want to send Welcome Email?',
            header: 'Send mail to user?',
            icon: 'pi pi-exclamation-triangle',
            accept: sendWelcomeMail,
            reject
        });
    };
    
    const acceptChangeOfUserActiveStatus = () => {
        updateUserActiveSetting(props.user.id, !props.user.active);
    }
    
    const closeErrorMessage = () => {
        
    }

    const acceptUserDelete = () => {
        deleteUser(props.user.id);
    }
    
    const reject = () => {
        
    }
    
    function renderFooter() {
        return (
            <div>
                {!props.addNew &&
                    <Button label="Send Welcome mail" icon="pi pi-envelope" onClick={confirmSendWelcomeMail} className="dialog-button-text p-button-text" />
                }
                <Button label="Cancel" icon="pi pi-times" onClick={hideEditDialog} className="dialog-button-text p-button-text" />
                <Button label="Save" icon="pi pi-check" className="dialog-button-text" onClick={(event) => submitForm(event)} />
            </div>
        );
    }

    function submitForm(e) {
        document.getElementById('editUser').dispatchEvent(new Event('submit', { 'bubbles' : true, 'cancelable': true }));
    }

    function ShowUserEditDialog() {
        setShowUserEditDialog(true);
    }

    function hideEditDialog() {
        setShowUserEditDialog(false);
    }
    
    function sendWelcomeMail() {
        SendWelcomeMail(props.activeToken, props.organisationId, props.user.id, hideEditDialog);
    }

    const updateUserActiveSetting = async (userId, active) => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "Users/ToggleUserActiveSetting", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    organisationId: props.organisationId,
                    userId: userId,
                    active: active,
                    tokenId: props.activeToken,
                }),
            })
            
            if (res.status === 200) {
                if (props.updateUserListFunction != null) {
                    props.updateUserListFunction();
                }
            } else {
                alert(res.error());
            }
        } catch (err) {
            
        }
    }
    
    function CallCallBack() {
        if (props.updateUserListFunction != null) {
            props.updateUserListFunction();
        }
    }

    const deleteUser = async (userId) => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "Users/DeleteUser?organisationId=" + props.organisationId + "&tokenId=" + props.activeToken + "&userId=" + userId, {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: ""
            }).then(response => response.text()).then( responseText => showMessage(responseText)).then(CallCallBack);

            if (res.status === 200) {
                if (props.updateUserListFunction != null) {
                    props.updateUserListFunction();
                }
            } else {
                alert("An error occurred. Your changes were not saved");
            }
        } catch (err) {
            
        }
    }

    let activeStatusOptionText = props.user.active ? "Deactivate" : "Activate";
    let items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Edit',
                    icon: 'pi pi-user-edit',
                    command: () => {ShowUserEditDialog() }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-times',
                    command: () => { confirmDeleteUser() }
                },
                {
                    label: 'Send Welcome mail',
                    icon: 'pi pi-envelope',
                    command: () => { confirmSendWelcomeMail() }
                },
                {
                    label: activeStatusOptionText,
                    icon: 'pi pi-power-off',
                    command: () => { confirmActiveSettingChange() }
                }
            ]
        }
    ];
    
    if (props.addNew) {
        return (
            <div className="user-list-row-container">
                <Button className="p-button-success" label="Add new user" icon="pi pi-plus" onClick={ShowUserEditDialog} />
                <Dialog key={"user_edit_dialog_new"} header="" position="center" footer={renderFooter()} className="edit-user-dialog" blockScroll={true} showHeader={false} visible={showUserEditDialog} dismissableMask={false} onHide={hideEditDialog}>
                    <EditUser id={props.user.id} activeToken={props.activeToken} user={props.user} callToHideDialog={hideEditDialog} callAfterSave={props.updateUserListFunction} rolesList={props.rolesList} organisationId={props.organisationId} addNew={true} teamList={props.teamList} />
                </Dialog>
            </div>
        )
    } else {
        return (
            <>
                <div className="user-list-row-container">
                    <div className="user-row">
                        <div className="user-column" onClick={ShowUserEditDialog}>
                            <div className="user-image">
                                <UserImageHtml userId={props.user.hasPhoto ? props.user.id : ""} tokenId={props.activeToken} initials={props.user.initials} className="user-image" />
                            </div>
                        </div>
    
                        <div className="user-column" onClick={ShowUserEditDialog}>
                            <div className="user-name">{props.user.name}</div>
                            <div className="props.user-email">{props.user.email}</div>
                        </div>
    
                        <div className="props.user-column" onClick={ShowUserEditDialog}>
                            <div className="user-title">{props.user.title}</div>
                        </div>
    
                        <div className="user-column" onClick={ShowUserEditDialog}>
                            <div className="user-primary-team-information">
                                <span className="user-primary-team-title">{props.user.primaryTeamTitle}&nbsp;</span>
                            </div>
                        </div>
    
                        <div className="user-column">
                            <div className="user-active-status">
                                <div className={ props.user.active === true ? "user-active-status-active-symbol" : "user-active-status-inactive-symbol" }>&nbsp;</div>
                                <div className={ props.user.active === true ? "user-active-status-active" : "user-active-status-inactive"}>{ props.user.active === true ? "Active" : "Inactive" }</div>
                            </div>
                        </div>
    
                        <div className="user-column">
                            <div className="user-kebab-menu-container" style={{display: props.isUserAdministrator ? '' : 'none'}}>
                                <span className="material-icons-outlined" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup>more_vert</span>
                                <Menu id="popup_menu" model={items} popup ref={menu} />
                            </div>
                        </div>
    
                        <Dialog key={"user_edit_dialog_" + props.user.id} header="" position="center" footer={renderFooter()} className="edit-user-dialog" blockScroll={true} showHeader={false} visible={showUserEditDialog} dismissableMask={false} onHide={hideEditDialog}>
                            <EditUser id={props.user.id} activeToken={props.activeToken} user={props.user} callToHideDialog={hideEditDialog} callAfterSave={props.updateUserListFunction} rolesList={props.rolesList} teamList={props.teamList} organisationId={props.organisationId} addNew={false} />
                        </Dialog>
    
                    </div>
                </div>
            </>
        )
    }
}

