import React from 'react';
import {TeamImage} from "../../components/content-elements/TeamImage";
import {UserImage} from "../../components/content-elements/UserImage";

const GetMaxNumberOfKeyResults = (keyResults) => {
    let result = 0;
    let numberOfKeyResults = 0;
    for (let i = 1; i < 5; i++) {
        numberOfKeyResults = keyResults.filter((kr) => kr.quarter === i).length;
        if (numberOfKeyResults > result) result = numberOfKeyResults;
    }
    if (result === 0) result = 1;
    return result;
}

export const TeamHeaderCard = ({team, keyResultCardHeight, keyResultCardMarginBottom, cardClass, tokenId}) => {
    return (
        <div className={cardClass + " center-contents-vertically-horizontally"} style={{height: (GetMaxNumberOfKeyResults(team.involvedInKeyResults) * (keyResultCardHeight + keyResultCardMarginBottom)) - keyResultCardMarginBottom, border: "0px solid #" + team.identificationColorHexValue, backgroundColor: "#" + team.identificationColorHexValue + "80"}}>
            <table>
                <tbody>
                    <tr>
                        <td className="center-contents-horizontally">
                            <TeamImage id={team.id} title={team.title} shortTitle={team.shortTitle} hasImage={team.hasImage} imageSize={80} tokenId={tokenId} borderThickness={3} borderColor={team.identificationColorHexValue}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="center-table-cell-content">
                            <div style={{justifyContent: "flex-end"}}>{team.title}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export const EmployeeHeaderCard = ({employee, keyResultCardHeight, keyResultCardMarginBottom, cardClass, tokenId}) => {
    return (
        <div className={cardClass + " center-contents-vertically-horizontally"} style={{height: (GetMaxNumberOfKeyResults(employee.responsibleForKeyResults) * (keyResultCardHeight + keyResultCardMarginBottom)) - keyResultCardMarginBottom}}>
            <table>
                <tbody>
                <tr>
                    <td className="center-contents-horizontally">
                        <UserImage id={employee.id} name={employee.name} imageSize={80} hasPhoto={employee.hasPhoto} initials={employee.initials} tokenId={tokenId} borderThickness={3} borderColor={employee.identificationColorHexValue}/>
                    </td>
                </tr>
                <tr>
                    <td className="center-table-cell-content">
                        <div style={{justifyContent: "flex-end", marginTop: "10px"}}>{employee.name}</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export const ObjectiveHeaderCard = ({objective, keyResultCardHeight, keyResultCardMarginBottom, cardClass, headerClass, subTitleClass}) => {
    return (
        <div className={cardClass + " center-contents-vertically-horizontally"} title={objective.description} style={{textOverflow: "ellipsis", height: (GetMaxNumberOfKeyResults(objective.keyResults) * (keyResultCardHeight + keyResultCardMarginBottom)) - keyResultCardMarginBottom}}>
            <div>
                <div className={headerClass}>{objective.title}</div>
                <div className={subTitleClass}>{objective.strategicGoalTitle}</div>
            </div>
        </div>
    )
}