import React, {useEffect, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from"primereact/dropdown";
import './EditForms.css'
import {Tooltip} from "primereact/tooltip";
import {InputNumber} from 'primereact/inputnumber';
import {GetSelectItemsFromJsonList, IsValueEmptyOrUndefinedThenUseThisValue, SetFocusOnElement, years} from "../../global-code/hooks/useGenericFunctions";
import {Button} from "primereact/button";

export const EditKeyResult = props => {
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const quarters  = [
        { label: 'Not selected', value: '0' },
        { label: 'Q1', value: '1' },
        { label: 'Q2', value: '2' },
        { label: 'Q3', value: '3' },
        { label: 'Q4', value: '4' }
    ];

    const GetEditDataObject = () => {
        return {
            id: props.data.id,
            title: props.data.title,
            whatToDo: props.data.whatToDo,
            objectiveId: props.data.objectiveId,
            measurableGoal: props.data.measurableGoal,
            levelYellow: props.data.levelYellow,
            levelGreen: props.data.levelGreen,
            year: props.data.year,
            quarter: props.data.quarter,
            anchorTeamId: props.data.anchorTeamId,
            responsiblePersonId: props.data.responsiblePersonId
        };
    }

    const [values, setValues] = useState(GetEditDataObject());
    
    useEffect( () => {
        setValues(GetEditDataObject());
        SetFocusOnElement("keyResultTitle");
    }, [props.data]); 

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validateFormInput(values));
        setIsSubmitting(true);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    const postData = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "keyResults/CreateUpdateKeyResult", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    id: values.id,
                    title: values.title,
                    whatToDo: values.whatToDo === null ? "" : values.whatToDo,
                    objectiveId: values.objectiveId,
                    measurableGoal: values.measurableGoal,
                    teamId: values.anchorTeamId,
                    personResponsibleId: values.responsiblePersonId,
                    levelYellow: values.levelYellow,
                    levelGreen: values.levelGreen,
                    year: values.year,
                    quarter: values.quarter,
                    tokenId: props.activeToken
                }),
            });
            await res.json();
            if (res.status === 201) {
                setIsSubmitting(false);
                if (props.callAfterSave != null) {
                    props.callAfterSave();
                }
                props.callToHideDialog();
            } else {
                alert("An error occurred. Your changes were not saved");
            }
        } catch (err) {
            setIsSubmitting(false);
            console.log(err);
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            postData();
        }
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

    function validateFormInput(values) {
        let errors = {};

        if (!values.title.trim()) {
            errors.title = "Please enter a title for your objective";
        }
        
        if (values.title.trim().length > 100) {
            errors.title = "Title can only be 100 characters long";
        }

        if (values.objectiveId === '0' || values.objectiveId === null) {
            errors.objectiveId = "Please choose the objective associated with your key result";
        }

        if (values.measurableGoal === null || values.measurableGoal === '') {
            errors.measurableGoal = "You need to fill out Measurable Goal";
        }

        if (values.levelYellow === null || values.levelYellow === '') {
            errors.levelYellow = "You need to fill out the level number for Yellow";
        }

        if (values.levelGreen === null || values.levelGreen === '') {
            errors.levelGreen = "You need to fill out the level number for Green";
        }
        
        if (values.levelYellow >= values.levelGreen || (values.measurableGoal < values.levelGreen || values.measurableGoal < values.levelYellow)) {
            errors.measurableGoal = "The Measurable goal needs to be higher than the level for Yellow and Green AND the level for Yellow needs to be lower than level for Green";
        }
        
        if (values.quarter === '0' || values.quarter === null) {
            errors.quarter = "You need to select quarter";
        }
        
        if (values.responsiblePersonId === '0' || values.responsiblePersonId === null) {
            errors.responsiblePersonId = "Please select the person who is responsible for the Key Result";
        }

        if (values.anchorTeamId === '0' || values.anchorTeamId === null) {
            errors.anchorTeamId = "Please select the anchor Team for the Key Result";
        }

        return errors;
    }
    
    function ConfirmDeleteOfKeyResult(id) {
        if (window.confirm("Are you sure you wish to delete this key result?")) {
            DeleteKeyResultOnServer(id);
        }
    }

    const DeleteKeyResultOnServer = async (id) => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + 'keyResults/DeleteKeyResult/' + id + '?tokenId=' + props.activeToken);
            
            await res.text();
            
            if (res.status === 200) {
                props.callAfterSave();
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    return (
            <>
                <div className="edit-container">
                    <form id="editKeyResult" onSubmit={handleSubmit}>
                        <div className="form-explanation left-separator-key-result">
                            <p>Key Results are the measurable results that prove you are succeding in reaching your Objective. In other words - <b>HOW</b> you want to accomplish your objective.</p>
                            <p>A Key Result have to be measurable and have a specific goal.</p>
                            <p>Please note that the <b>Measurable goal</b> field is the - stretch - number your are trying to achieve.</p>
                            <p>The Level <span className="yellow-text emphasize-text">yellow</span> and <span className="green-text emphasize-text">green</span> is the number that when reached will paint your Key Result in those colors. Green could for example be achieved when reaching 90% of your goal.</p>
                            <p>A Key Result <b>IS NOT</b> binary (achieved or not achieved). Your Key Result is a numeric target that you pursue to reach your objective.</p>
                            <br/>
                            <p><span className="emphasize-text">Important!</span> Hold the mouse over the field title to learn more about each field</p>
                        </div>
                        <div className="field-wrapper">
                            <Tooltip target=".helpTitle" style={{width: "400px"}} position="left">Concrete, understandable and short (example: 15 new customers on product X | 15.000 new subscribers to newsletter)</Tooltip>
                            <div className="input-field-title-container">
                                <span className="input-field-title-text helpTitle">Key Result Headline</span>
                            </div>
                            <span className="p-float-label">
                                <InputText id="keyResultTitle" name="title" className="fill-container-width" type="text" value={values.title} onChange={handleChange} />
                                {errors.title && <p className="error-message">{errors.title}</p>}
                            </span>
                        </div>
        
                        <div className="field-wrapper">
                            <Tooltip target=".helpDescription" style={{width: "400px"}} position="left">Explain Key Result in more detail and WHY this will bring you closer to your objective</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpDescription" >Description</span></div>
                            <span className="p-float-label">
                                <InputTextarea name="whatToDo" className="fill-container-width" rows={5} cols={30} value={values.whatToDo} onChange={handleChange} />
                                {errors.whatToDo && <p className="error-message">{errors.whatToDo}</p>}
                            </span>
                        </div>
        
                        <div className="field-wrapper">
                            <Tooltip target=".helpObjective" style={{width: "400px"}} position="left">You can select or change the objective this key result i associated with</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpObjective" >Associated with Objective</span></div>
                            <span className="p-float-label">
                                <Dropdown name="objectiveId" className="fill-container-width" options={GetSelectItemsFromJsonList(props.objectiveList, "title", true)} value={values.objectiveId} onChange={handleChange} />
                                {errors.objectiveId && <p className="error-message">{errors.objectiveId}</p>}
                            </span>
                        </div>

                        <div className="field-wrapper">
                            <div className="input-field-title-container"><span className="input-field-title-text" >Goals</span></div>
                            <div className="number-container">
                                <div className="number-input-field-container">
                                    <Tooltip target=".helpMeasurableGoal" style={{width: "400px"}} position="left">The specific result you want to achieve as a number</Tooltip>
                                    <label htmlFor="measurableGoal" className="input-number-field-title helpMeasurableGoal">Measurable goal</label>
                                    <InputNumber name="measurableGoal" inputId="measurableGoal" value={values.measurableGoal} onValueChange={handleChange} />
                                </div>
                                
                                <div className="number-input-field-container" style={{marginRight: '5px'}}>
                                    <Tooltip target=".helpLevelYellow" style={{width: "400px"}} position="left">The number at which the result should turn <span className="yellow-text emphasize-text">yellow</span> in reports and on dashboards</Tooltip>
                                    <label htmlFor="levelYellow" className="input-number-field-title helpLevelYellow">Level Yellow</label>
                                    <InputNumber name="levelYellow" inputId="levelYellow" value={values.levelYellow} onValueChange={handleChange} />
                                </div>
                                
                                <div className="number-input-field-container">
                                    <Tooltip target=".helpLevelGreen" style={{width: "400px"}} position="left">The number at which the result should turn <span className="green-text emphasize-text">green</span> in reports and on dashboards</Tooltip>
                                    <label htmlFor="levelGreen" className="input-number-field-title helpLevelGreen">Level Green</label>
                                    <InputNumber name="levelGreen" inputId="levelGreen" value={values.levelGreen} onValueChange={handleChange} />
                                </div>
                            </div>
                            {errors.measurableGoal && <p className="error-message">{errors.measurableGoal}</p>}
                            {errors.levelYellow && <p className="error-message">{errors.levelYellow}</p>}
                            {errors.levelGreen && <p className="error-message">{errors.levelGreen}</p>}
                        </div>

                        <div className="field-wrapper">
                            <div className="input-field-title-container"><span className="input-field-title-text" >Time line</span></div>
                            <div className="number-container">
                                <div className="number-input-field-container" style={{marginRight: '10px'}}>
                                    <label htmlFor="year" className="input-number-field-title">Year</label>
                                    <Dropdown name="year" className="fill-container-width" options={years} value={values.year.toString()} onChange={handleChange} />
                                </div>

                                <div className="number-input-field-container">
                                    <Tooltip target=".helpQuarter" style={{width: "400px"}} position="left">Choose which quarter the key result should be achieved</Tooltip>
                                    <label htmlFor="quarter" className="input-number-field-title helpQuarter">Quarter</label>
                                    <Dropdown name="quarter" className="fill-container-width" options={quarters} value={values.quarter.toString()} onChange={handleChange} />
                                </div>
                            </div>
                            {errors.quarter && <p className="error-message">{errors.quarter}</p>}
                        </div>

                        <div className="field-wrapper">
                            <div className="input-field-title-container"><span className="input-field-title-text" >Organisation</span></div>
                            <div className="number-container">
                                <div className="number-input-field-container" style={{marginRight: '10px'}}>
                                    <label htmlFor="responsiblePersonId" className="input-number-field-title">Person responsible</label>
                                    <Dropdown name="responsiblePersonId" className="fill-container-width" options={GetSelectItemsFromJsonList(props.userList, "name", true)} value={values.responsiblePersonId} onChange={handleChange} />
                                </div>

                                <div className="number-input-field-container">
                                    <Tooltip target=".helpQuarter" style={{width: "400px"}} position="left">Choose the anchor team for the Key Result. (Is used for the organisational overview)</Tooltip>
                                    <label htmlFor="anchorTeamId" className="input-number-field-title helpQuarter">Anchor Team</label>
                                    <Dropdown name="anchorTeamId" className="fill-container-width" options={GetSelectItemsFromJsonList(props.teamList, "title", true)} value={values.anchorTeamId} onChange={handleChange} />
                                </div>
                            </div>
                            {errors.responsiblePersonId && <p className="error-message">{errors.responsiblePersonId}</p>}
                            {errors.anchorTeamId && <p className="error-message">{errors.anchorTeamId}</p>}
                        </div>
                        
                        <div style={{marginBottom: "40px"}} />

                        <div className="button-container" style={{padding: "0 40px 0 40px"}}>
                            {props.showCloseButton && <Button label="Close" type="button" icon="pi pi-times" className="dialog-button-text p-button-close" onClick={props.callToHideDialog} style={{float: "left", marginLeft: "10px"}} />}
                            <Button label="Save" icon="pi pi-check" className="dialog-button-text p-button-save" />
                            <Button label="Delete ..." icon="pi pi-minus" type="button" className="dialog-button-text p-button-delete" onClick={(event) => ConfirmDeleteOfKeyResult(values.id)} />
                        </div>
                    </form>
                </div>
            </>
    );
}