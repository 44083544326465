import React, {useRef, useState} from 'react';
import './TeamListRow.css';
import {TeamImage} from "../TeamImage";
import {EditTeam} from "./EditTeam";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import {confirmDialog} from "primereact/confirmdialog";
import {showMessage} from "../../../global-code/hooks/useGenericFunctions";
import {EditUser} from "../../EditComponents/EditUser";

export const TeamListRow = (props) => {
    const menu = useRef(null);
    const [showTeamEditDialog, setShowTeamEditDialog] = useState(false);

    function ShowTeamEditDialog() {
        setShowTeamEditDialog(true);
    }

    const closeErrorMessage = () => {

    }

    const deleteTeam = async (teamId) => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "Teams/DeleteTeam?organisationId=" + props.organisationId + "&tokenId=" + props.tokenId + "&teamId=" + teamId, {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: ""
            }).then(response => response.text()).then(props.updateTeamsListFunction);

            if (res.status === 200) {
                if (props.updateTeamsListFunction != null) {
                    props.updateTeamsListFunction();
                }
            } else {
                alert("An error occurred. Your changes were not saved");
            }
        } catch (err) {

        }
    }

    const acceptTeamDelete = () => {
        deleteTeam(props.team.id);
    }

    const reject = () => {

    }

    const confirmDeleteTeam = () => {
        confirmDialog({
            message: 'Are you sure you want to DELETE this team?',
            header: 'Delete Team?',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptTeamDelete,
            reject
        });
    };
    
    const handleTeamDelete = () => {
        if (!props.allowTeamDelete) {
            showMessage("Cannot delete Team as it has associated Key Results or Team members");
        } else {
            confirmDeleteTeam();
        }
    }

    function renderFooter() {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={hideEditDialog} className="dialog-button-text p-button-text" />
                <Button label="Save" icon="pi pi-check" className="dialog-button-text" onClick={(event) => submitForm(event)} />
            </div>
        );
    }

    let items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Edit',
                    icon: 'pi pi-user-edit',
                    command: () => {ShowTeamEditDialog() }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-times',
                    command: () => { handleTeamDelete() }
                },
            ]
        }
    ];

    function hideEditDialog() {
        setShowTeamEditDialog(false);
    }

    function submitForm(e) {
        document.getElementById('editTeam').dispatchEvent(new Event('submit', { 'bubbles' : true, 'cancelable': true }));
    }

    if (props.addNew) {
        return (
            <div className="user-list-row-container">
                <Button className="p-button-success" label="Add new Team" icon="pi pi-plus" onClick={ShowTeamEditDialog}/>
                <Dialog key={"team_edit_dialog_" + props.team.id} header="" position="center" footer={renderFooter()} className="edit-team-dialog" blockScroll={true} showHeader={false} visible={showTeamEditDialog} dismissableMask={false} onHide={hideEditDialog}>
                    <EditTeam id={props.team.id} activeToken={props.tokenId} team={props.team} callToHideDialog={hideEditDialog} callAfterSave={props.updateTeamsListFunction} rolesList={props.rolesList} teamList={props.teamList} organisationId={props.organisationId} addNew={true} />
                </Dialog>
            </div>
        )
    }
    
    return (
        <>
            <div className="team-list-row-container">
                <div className="team-row center-contents-vertically">
                    <div className="team-column" onClick={ShowTeamEditDialog}>
                        <div className="team-image">
                            <TeamImage id={props.team.id} title={props.team.title} shortTitle={props.team.shortTitle} hasImage={props.team.hasImage} imageSize={55} tokenId={props.tokenId} />
                        </div>
                    </div>

                    <div className="team-column" style={{width: "300px"}} onClick={ShowTeamEditDialog}>
                        <div className="team-title">{props.team.title}</div>
                    </div>

                    <div className="team-column" style={{width: "450px"}} onClick={ShowTeamEditDialog}>
                        <div className="team-color center-contents-vertically hide-overflow" style={{backgroundColor: "#" + props.team.identificationColorHexValue}}>
                            <b>{props.team.title}</b>&nbsp;team identification color
                        </div>
                    </div>

                    <div className="team-column">
                        <div className="user-kebab-menu-container" style={{display: props.isUserAdministrator ? '' : 'none'}}>
                            <span className="material-icons-outlined" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup>more_vert</span>
                            <Menu id="popup_menu" model={items} popup ref={menu} />
                        </div>
                    </div>

                    <Dialog key={"team_edit_dialog_" + props.team.id} header="" position="center" footer={renderFooter()} className="edit-team-dialog" blockScroll={true} showHeader={false} visible={showTeamEditDialog} dismissableMask={false} onHide={hideEditDialog}>
                        <EditTeam id={props.team.id} activeToken={props.tokenId} team={props.team} callToHideDialog={hideEditDialog} callAfterSave={props.updateTeamsListFunction} rolesList={props.rolesList} teamList={props.teamList} organisationId={props.organisationId} addNew={false} />
                    </Dialog>

                </div>
            </div>
        </>
    )
}