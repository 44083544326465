import React, {useEffect} from 'react';
import "./StrategicGoalAndKeyResultProgression.css";
import {CompanyLogoHtmlWide, GetFormattedDate} from "../../../global-code/hooks/useGenericFunctions";
import {Tooltip} from "primereact/tooltip";

export const StrategicGoalAndKeyResultProgression = props => {
    
    const GetColorClassDarkToLight = level => {
        if (level === 1) {
            return "green-left-to-right-gradient";
        }
        
        if (level === 2) {
            return "yellow-left-to-right-gradient";
        }
        
        if (level === 3) {
            return "red-left-to-right-gradient";
        }
    }

    const GetColorClassLightToDark = level => {
        if (level === 1) {
            return "green-right-to-left-gradient";
        }

        if (level === 2) {
            return "yellow-right-to-left-gradient";
        }

        if (level === 3) {
            return "red-right-to-left-gradient";
        }
    }

    const GetCleanColorClass = level => {
        if (level === 1) {
            return "green";
        }

        if (level === 2) {
            return "yellow";
        }

        if (level === 3) {
            return "red";
        }
    }
    
    const CalculateProgress = (keyResult) => {
        let progress = 0;
        let update1 = keyResult.statusUpdate1;
        let update2 = keyResult.statusUpdate2;

        if (update2 !== null) {
            progress = update1.percentDone - update2.percentDone;
        }
        
        if (update2 === null && keyResult.percentDone > 0) {
            progress = keyResult.percentDone;
        }
        
        if (progress > 0) {
            progress = "+" + progress + "%";
        }
        
        if (progress < 0) {
            progress = progress + "%";
        }
        
        if (progress === 0) {
            progress = "0%";
        }
        return progress;
    }
    
    let currentQuarter = 0;
    
    return (
        
        <div className="strategic-goal-and-key-result-progression-container">
            <div className="goal-container">
                <div className="company-logo-container" style={{width: "100%"}}>
                    <CompanyLogoHtmlWide id={"logo_" + props.objectiveId} organisationId={props.activeOrganisationId} organisationName="company logo" className="company-logo-wide pull-right" tokenId={props.tokenId} widthIfWide={280} widthIfTall={140} />
                </div>
                <div className="strategic-goal-title">{props.objectiveTitle}</div>
                <div className="objective-title">{props.strategicGoalTitle}</div>
                <div className="progress-period center-contents-vertically">
                    <div className="progress-pointer-arrow-shape progress-pointer-arrow-color-before" style={{top: "0", left: "10px", width: "30px", height: "50px", position: "relative", display: "inline-block"}} />
                    <div className="progress-period-leading-text">indicates progress on {GetFormattedDate(props.comparisonDateText)}</div>
                </div>

                {   props.data.map(keyResult => (
                    <div key={"key_result_" + keyResult.id}>
                        <div className="key-result-container">
                            <div className={"key-result-title-and-progress-container center-contents-vertically "}>
                                <div className="key-result-progress">
                                    {CalculateProgress(keyResult)}
                                </div>
                                <div className="key-result-title" title={keyResult.title}>
                                    {keyResult.title}
                                </div>
                            </div>
                        
                            <div className="progress-container-background">
                                <div className={"progress-container " + GetColorClassLightToDark(keyResult.level)} style={{width: keyResult.percentDone * 10 + "px"}}>
                                    <div id={"current_progress_" + keyResult.id} className="progress-container-percent center-contents-vertically">
                                        {keyResult.percentDone === 0 ? "" : keyResult.percentDone + "%"}
                                        <Tooltip target={"#current_progress_" + keyResult.id} style={{width: "400px"}} position="left">{keyResult.statusUpdate1 === null ? "" : keyResult.statusUpdate1.updateText}</Tooltip>
                                    </div>
                                </div>
                                {/*{ keyResult.percentDone !== 0 && <div className={"progress-pointer-arrow-shape " + GetCleanColorClass(keyResult.level)} style={{left: 482 + (keyResult.percentDone * 10) + "px"}}>{keyResult.percentDone}%</div> }*/}

                                { currentQuarter !== keyResult.quarter &&
                                    <div className="progress-quarter-display center-contents-vertically-horizontally">Q{keyResult.quarter}</div>
                                }
                                {currentQuarter = keyResult.quarter}
                                { 
                                    keyResult.statusUpdate2 !== null && keyResult.statusUpdate2.percentDone !== keyResult.percentDone &&
                                    <div id={"progress_pointer_before_" + keyResult.id} className="progress-pointer-arrow-shape progress-pointer-arrow-color-before" style={{left: 482 + (keyResult.statusUpdate2.percentDone * 10)}}>
                                        {keyResult.statusUpdate2.percentDone}%
                                        <Tooltip target={"#progress_pointer_before_" + keyResult.id} style={{width: "400px"}} position="left">{keyResult.statusUpdate2.updateText}</Tooltip>
                                    </div>
                                }
                            </div>
                            <div style={{marginTop: props.spaceBetweenKeyResultsInPixels}} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}