import React, {useEffect, useState} from 'react';
import './Login.css';
import {InputText} from "primereact/inputtext";
import logo from "../resources/basic-graphics/VisualOKRLogoBlack.png";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import {Divider} from "primereact/divider";
import {GetUriParam, GotoStartPage} from "../global-code/hooks/useGenericFunctions";
import {confirmDialog} from "primereact/confirmdialog";
import {deleteAllSessionVariablesAndCookies} from "../global-code/hooks/useSecurity";

export const ChangePassword = props => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const [values, setValues] = useState({
        password1: "",
        password2: "",
    });

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validateFormInput(values));
        setIsSubmitting(true);
    };

    const showErrorMessage = (errorMessage) => {
        confirmDialog({
            message: errorMessage,
            header: 'An error occurred',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK'
        });
    };

    deleteAllSessionVariablesAndCookies();

    const changeUserPasswordOnBackend = async (passwordResetIdentifier, doAfterSuccess) => {
        console.log("Her");
        try {            
            let res = await fetch(process.env.REACT_APP_API_URL + "security/changeUserPassword/" + passwordResetIdentifier + "/" + values.password1, {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: ""    
            });

            let responseText = await res.text();
            console.log(await res);

            if (res.status === 200) {
                doAfterSuccess();
            }

            if (res.status === 401) {
                showErrorMessage("Password reset identifier is invalid or expired. Ask for forgotten password again or ask an administrator to send welcome message.");
            }
        } catch (err) {
            console.log(err);
            showErrorMessage(err.message)
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            let passwordResetIdentifier = GetUriParam("passwordresetidentifier");
            changeUserPasswordOnBackend(passwordResetIdentifier, GotoStartPage);
        }
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const header = <h6>Pick a password</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    function validateFormInput(values) {
        let errors = {};

        if (!values.password1.trim()) {
            errors.password1 = "Please enter your new password";
        }

        if (values.password1.trim().length > 100) {
            errors.password1 = "Password can only be 100 characters long";
        }

        if (!values.password2.trim()) {
            errors.password2 = "Please enter your new password";
        }

        if (values.password2.trim().length > 100) {
            errors.password2 = "Password can only be 100 characters long";
        }
        
        if (values.password1 !== values.password2) {
            errors.password2 = "The passwords entered are not the same";
        }

        return errors;
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    return (
        <>
            <div className="login-container" style={{backgroundImage: "url(/resources/basic-graphics/bg-02.jpg)"}}>
                <div className="login-card">
                    <div className="dialog-header">
                        WELCOME
                    </div>
                    <div className="dialog-subtitle">
                        Please change your password
                    </div>
                    <div className="logo-container">
                        <div className="VOKR-logo"><img src={logo} alt='Company Logo'/></div>
                    </div>
                    <div className="form-container">
                        <form id="loginForm" onSubmit={handleSubmit}>

                            <div className="field-wrapper">
                                <div className="input-field-title-container">New Password</div>
                                <Password id="password1" name="password1" value={values.password1} onChange={handleChange} header={header} footer={footer} className="fill-container-width" />
                                {errors.password1 && <p className="error-message" style={{top: "255px"}}>{errors.password1}</p>}
                            </div>

                            <div className="field-wrapper">
                                <div className="input-field-title-container">Repeat new Password</div>
                                <Password id="password2" name="password2" value={values.password2} onChange={handleChange} header={header} footer={footer} className="fill-container-width" feedback={false} />
                                {errors.password2 && <p className="error-message" style={{top: "355px"}} >{errors.password2}</p>}
                            </div>

                            <Button className="login-button" label="Change Password" type="submit" />

                        </form>
                    </div>
                    <div className="payoff">Strategy In Motion</div>
                </div>
            </div>
        </>
    )
}

