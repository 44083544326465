import React, {useState, useContext} from 'react';
import './home.css';
import {PageHeader} from "../components/navigation/PageHeader";
import {ObjectiveOverviewColumnBased} from "../components/content-elements/ObjectiveOverviewColumnBased";
import {StrategyOverview} from "../components/content-elements/StrategyOverview";
import {OrganisationalOverviewColumnBased} from "../components/content-elements/OrganisationalOverviewColumnBased";
import {ProgressionOverview} from "../components/content-elements/ProgressionOverview";
import {LoadingIndicator} from "../components/content-elements/LoadingIndicator";
import {globalStateContext} from "../App";
import {TabNavigation} from "../components/navigation/TabNavigation";
import {InputSwitch} from "primereact/inputswitch";
import {GetPersistedValue, TimeSpanSelectorValues} from "../global-code/hooks/useGenericFunctions";
import {Dropdown} from "primereact/dropdown";
import {useHttp} from "../global-code/hooks/useHttp";
import {OrganisationalOverviewRowBased} from "../components/content-elements/OrganisationalOverviewRowBased";
import {ObjectiveOverviewRowBased} from "../components/content-elements/ObjectiveOverviewRowBased";
import {EmployeeOverviewRowBased} from "../components/content-elements/EmployeeOverviewRowBased";
import {EmployeeOverviewColumnBased} from "../components/content-elements/EmployeeOverviewColumnBased";
import {PERSIST_TAB_NAVIGATION_SELECTION_PREFIX} from "../global-code/hooks/useGlobalSettings";
import {OrganisationsSummary} from "../components/content-elements/Dashboard/OrganisationsSummary";

const Home = props => {
    const globalContext = useContext(globalStateContext);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [keyResultsUpdated, setKeyResultsUpdated] = useState(false);
    const [refreshOverview, setRefreshOverview] = useState(false);

    let subpageOptions = [
        // {title: 'Dashboard', values: [9], notificationNumber: 0},
        {title: 'Objectives', values: [1, 7], notificationNumber: 0},
        {title: 'Organisation', values: [3, 6], notificationNumber: 0},
        {title: 'Employee', values: [4, 8], notificationNumber: 0},
        {title: 'Strategy Overview', values: [2], notificationNumber: 0},
        {title: 'Progress', values: [5], notificationNumber: 0},
    ];

    const [selectedValue, setSelectedValue] = useState(Number(GetPersistedValue(PERSIST_TAB_NAVIGATION_SELECTION_PREFIX + subpageOptions[0].title, subpageOptions[0].values[0])));

    const changeNavigationTab = value => {
        setSelectedValue(value);
    }

    function getSelectedTabIndex(value) {
        let result = 1;
        subpageOptions.map((tab, index) => {
            if (tab.values.indexOf(value) >= 0) {
                result = index + 1;
            }
        });

        return result;
    }

    const updateKeyResultDependentData = () => {
        setKeyResultsUpdated(!keyResultsUpdated);
        setRefreshOverview(!refreshOverview);
    }

    const [isLoadingTeamData, teamData] = useHttp(
        process.env.REACT_APP_API_URL + 'Organisations/TeamInvolvement/' + props.organisationId + "?tokenId=" + props.activeToken + "&startQuarterAndYear=" + props.startQuarterAndYear,
        [refreshOverview, props.startQuarterAndYear, props.keyResultsUpdatedFlag]
    )

    const [isLoadingObjectivesData, objectivesData] = useHttp(
        process.env.REACT_APP_API_URL + 'KeyResults/KeyResults/' + props.organisationId + "?tokenId=" + props.activeToken + "&startQuarterAndYear=" + props.startQuarterAndYear,
        [refreshOverview, props.startQuarterAndYear, props.keyResultsUpdatedFlag]
    );

    const [isLoading, employeesData] = useHttp(
        process.env.REACT_APP_API_URL + 'Employee/EmployeeInvolvement/' + globalContext.activeOrganisationId + "?tokenId=" + globalContext.activeTokenId + "&startQuarterAndYear=" + props.startQuarterAndYear,
        [props.year, refreshOverview, props.keyResultsUpdatedFlag]
    )

    let content = <LoadingIndicator message="Loading overview Data"/>

    if (teamData && objectivesData) {
        content = (
            <> 
                <div style={{minWidth: "3000px"}}>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <PageHeader icon={props.iconName} pageName="Dashboard" organisationName={props.organisationTitle}/>
                            </td>
                            <td style={{verticalAlign: "top", paddingLeft: "50px"}}>
                                <div className="subpage-selector-container">
                                    <TabNavigation buttons={subpageOptions} value={selectedValue} onChangeEvent={changeNavigationTab} selectedTabIndex={getSelectedTabIndex(selectedValue)}/>
                                </div>
                            </td>
                            <td>
                                <div className="show-hide-filter-selector-container center-contents-vertically-horizontally">
                                    <label htmlFor="showFilterOptionsButton" style={{marginRight: "15px"}}>Show actions</label>
                                    <InputSwitch inputId="showFilterOptionsButton" name="showFilterOptionsButton" checked={showFilterOptions} onChange={(e) => setShowFilterOptions(!showFilterOptions)}/>
                                </div>
                            </td>
                            <td>
                                <div className="show-hide-filter-selector-container center-contents-vertically-horizontally">
                                    <label htmlFor="year" style={{marginRight: "15px"}}>Qrt./Year</label>
                                    <Dropdown name="year" className="fill-container-width" options={TimeSpanSelectorValues()} value={globalContext.chosenQuarterAndYear} onChange={props.handleChangeOfQuarterAndYear}/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
    
                    <div className="subpage-navigation-container">
                        <br/>
                        <div id="keyResultsContainer" style={selectedValue == 1 || selectedValue == 7 ? {display: "inline-block"} : {display: "none"}}>
                            {selectedValue == 1 && <ObjectiveOverviewColumnBased
                                objectivesData={objectivesData}
                                showFilters={showFilterOptions}
                                keyResultsUpdatedFlag={keyResultsUpdated}
                                updateDependentData={updateKeyResultDependentData}
                            />}
    
                            {selectedValue == 7 &&
                                <ObjectiveOverviewRowBased
                                    data={objectivesData}
                                    showFilters={showFilterOptions}
                                    startQuarterAndYear={props.startQuarterAndYear}
                                    keyResultsUpdatedFlag={keyResultsUpdated}
                                    updateDependentData={updateKeyResultDependentData}
                                />}
                        </div>
                        <div id="strategyOverviewContainer" style={selectedValue == 2 ? {display: "inline-block"} : {display: "none"}}>
                            <StrategyOverview
                                showFilters={showFilterOptions}
                                startQuarterAndYear={props.startQuarterAndYear}
                                keyResultsUpdatedFlag={keyResultsUpdated}
                            />
                        </div>
                        <div id="organisationOverviewContainer" style={selectedValue == 3 || selectedValue == 6 ? {display: "inline-block"} : {display: "none"}}>
                            {selectedValue == 3 && <OrganisationalOverviewColumnBased
                                data={teamData}
                                showFilters={showFilterOptions}
                                startQuarterAndYear={props.startQuarterAndYear}
                                keyResultsUpdatedFlag={keyResultsUpdated}
                                updateDependentData={updateKeyResultDependentData}
                            />}
    
                            {selectedValue == 6 && <OrganisationalOverviewRowBased
                                data={teamData}
                                showFilters={showFilterOptions}
                                startQuarterAndYear={props.startQuarterAndYear}
                                keyResultsUpdatedFlag={keyResultsUpdated}
                                updateDependentData={updateKeyResultDependentData}
                            />}
                        </div>
                        <div id="employeeOverviewContainer" style={selectedValue == 4 || selectedValue == 8 ? {display: "inline-block"} : {display: "none"}}>
                            {selectedValue == 4 && <EmployeeOverviewColumnBased
                                startQuarterAndYear={props.startQuarterAndYear}
                                keyResultsUpdatedFlag={keyResultsUpdated}
                                updateDependentData={updateKeyResultDependentData}
                                employeesData={employeesData}
                            />}
    
                            {selectedValue == 8 && <EmployeeOverviewRowBased
                                startQuarterAndYear={props.startQuarterAndYear}
                                keyResultsUpdatedFlag={keyResultsUpdated}
                                updateDependentData={updateKeyResultDependentData}
                                employeesData={employeesData}
                            />}
                        </div>
                        <div id="progressionContainer" style={selectedValue == 5 ? {display: "inline-block"} : {display: "none"}}>
                            <ProgressionOverview
                                activeOrganisationId={props.organisationId}
                                activeUserId={props.activeUserId}
                                activeToken={props.activeToken}
                                showFilters={showFilterOptions}
                                startQuarterAndYear={props.startQuarterAndYear}
                                keyResultsUpdatedFlag={keyResultsUpdated}
                            />
                        </div>
                        <div id="dashboardContainer" style={selectedValue == 9 ? {display: "inline-block"} : {display: "none"}}>
                            {/*<OrganisationsSummary activeOrganisationId={props.organisationId} tokenId={props.activeToken} cutOffNumberOfDaysAgo={30} keyResultsUpdatedFlag={keyResultsUpdated} />*/}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return content;
}

export default Home;