import React, {useEffect, useState} from 'react';
import {InputText} from "primereact/inputtext";
import './EditUser.css'
import {Tooltip} from "primereact/tooltip";
import {ToggleButton} from "primereact/togglebutton";
import {UploadUserImage} from "../../global-code/Components/UploadUserImage";
import {GetUserImageAPIEndPointUri} from "../../global-code/hooks/useSolutionFunctions";
import {getActiveToken, isUserAdministrator, isUserReadOnly, isUserUser} from "../../global-code/hooks/useSecurity";
import {Dropdown} from "primereact/dropdown";
import {GetSelectItemsFromJsonList, IsValueEmptyOrUndefined} from "../../global-code/hooks/useGenericFunctions";
import {useHttpOnCondition} from "../../global-code/hooks/useHttp";

export const EditUser = props => {
    const roleAdministratorId = "C918AE0D-D3E3-4585-BE52-D930443664CB";
    const roleReadOnlyId = "1E046EA2-86F0-43D9-9B93-9F52795002AC";
    const roleUserId = "928322CF-C1BC-4FF9-B800-04BB7E15EC19";
    
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imageBytes, setImageBytes] = useState(null);
    const [roleAdministratorSelected, setRoleAdministratorSelected] = useState(isUserAdministrator(props.user.userRoles, props.organisationId));
    const [roleReadOnlySelected, setRoleReadOnlySelected] = useState(isUserReadOnly(props.user.userRoles, props.organisationId));
    const [roleUserSelected, setRoleUserSelected] = useState(isUserUser(props.user.userRoles, props.organisationId));

    const [values, setValues] = useState({
        id: valueIfNull(props.user.id, ""),
        name: valueIfNull(props.user.name, ""),
        initials: valueIfNull(props.user.initials, ""),
        title: valueIfNull(props.user.title, ""),
        email: valueIfNull(props.user.email, ""),
        active: valueIfNull(props.user.active, true),
        teamId: valueIfNull(props.user.primaryTeamId, ""),
        roles: valueIfNull(props.user.userRoles, "")
    });

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validateFormInput(values));
        setIsSubmitting(true);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }
   
    const postData = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "Users/CreateUpdateUser", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    id: values.id,
                    userName: values.name,
                    initials: values.initials === null ? "" : values.initials,
                    title: values.title === null ? "" : values.title,
                    email: values.email === null ? "" : values.email,
                    active: values.active,
                    roles: GetActiveRolesString(),
                    tokenId: props.activeToken,
                    activeOrganisationId: props.organisationId,
                    teamId: values.teamId,
                    userImage: imageBytes === null ? "" : imageBytes
                }),
            });

            if (res.status === 200) {
                if (props.callAfterSave != null) {
                    props.callAfterSave();
                }
                props.callToHideDialog();
            }
            
            if (res.status === 409) {
                alert("There is already a user with this e-mail address. Cannot create this user.");
            }
            
            if (res.status !== 409 && res.status !== 200) {
                alert(res.text());
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            postData();
        }
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function valueIfNull(value, valueIfNull) {
        return value === null ? valueIfNull : value;
    }
    
    function validateFormInput(values) {
        let errors = {};

        if (!values.name.trim()) {
            errors.name = "You need to enter a name for the user";
        }
        
        if (values.name.trim().length > 100) {
            errors.title = "Name can only be 100 characters long";
        }
        
        if (!values.initials.trim()) {
            errors.initials = "This is a mandatory field";
        }
        
        if (values.initials.trim().length > 10) {
            errors.initials = "Initials cannot be more than 10 characters long";
        }
        
        if (!validateEmail(values.email)) {
            errors.email = "Not a valid email";
        }
        
        if (values.teamId === '0' || IsValueEmptyOrUndefined(values.teamId)) {
            errors.teamId = "You must select a team for this user";
        }
        
        if (GetActiveRolesString().length === 0) {
            errors.roles = "The user needs to have at least one role";
        }
        
        return errors;
    }
    
    function validateEmail (email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    
   function GetActiveRolesString() {
        let result = "";
        
        if (roleAdministratorSelected) result += roleAdministratorId + ",";
        if (roleReadOnlySelected) result += roleReadOnlyId + ",";
        if (roleUserSelected) result += roleUserId + ",";
        
        if (result.length > 0) {
            result = result.substring(0, result.length - 1);
        }
        
        return result;
   }
   
    return (
            <>
                <div className="edit-user-container">
                    <div className="user-image">
                        { (props.addNew || !props.user.hasPhoto) && <UploadUserImage imageUrl="/resources/basic-graphics/missingperson.png" imageByteSetState={setImageBytes} /> }
                        { !props.addNew && props.user.hasPhoto && <UploadUserImage imageUrl={GetUserImageAPIEndPointUri(props.activeToken, values.id) + "&v=" + new Date().getTime()} imageByteSetState={setImageBytes} /> }
                    </div>

                    <form id="editUser" onSubmit={handleSubmit}>
                        
                        <div className="field-wrapper">
                            <Tooltip target=".helpName" style={{width: "400px"}} position="left">Full name of user</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpName">User Name</span></div>
                            <span className="p-float-label">
                                <InputText name="name" className="fill-container-width" type="text" value={values.name} onChange={handleChange} />
                                {errors.name && <p className="error-message">{errors.name}</p>}
                            </span>
                        </div>
        
                        <div className="field-wrapper">
                            <Tooltip target=".helpTitle" style={{width: "400px"}} position="left">The title of the user (CEO, sales representative, developer etc.)</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpTitle" >Title</span></div>
                            <span className="p-float-label">
                                <InputText name="title" className="fill-container-width" type="text" value={values.title} onChange={handleChange} />
                                {errors.title && <p className="error-message">{errors.title}</p>}
                            </span>
                        </div>

                        <div className="field-wrapper">
                            <div className="input-field-title-container"><span className="input-field-title-text" >Initials</span></div>
                            <span className="p-float-label">
                                <InputText name="initials" className="fill-container-width" type="text" value={values.initials} onChange={handleChange} />
                                {errors.initials && <p className="error-message">{errors.initials}</p>}
                            </span>
                        </div>

                        <div className="field-wrapper">
                            <div className="input-field-title-container"><span className="input-field-title-text" >Email</span></div>
                            <span className="p-float-label">
                                <InputText name="email" type="email" className="fill-container-width" value={values.email} onChange={handleChange} />
                                {errors.email && <p className="error-message">{errors.email}</p>}
                            </span>
                        </div>

                        <div className="field-wrapper">
                            <Tooltip target=".helpTeam" style={{width: "400px"}} position="left">The team that this person is primarily associated with</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpTeam" >Primary team</span></div>
                            <span className="p-float-label">
                                <Dropdown name="teamId" className="fill-container-width" options={GetSelectItemsFromJsonList(props.teamList, "title", true)} value={values.teamId} onChange={handleChange} />
                                {errors.teamId && <p className="error-message">{errors.teamId}</p>}
                            </span>
                        </div>


                        <div className="field-wrapper">
                            <Tooltip target=".helpRoles" style={{width: "400px"}} position="left">Choose the access level for the user (hold mouse over role to get description). Note that you can choose several roles, but the role with highest access "wins"</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text helpRoles" >User Roles</span></div>
                            {/*<Dropdown className="fill-container-width" options={getSelectItemsFromJsonList(props.rolesList, "title")} name="roles" value={null} onChange={handleChange} />*/}
                            <ToggleButton name={props.rolesList[0].id} checked={roleAdministratorSelected} onChange={(e) => setRoleAdministratorSelected(!roleAdministratorSelected)} onLabel={props.rolesList[0].title} offLabel={props.rolesList[0].title} onIcon="pi pi-check" offIcon="pi pi-times" />
                            <ToggleButton name={props.rolesList[1].id} checked={roleReadOnlySelected} onChange={(e) => setRoleReadOnlySelected(!roleReadOnlySelected)} onLabel={props.rolesList[1].title} offLabel={props.rolesList[1].title} onIcon="pi pi-check" offIcon="pi pi-times" />
                            <ToggleButton name={props.rolesList[2].id} checked={roleUserSelected} onChange={(e) => setRoleUserSelected(!roleUserSelected)} onLabel={props.rolesList[2].title} offLabel={props.rolesList[2].title} onIcon="pi pi-check" offIcon="pi pi-times" />
                            {errors.roles && <p className="error-message">{errors.roles}</p>}
                        </div>

                    </form>
                </div>
            </>
    );
}