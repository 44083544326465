import React, {useContext, useEffect, useState} from 'react';
import './StrategyCanvas.css';
import {CloseButton, GridOnOff} from "../../global-code/hooks/useButtons";
import logo from "../../resources/basic-graphics/VisualOKRLogoBlack.png";
import {useHttp, useHttpOnCondition} from "../../global-code/hooks/useHttp";
import {LoadingIndicator} from "../../components/content-elements/LoadingIndicator";
import {AddButtonPopOut, KeyResultCard, ObjectiveCard, StrategicGoalCard} from "./useStrategyCanvasComponents";
import {Sidebar} from 'primereact/sidebar';
import {EditKeyResult} from "../../components/EditComponents/EditKeyResult";
import {globalStateContext} from "../../App";
import {EditObjective} from "../../components/EditComponents/EditObjective";
import {EditStrategicGoal} from "../../components/EditComponents/EditStrategicGoal";
import {ExtractString, GetDefaultTimeSpanValue, GetPersistedValue, GetQuarterFromSelectedTimeSpan, GetYearFromSelectedTimeSpan, PersistValue, TimeSpanSelectorValues} from "../../global-code/hooks/useGenericFunctions";
import {Dropdown} from "primereact/dropdown";
import {PERSIST_KEY_DISPLAY_CANVAS_YEAR} from "../../global-code/hooks/useGlobalSettings";

export const StrategyCanvas = (props) => {
    const globalContext = useContext(globalStateContext);
    const [gridOn, setGridOn] = useState(true);
    const [refreshOverview, setRefreshOverview] = useState(false);
    const [rightSidebarVisible, setRightSidebarVisible] = useState(false);
    const [activeStrategicGoalId, setActiveStrategicGoalId] = useState(null);
    const [activeObjectiveId, setActiveObjectiveId] = useState(null);
    const [activeKeyResultId, setActiveKeyResultId] = useState(null);
    const [showStrategicGoalEdit, setShowStrategicGoalEdit] = useState(false);
    const [showObjectivetEdit, setShowObjectivetEdit] = useState(false);
    const [showKeyResultEdit, setShowKeyResultEdit] = useState(false);
    const [addNewKeyResultData, setAddNewKeyResultData] = useState(null);
    const [addNewObjectiveData, setAddNewObjectiveData] = useState(null);
    const [addNewStrategicGoalData, setAddNewStrategicGoalData] = useState(null);
    const [chosenQuarterAndYear, setChosenQuarterAndYear] = useState(GetChosenQuarterAndYear());
    
    const canvas_start_left = 0;
    const canvas_start_top = 0;
    const header_container_height = 110;
    const strategic_goal_card_min_height = 70;
    const strategic_goal_card_width = 300;
    const strategic_goal_card_horizontal_gap = 20;
    const objective_card_width = 300;
    const objective_card_min_height = 70;
    const objective_card_horizontal_gap = 40;
    const objective_card_vertical_gap = 40;
    const key_result_card_width = 300;
    const key_result_card_height = 70;
    const key_result_card_horizontal_gap = 10;
    const key_result_card_vertical_gap = 20;
    let current_left_pos = canvas_start_left;
    let current_strategic_goal_top_pos = canvas_start_top + header_container_height;
    let current_objective_top_pos = canvas_start_top + header_container_height;
    let current_key_result_top_pos = [canvas_start_top + header_container_height, canvas_start_top + header_container_height, canvas_start_top + header_container_height, canvas_start_top + header_container_height];
    
    // SECTION Data fetch
    const [isLoadingBaseData, items] = useHttp(
        process.env.REACT_APP_API_URL + 'strategy/strategy/' + globalContext.activeOrganisationId + "?tokenId=" + globalContext.activeTokenId + "&startQuarterAndYear=" + chosenQuarterAndYear,
        [refreshOverview, chosenQuarterAndYear]
    );

    const [isLoadingStrategicGoal, strategicGoalData] = useHttpOnCondition(
        activeStrategicGoalId != null,
        process.env.REACT_APP_API_URL + 'Strategy/GetStrategicGoal/' + activeStrategicGoalId + "?tokenId=" + globalContext.activeTokenId,
        [activeStrategicGoalId, refreshOverview, chosenQuarterAndYear]
    );

    const [isLoadingObjective, objectiveData] = useHttpOnCondition(
        activeObjectiveId != null,
        process.env.REACT_APP_API_URL + 'objectives/' + activeObjectiveId + "?tokenId=" + globalContext.activeTokenId,
        [activeObjectiveId, refreshOverview, chosenQuarterAndYear]
    );

    const [isLoadingKeyResult, keyResultData] = useHttpOnCondition(
        activeKeyResultId != null,
        process.env.REACT_APP_API_URL + 'KeyResults/' + activeKeyResultId + "?tokenId=" + globalContext.activeTokenId + "&organisationId=" + globalContext.activeOrganisationId, 
        [activeKeyResultId, refreshOverview, chosenQuarterAndYear]
    );

    // SECTION toolbar handling
    const CloseCanvas = () => {
        window.location = "/";
    }
    
    const TurnGridOnOff = () => {
        setGridOn(!gridOn);
    }

    const handleChangeOfQuarterAndYear = e => {
        let value = e.target.value;
        PersistValue(PERSIST_KEY_DISPLAY_CANVAS_YEAR, value);
        setChosenQuarterAndYear(value);
    }

    function GetChosenQuarterAndYear() {
        return GetPersistedValue(PERSIST_KEY_DISPLAY_CANVAS_YEAR, GetDefaultTimeSpanValue());
    }
    
    // SECTION canvas element positioning
    const GetMaxNumberOfKeyResultsAcrossQuarters = (objective) => {
        let result = 0;
        let numberOfKeyResults = 0;
        for (let i = 1; i < 5; i++) {
            numberOfKeyResults = objective.keyResults.filter((kr) => kr.quarter === i).length;
            if (numberOfKeyResults > result) result = numberOfKeyResults;
        }
        result += 1;
        return result;
    }
    
    /* TO-DO: Mangler at tage højde for højden af Strategic Goal og Objective (hvis der kun er ét Key Result eksempelvis) */
    const GetStrategicGoalTopPositionAndUpdateCurrentTopPosition = (strategicGoal) => {
        let result = current_strategic_goal_top_pos;
        let heightOfKeyResults = 0;
        strategicGoal.objectives.map( (objective) => {
            heightOfKeyResults += objective_card_vertical_gap + ((key_result_card_height + key_result_card_vertical_gap) * GetMaxNumberOfKeyResultsAcrossQuarters(objective));
        })
        current_strategic_goal_top_pos += heightOfKeyResults === 0 ? strategic_goal_card_min_height + objective_card_vertical_gap : heightOfKeyResults;
        if (heightOfKeyResults === 0) current_objective_top_pos = current_strategic_goal_top_pos;
        return result;
    }

    /* TO-DO: BEMÆRK - hvis der kun er ét Key Result OG et key result er sat til at være lavere end et objective, så giver nedenstående et problem */
    const GetObjectiveTopPositionAndUpdateCurrentTopPosition = (objective) => {
        let result = current_objective_top_pos;
        for (let i = 0; i < 4; i++) current_key_result_top_pos[i] = current_objective_top_pos;
        current_objective_top_pos += objective_card_vertical_gap + ((key_result_card_height + key_result_card_vertical_gap) * GetMaxNumberOfKeyResultsAcrossQuarters(objective));
        return result;
    }
    
    const GetLeftPositionOfObjectiveColumn = () => {
        return canvas_start_left + strategic_goal_card_width + strategic_goal_card_horizontal_gap;
    }

    /* QuarterPosition is the "slot number" of the quarter being displayed. The first slot might be occupied by Q3, then Q4, then Q1 and so on */
    const GetKeyResultTopPositionAndUpdateCurrentTopPosition = (quarterPosition) => {
        let result = current_key_result_top_pos[quarterPosition-1];
        current_key_result_top_pos[quarterPosition-1] += key_result_card_height + key_result_card_vertical_gap;
        return result;
    }
    
    const GetKeyResultAddButtonPosition = (objective, quarterPosition) => {
        return current_objective_top_pos - key_result_card_height - key_result_card_vertical_gap - objective_card_vertical_gap;
    }

    /* QuarterPosition is the "slot number" of the quarter being displayed. The first slot might be occupied by Q3, then Q4, then Q1 and so on */
    const GetLeftPositionOfKeyResult = (quarterPosition) => {
        return GetLeftPositionOfObjectiveColumn() + objective_card_width + objective_card_horizontal_gap + (key_result_card_width + key_result_card_horizontal_gap) * (quarterPosition-1);
    }
    
    const CalculateHeightOfStrategicGoal = (objectives) => {
        let result = 0;
        if (objectives.length === 0) return result = strategic_goal_card_min_height;
        objectives.map( objective => {
            result += (key_result_card_height + key_result_card_vertical_gap) * GetMaxNumberOfKeyResultsAcrossQuarters(objective) + objective_card_vertical_gap;
        })
        result += -objective_card_vertical_gap - key_result_card_vertical_gap;
        return result;
    }
    
    const CalculateHeightOfObjective = (objective) => {
        let maxNumberOfKeyResultsAcrossQuarters = GetMaxNumberOfKeyResultsAcrossQuarters(objective);
        return ((key_result_card_height + key_result_card_vertical_gap) * maxNumberOfKeyResultsAcrossQuarters) - key_result_card_vertical_gap;
    }
    
    const CalculateWidthOfKeyResultTitle = () => {
        return ((key_result_card_width + key_result_card_horizontal_gap) * 4) - key_result_card_horizontal_gap;
    }
    
    // SECTION edit Handling for Strategic Goals, Objectives and Key Results
    const DoEditStrategicGoal = (e) => {
        HideAllEdits();
        setActiveStrategicGoalId(e.currentTarget.id);
        setShowStrategicGoalEdit(true);
        setRightSidebarVisible(true);
        console.log('her');
        // setAddNewStrategicGoalData(null);
    }

    const DoEditObjective = (e) => {
        HideAllEdits();
        setActiveObjectiveId(e.currentTarget.id);
        setShowObjectivetEdit(true);
        setRightSidebarVisible(true);
        setAddNewObjectiveData(null);
    }
    
    const DoEditKeyResult = (e) => {
        HideAllEdits();
        setShowKeyResultEdit(true);
        setActiveKeyResultId(e.currentTarget.id);
        setRightSidebarVisible(true);
        setAddNewKeyResultData(null);
    }    
    
    const HideAllEdits = () => {
        setRightSidebarVisible(false);
        setShowStrategicGoalEdit(false);
        setShowObjectivetEdit(false);
        setShowKeyResultEdit(false);
    }
    
    const GetSidebarColor = () => {
        if (showStrategicGoalEdit) return "#5b727f";
        if (showObjectivetEdit) return "#865425";
        if (showKeyResultEdit) return "#bcad87";
    }
    
    const StrategicGoalEditDoneEventHandler = () => {
        HideAllEdits();
        setActiveStrategicGoalId(null);
        setRefreshOverview(!refreshOverview);
        setAddNewStrategicGoalData(null);
        globalContext.setUpdateStrategyList(!globalContext.updateStrategyList);
    }
    
    const ObjectiveEditDoneEventHandler= () => {
        HideAllEdits();
        setActiveObjectiveId(null);
        setRefreshOverview(!refreshOverview);
        setAddNewObjectiveData(null);
        globalContext.setUpdateObjectiveList(!globalContext.updateObjectiveList);
    }
    
    const KeyResultEditDoneEventHandler = () => {
        HideAllEdits();
        setActiveKeyResultId(null);
        setRefreshOverview(!refreshOverview);
        setAddNewKeyResultData(null);
    }
    
    const handleAddKeyResult = (id) => {
        let s1 = "add_key_result_button_q";
        let s2 = "_";
        let quarterSlot = ExtractString(id, s1, s2);
        let objectiveId = ExtractString(id, "q" + quarterSlot + "_","");
        HideAllEdits();
        setShowKeyResultEdit(true);
        setActiveKeyResultId(null);
        setRightSidebarVisible(true);
        setAddNewKeyResultData(CreateItemObjectForNewKeyResult(quarterSlot, objectiveId));        
    }
    
    const CreateItemObjectForNewKeyResult = (quarterSlot, objectiveId) => {
        return ({
            id: "FCCF163D-16FB-4A21-9E2F-10EC39984BDF",
            title: "",
            whatToDo: "",
            objectiveId: objectiveId,
            measurableGoal: 0,
            levelYellow: 0,
            levelGreen: 0,
            year: GetYearFromSelectedTimeSpan(chosenQuarterAndYear, quarterSlot),
            quarter: GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, quarterSlot),
            anchorTeamId: null,
            responsiblePersonId: globalContext.activeUserId
        })
    }

    const handleAddObjective = (id) => {
        let s1 = "add_objective_button_";
        let s2 = "";
        let strategicGoalId = ExtractString(id, s1, s2);
        HideAllEdits();
        setShowObjectivetEdit(true);
        setActiveObjectiveId(null);
        setRightSidebarVisible(true);
        setAddNewObjectiveData(CreateItemObjectForNewObjective(strategicGoalId));
    }

    const CreateItemObjectForNewObjective = (strategicGoalId) => {
        return ({
            id: "FCCF163D-16FB-4A21-9E2F-10EC39984BDF",
            title: "",
            description: "",
            strategicGoalId: strategicGoalId,
            validFromYear: new Date().getFullYear(),
            validUntilYear: new Date().getFullYear() + 5
        })
    }

    const handleAddStrategicGoal = (id) => {
        HideAllEdits();
        setShowStrategicGoalEdit(true);
        setActiveStrategicGoalId(null);
        setRightSidebarVisible(true);
        setAddNewStrategicGoalData(CreateItemObjectForNewStrategicGoal());
        }

    const CreateItemObjectForNewStrategicGoal = () => {
        return ({
            id: "",
            title: "",
            description: "",
            strategicGoalId: "",
            organisationId: globalContext.activeOrganisationId
        })
    }

  let content = <LoadingIndicator message="Loading Data for Canvas"/>
    
    if (items) {
        
        content = (
            <div className="strategy-canvas-container" style={{backgroundSize: gridOn ? "" : "0 0", height: "5000px"}}>
                
                <Sidebar className="p-sidebar-md" visible={rightSidebarVisible} position="right" modal={false} dismissable={true} style={{borderLeft: "5px solid " + GetSidebarColor(), borderTop: "0 solid " + GetSidebarColor()}} onHide={HideAllEdits}>
                    {(strategicGoalData || addNewStrategicGoalData) && showStrategicGoalEdit &&
                        <>
                            <div className="right-sidebar-header-name" style={{color: GetSidebarColor()}}>Strategy</div>
                            <div className="right-sidebar-header">{addNewStrategicGoalData ? "New Strategic Goal" : strategicGoalData.title}</div>
                            {!addNewStrategicGoalData &&
                                <EditStrategicGoal activeToken={globalContext.activeTokenId} data={strategicGoalData} activeOrganisationId={globalContext.activeOrganisationId} activeUserId={globalContext.activeUserId} callToHideDialog={HideAllEdits} callAfterSave={StrategicGoalEditDoneEventHandler} />    
                            }

                            {addNewStrategicGoalData &&
                                <EditStrategicGoal activeToken={globalContext.activeTokenId} data={addNewStrategicGoalData} activeOrganisationId={globalContext.activeOrganisationId} activeUserId={globalContext.activeUserId} callToHideDialog={HideAllEdits} callAfterSave={StrategicGoalEditDoneEventHandler} />
                            }
                            
                        </>
                    }
                    
                    {(objectiveData || addNewObjectiveData) && showObjectivetEdit &&
                        <>
                            <div className="right-sidebar-header-name" style={{color: GetSidebarColor()}}>Objective</div>
                            <div className="right-sidebar-header">{addNewObjectiveData ? "New Objective" : objectiveData.title}</div>
                            {!addNewObjectiveData &&
                                <EditObjective data={objectiveData} callAfterSave={ObjectiveEditDoneEventHandler} callToHideDialog={HideAllEdits} activeToken={globalContext.activeTokenId} strategyList={globalContext.strategyList} />
                            }
                            {addNewObjectiveData &&
                                <EditObjective data={addNewObjectiveData} callAfterSave={ObjectiveEditDoneEventHandler} callToHideDialog={HideAllEdits} activeToken={globalContext.activeTokenId} strategyList={globalContext.strategyList} />
                            }                            
                        </>
                    }
                    {(keyResultData || addNewKeyResultData) && showKeyResultEdit &&
                        <>
                            <div className="right-sidebar-header-name" style={{color: GetSidebarColor()}}>Key result</div>
                            <div className="right-sidebar-header">{addNewKeyResultData ? "New Key Result" : keyResultData.title}</div>
                            {!addNewKeyResultData &&
                                <EditKeyResult id={keyResultData.id} activeToken={globalContext.activeTokenId} data={keyResultData} objectiveList={globalContext.objectiveList} teamList={globalContext.teamList} userList={globalContext.userList} callAfterSave={KeyResultEditDoneEventHandler} callToHideDialog={HideAllEdits} isUserAdministrator={props.isUserAdministrator} isUserReadOnly={props.isUserReadOnly} isUserUser={props.isUserUser} showCloseButton={true} />
                            }
                            {addNewKeyResultData &&
                                <EditKeyResult id={null} activeToken={globalContext.activeTokenId} data={addNewKeyResultData} objectiveList={globalContext.objectiveList} teamList={globalContext.teamList} userList={globalContext.userList} callAfterSave={KeyResultEditDoneEventHandler} callToHideDialog={HideAllEdits} isUserAdministrator={props.isUserAdministrator} isUserReadOnly={props.isUserReadOnly} isUserUser={props.isUserUser} showCloseButton={true} />
                            }
                        </>
                    }
                </Sidebar>
                
                <div className="top-bar">
                    <div className="left-navigation-section center-contents-vertically">
                        <div className="VOKR-logo"><img src={logo} alt='Company Logo'/></div>
                        <div className="title">Strategy Canvas</div>
                    </div>               
                    <div className="center-navigation-section center-contents-vertically">
                        <GridOnOff id="gridOnOffButton" sizePixel={35} on={gridOn} onClick={TurnGridOnOff} />
                        <span className="spacer" />
                        <Dropdown id="chosenQuarterAndYear" name="year" className="center-contents-vertically" options={TimeSpanSelectorValues()} value={GetChosenQuarterAndYear()} style={{width: "185px", height: "30px"}} onChange={handleChangeOfQuarterAndYear} />
                    </div>
                    <div className="right-navigation-section center-contents-vertically">
                        <div className="close-button">
                            <CloseButton id="closeCanvasButton" sizePixel={35} onClick={CloseCanvas} />
                        </div>
                    </div>                
                </div>
    
                <div className="strategy-canvas-contents">
                
                    <div className="canvas-column-title" style={{top: canvas_start_top + 50 + "px", width: strategic_goal_card_width + "px"}}>Strategic Goal</div>
                    <div className="canvas-column-title" style={{top: canvas_start_top + 50 + "px", width: objective_card_width + "px", left: GetLeftPositionOfObjectiveColumn() + "px"}}>Objectives</div>
                    <div className="canvas-column-title" style={{top: canvas_start_top + 50 + "px", left: GetLeftPositionOfKeyResult(1) + "px", width: key_result_card_width + "px"}}>Q{GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 1)}-{GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 1).toString().substring(2)}</div>
                    <div className="canvas-column-title" style={{top: canvas_start_top + 50 + "px", left: GetLeftPositionOfKeyResult(2) + "px", width: key_result_card_width + "px"}}>Q{GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 2)}-{GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 2).toString().substring(2)}</div>
                    <div className="canvas-column-title" style={{top: canvas_start_top + 50 + "px", left: GetLeftPositionOfKeyResult(3) + "px", width: key_result_card_width + "px"}}>Q{GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 3)}-{GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 3).toString().substring(2)}</div>
                    <div className="canvas-column-title" style={{top: canvas_start_top + 50 + "px", left: GetLeftPositionOfKeyResult(4) + "px", width: key_result_card_width + "px"}}>Q{GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 4)}-{GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 4).toString().substring(2)}</div>
                    
                    {items.map( strategicGoal => (
                        <React.Fragment key={strategicGoal.id}>
                            <StrategicGoalCard id={strategicGoal.id} title={strategicGoal.title} top={GetStrategicGoalTopPositionAndUpdateCurrentTopPosition(strategicGoal)} left={current_left_pos} height={CalculateHeightOfStrategicGoal(strategicGoal.objectives)} width={strategic_goal_card_width} clickEventHandler={DoEditStrategicGoal} />
                            {strategicGoal.objectives.map( objective => (
                                <React.Fragment key={objective.id}>
                                    
                                    <ObjectiveCard id={objective.id} title={objective.title} top={GetObjectiveTopPositionAndUpdateCurrentTopPosition(objective)} left={GetLeftPositionOfObjectiveColumn()} height={CalculateHeightOfObjective(objective)} width={objective_card_width} clickEventHandler={DoEditObjective} />
                                    
                                    {objective.keyResults.filter((kr) => kr.quarter === GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 1) && kr.year === GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 1)).map( keyresult => (
                                        <React.Fragment key={keyresult.id}>
                                            <KeyResultCard id={keyresult.id} title={keyresult.title} top={GetKeyResultTopPositionAndUpdateCurrentTopPosition(1)} left={GetLeftPositionOfKeyResult(1)} height={key_result_card_height} width={key_result_card_width} clickEventHandler={DoEditKeyResult} />
                                        </React.Fragment>
                                    ))}
                                    <AddButtonPopOut id={"add_key_result_button_q1_" + objective.id} tooltip="Add new Key Result" clickEventHandler={handleAddKeyResult} height={key_result_card_height} top={GetKeyResultAddButtonPosition()} left={GetLeftPositionOfKeyResult(1)} width={key_result_card_width} buttonColor="#bcad87" />

                                    {objective.keyResults.filter((kr) => kr.quarter === GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 2) && kr.year === GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 2)).map( keyresult => (
                                        <React.Fragment key={keyresult.id}>
                                            <KeyResultCard id={keyresult.id} title={keyresult.title} top={GetKeyResultTopPositionAndUpdateCurrentTopPosition(2)} left={GetLeftPositionOfKeyResult(2)} height={key_result_card_height} width={key_result_card_width} clickEventHandler={DoEditKeyResult} />
                                        </React.Fragment>
                                    ))}
                                    <AddButtonPopOut id={"add_key_result_button_q2_" + objective.id} tooltip="Add new Key Result" clickEventHandler={handleAddKeyResult} height={key_result_card_height} top={GetKeyResultAddButtonPosition()} left={GetLeftPositionOfKeyResult(2)} width={key_result_card_width} buttonColor="#bcad87" />
                                    
                                    {objective.keyResults.filter((kr) => kr.quarter === GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 3) && kr.year === GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 3)).map( keyresult => (
                                        <React.Fragment key={keyresult.id}>
                                            <KeyResultCard id={keyresult.id} title={keyresult.title} top={GetKeyResultTopPositionAndUpdateCurrentTopPosition(3)} left={GetLeftPositionOfKeyResult(3)} height={key_result_card_height} width={key_result_card_width} clickEventHandler={DoEditKeyResult} />
                                        </React.Fragment>
                                    ))}
                                    <AddButtonPopOut id={"add_key_result_button_q3_" + objective.id} tooltip="Add new Key Result" clickEventHandler={handleAddKeyResult} height={key_result_card_height} top={GetKeyResultAddButtonPosition()} left={GetLeftPositionOfKeyResult(3)} width={key_result_card_width} buttonColor="#bcad87" />
                                    
                                    {objective.keyResults.filter((kr) => kr.quarter === GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 4) && kr.year === GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 4)).map( keyresult => (
                                        <React.Fragment key={keyresult.id}>
                                            <KeyResultCard id={keyresult.id} title={keyresult.title} top={GetKeyResultTopPositionAndUpdateCurrentTopPosition(4)} left={GetLeftPositionOfKeyResult(4)} height={key_result_card_height} width={key_result_card_width} clickEventHandler={DoEditKeyResult} />
                                        </React.Fragment>
                                    ))}
                                    <AddButtonPopOut id={"add_key_result_button_q4_" + objective.id} tooltip="Add new Key Result" clickEventHandler={handleAddKeyResult} height={key_result_card_height} top={GetKeyResultAddButtonPosition()} left={GetLeftPositionOfKeyResult(4)} width={key_result_card_width} buttonColor="#bcad87" />

                                </React.Fragment>
                            ))}
                            <AddButtonPopOut id={"add_objective_button_" + strategicGoal.id} tooltip="Add new Objective" clickEventHandler={handleAddObjective} height={key_result_card_height} top={current_objective_top_pos - objective_card_vertical_gap} left={GetLeftPositionOfObjectiveColumn()} width={objective_card_width} buttonColor="#865425" />

                        </React.Fragment>
                    ))}
                    <AddButtonPopOut id={"add_strategic_goal_button"} tooltip="Add new Strategic Goal" clickEventHandler={handleAddStrategicGoal} height={key_result_card_height} top={current_strategic_goal_top_pos - 40} left={0} width={objective_card_width} buttonColor="#5b727f" />
                </div>
            </div>
        )
    }
    
    return content;
}