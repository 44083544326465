import React, {useContext, useState} from 'react';
import './KeyResultList.css';
import {KeyResultCard} from "./KeyResultCard";
import {PlusSymbol} from "../svg-symbols/PlusSymbol";
import {EditKeyResult} from "../EditComponents/EditKeyResult";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {KeyResultCardCompact} from "./KeyResultCardCompact";
import {globalStateContext} from "../../App";

export const KeyResultList = props =>  {
    const globalContext = useContext(globalStateContext);
    
    function submitForm(e) {
        document.getElementById('editKeyResult').dispatchEvent(new Event('submit', { 'bubbles' : true, 'cancelable': true }));
    }
    
    return (
        <>
            <div key={"keyresult_list_container_" + props.id} className="keyresult-list-container">
                <div key={"keyresult_container_" + props.id} className="keyresult-container">
                    { props.items.map(item => (
                        <div key={item.id} style={{marginBottom: "10px"}}>
                            { props.showCompactView ?
                            <KeyResultCardCompact
                                item={item}
                                showResponsible={props.showResponsible}
                                showLive={props.showLive}
                                showEditOptions={props.showEditOptions}
                                liveThreshold={props.liveThreshold}
                                objectiveList={globalContext.objectiveList}
                                teamList={globalContext.teamList}
                                userList={globalContext.userList}
                                callAfterSave={props.callAfterSave}
                                activeUserId={globalContext.userInformation.userId}
                                activeToken={globalContext.activeTokenId}
                                isUserAdministrator={globalContext.userIsAdministrator}
                                isUserReadOnly={globalContext.userIsReadOnly}
                                isUserUser={globalContext.userIsUser}
                            /> : null }

                            { !props.showCompactView ?
                             <KeyResultCard
                                 item = {item}
                                 showResponsible={props.showResponsible}
                                 showLive={props.showLive}
                                 showEditOptions={props.showEditOptions}
                                 liveThreshold={props.liveThreshold}
                                 objectiveList={globalContext.objectiveList}
                                 teamList={globalContext.teamList}
                                 userList={globalContext.userList}
                                 callAfterSave={props.callAfterSave}
                                 activeUserId={globalContext.userInformation.userId}
                                 activeToken={globalContext.activeTokenId}
                                 isUserAdministrator={globalContext.userIsAdministrator}
                                 isUserReadOnly={globalContext.userIsReadOnly}
                                 isUserUser={globalContext.userIsUser}
                             /> : null }
                        </div>
                        ))
                    }

                </div>
            </div>

        </>
    )
}

