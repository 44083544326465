import React from 'react';
import './KeyResultListHeader.css';

class KeyResultListHeader extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(prevState => ({}));
    }

    render() {
        return (
            <>
                <div id={this.props.id} key={this.props.id} className="keyresult-list-header-container">
                    <div className="title" title={this.props.title}>{this.props.title}</div>
                    <div className="subtitle">{this.props.strategicGoalTitle}</div>
                </div>
            </>
        )
    }

}

export {KeyResultListHeader}