import React, {useContext, useEffect, useState} from 'react';
import './UserSelector.css';
import {AutoComplete} from "primereact/autocomplete";
import {globalStateContext} from "../../App";
import {IsValueEmptyNullOrUndefined, UserImage} from "../../global-code/hooks/useGenericFunctions";

export const UserSelector = (props) => {
    const globalContext = useContext(globalStateContext);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    
    const searchUser = (event) => {
        setTimeout(() => {
            let _filteredUsers;
            if (!event.query.trim().length) {
                _filteredUsers = [...users];
            }
            else {
                _filteredUsers = users.filter((user) => {
                    return user.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredUsers(_filteredUsers);
        }, 250);
    }

    const itemTemplate = (item) => {
        return (
            <div className="user-selector-container center-contents-vertically">
                {item.hasPhoto &&    
                    <UserImage userId={item.hasPhoto ? item.id : ""} tokenId={globalContext.activeTokenId} initials={item.initials} className="user-image" title={item.name} />
                }
                {!item.hasPhoto &&
                    <div className="missing-photo center-contents-vertically-horizontally" style={{backgroundColor: IsValueEmptyNullOrUndefined(item.initials) ? "transparent" : "#cccccc"}}>{item.initials}</div>
                }
                {item.name}
            </div>
        );
    }
    
    const getFormattedUserList = (userList) => {
        let listData = [];

        userList.map(user => (
            listData.push({id: user.id, name: user.name, hasPhoto: user.hasPhoto, initials: user.initials})
        ));

        return listData;
    }
    
    useEffect(() => {
        setUsers(getFormattedUserList(globalContext.userList))
    }, [])
    
    useEffect( () => {
        setSelectedItem(users.filter( user => {return user.id === props.defaultValue})[0]);
    }, [props.defaultValue, users])

    return (
        <AutoComplete id={props.id} value={selectedItem} suggestions={filteredUsers} completeMethod={searchUser} panelStyle={{maxHeight: "500px"}} field="name" dropdown forceSelection itemTemplate={itemTemplate} onChange={(e) => setSelectedItem(e.value)} onSelect={props.getSelectedValueEventHandler(selectedItem)} aria-label="Users" className={props.className} style={props.style} placeholder={props.placeholder} />
    )
}