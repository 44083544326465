import React, {useContext, useState} from 'react';
import './ObjectiveOverviewColumnBased.css';
import {KeyResultList} from "./KeyResultList";
import {KeyResultListHeader} from "./KeyResultListHeader";
import {FilterButton} from "../navigation/FilterButton";
import {GetDisplaySlotsArray} from "../../global-code/hooks/useGenericFunctions";
import {globalStateContext} from "../../App";

export const ObjectiveOverviewColumnBased = props => {
    const globalContext = useContext(globalStateContext);
    const [showResponsible, setShowResponsible] = useState(true);
    const [showLive, setShowLive] = useState(false);
    const [showEditOptions, setShowEditOptions] = useState(false);
    const [showCompactView, setShowCompactView] = useState(false);
    const [refreshOverview, setRefreshOverview] = useState(false);
    
    function handleShowHideResponsibleClick() {
        setShowResponsible(!showResponsible);
    }

    function handleShowHideLiveClick() {
        setShowLive(!showLive);
    }

    function handleShowCompactViewClick() {
        setShowCompactView(!showCompactView);
    }
    
    function getItemsForQuarterSlot(objectives, quarter, year) {
        return objectives.map((item) => {
            return {
                ...item,
                keyResults: item.keyResults.filter((keyresults) =>
                    keyresults.quarter === quarter && keyresults.year === year
                ),
            }
        });
    }

    const refreshAfterSave = () => {
        setRefreshOverview(!refreshOverview);
        props.updateDependentData();
    }

    let content = ""

    if (props.objectivesData) {
        content = (
            <>
                <div className="objective-overview-container">
                    <div className={`filter-buttons-container ${props.showFilters ? '' : 'hidden-class'}`}>
                        <div className="filter-button-container">
                            <FilterButton title="Responsible" description="Show or hide responsible for the Key Result" value={showResponsible} onChange={handleShowHideResponsibleClick} />
                        </div>
                        <div className="filter-button-container">
                            <FilterButton title="Alive" description="Fade Key Results not updated last 30 days" value={showLive} onChange={handleShowHideLiveClick} />
                        </div>
                        <div className="filter-button-container">
                            <FilterButton title="Compact View" description="Show less information but more Key Results" value={showCompactView} onChange={handleShowCompactViewClick} />
                        </div>
                    </div>
                    
                    <div className="objectives-and-results-container">
                        <table>
                            <tbody>
                            <tr>
                                <td>    
                                </td>
                                {props.objectivesData.map(objective => (
                                    <td key={objective.id + "_key_result_header"}>
                                        <div className="keyresult-header-container">
                                            <KeyResultListHeader 
                                                id={objective.id} title={objective.title}
                                                numberOfAssociatedKeyResults={objective.numberOfAssociatedKeyResults}
                                                strategicGoalTitle={objective.strategicGoalTitle} 
                                            />
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            {GetDisplaySlotsArray(globalContext.chosenQuarterAndYear).map((slotData, index) => {
                                return <React.Fragment key={slotData.quarter + "_Quarter"}>
                                    <tr key={slotData.quarter + "_quarter_row"}>
                                        <td className="time-span-label-td">
                                            <div className="time-span-label-container">
                                                {slotData.quarter}.&nbsp;QUARTER&nbsp;{slotData.year}
                                            </div>
                                        </td>
                                        {getItemsForQuarterSlot(props.objectivesData, slotData.quarter, slotData.year).map(objective => (
                                            <td key={objective.id + "_keyresult_list"} className="keyresult-list-td">
                                                <KeyResultList key={objective.id} 
                                                    id={objective.id} 
                                                    objectiveId={objective.id} 
                                                    items={objective.keyResults} 
                                                    timeSpanTitle="1. QUARTER" 
                                                    quarter={slotData.quarter} 
                                                    showResponsible={showResponsible} 
                                                    showLive={showLive} 
                                                    showEditOptions={showEditOptions} 
                                                    showCompactView={showCompactView}
                                                    liveThreshold={25}  
                                                    callAfterSave={refreshAfterSave} 
                                                    year={slotData.year} 
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <div className="spacer-row-between-time-periods"/>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            })}
                            </tbody>
                        </table>
                    </div>    
                </div>
            </>
        );
    }
   return content;
}

