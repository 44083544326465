import React, {useContext, useState} from 'react'
import {PageHeader} from "../components/navigation/PageHeader";
import {UserList} from "../components/content-elements/UserList";
import {globalStateContext} from "../App";
import {ButtonTabNavigation} from "../components/navigation/ButtonTabNavigation/ButtonTabNavigation";
import './Organisation.css';
import {TeamList} from "../components/content-elements/Team/TeamList";

const Organisation = props => {
    const globalContext = useContext(globalStateContext);
    const [selectedTab, setSelectedTab] = useState(1);
    
    const getTabs = () => {
        return [
            { title: 'Users', icon: 'person', value: 1, enabled: true},
            { title: 'Teams', icon: 'diversity_2', value: 2, enabled: true},
            { title: 'Organisation', icon: 'apartment', value: 3, enabled: false}
        ]
    }
    
    const changeTab = (tabNumber) => {
        setSelectedTab(tabNumber);
    }
    
    return (
        <>
            <PageHeader key="organisationSubPage" icon={props.iconName} pageName="organisation" organisationName={props.organisationTitle}/>
            
            <div className="organisation-sub-page-container">
                <div className="help-text">
                    <p>This is where you edit your organisations data.</p>
                    <p>
                        You can add, edit and delete users and teams.
                        Add as many users from your organisation as possible, as it is important that users can get involved, comment and see a clear path from everyday tasks to the Strategy and Vision of your company.
                    </p>
                </div>
                <ButtonTabNavigation tabs={getTabs()} tabChangeEventHandler={changeTab} value={selectedTab} />
                <div style={{marginBottom: "50px"}} />
                <UserList key="userList" visible={selectedTab === 1} organisationId={props.organisationId} activeToken={props.activeToken} isUserAdministrator={props.isUserAdministrator} teamList={globalContext.teamList} />
                <TeamList key="teamList" visible={selectedTab === 2} organisationId={props.organisationId} activeToken={props.activeToken} isUserAdministrator={props.isUserAdministrator} teamList={globalContext.teamList} />
            </div>
        </>
    );
};

export {Organisation};