import React from 'react';
import {InputSwitch} from "primereact/inputswitch";
import './FilterButton.css';

export const FilterButton = props => {
    return (
        <>
            <div className="filter-button-component-container">
                <span className="filter-button-switch-container">
                    <InputSwitch checked={props.value} onChange={props.onChange} />
                </span>
                <span className="filter-button-text-container">
                    <div className="filter-button-text-title">
                        {props.title}
                    </div>
                    <div className="filter-button-text-description">
                        {props.description}
                    </div>
                </span>
            </div>
        </>
    )
}