import React, {useContext} from 'react';
import './StrategyOverview.css';
import {LoadingIndicator} from "./LoadingIndicator";
import {Tooltip} from 'primereact/tooltip';
import {globalStateContext} from "../../App";
import {useHttp} from "../../global-code/hooks/useHttp";

export const StrategyOverview = props => {
    const globalContext = useContext(globalStateContext);
    
    let keyresultCardHeight = 40;
    let keyresultCardMargin = 10;
    let keyresultContainerPadding = 20;
    let objectiveCardHeight = 80;
    let objectiveCardMargin = 10;
    
    const [isLoading, items] = useHttp(
        process.env.REACT_APP_API_URL + 'strategy/strategy/' + globalContext.activeOrganisationId + "?tokenId=" + globalContext.activeTokenId + "&startQuarterAndYear=" + props.startQuarterAndYear,
        [props.startQuarterAndYear, props.keyResultsUpdatedFlag]        
    );

    /* If there are no keyResults associated with objectives, then it is the height of the objective cards that determine the height of the strategic goal card */
    /* If there is only one keyResult then the height should be at least the height of the objective (the keyResult card is lower) */
    const calculateHeightOfStrategyCard = (objectives) => {
        let result = 0;

        objectives.map(objective => (
            result += objective.keyResults.length === 0 ? objectiveCardHeight + objectiveCardMargin : objective.keyResults.length * (keyresultCardHeight + keyresultCardMargin) < (objectiveCardHeight) ? objectiveCardHeight + objectiveCardMargin : keyresultContainerPadding + (objective.keyResults.length * (keyresultCardHeight + keyresultCardMargin))
            )     
        )
        result = result - objectiveCardMargin;
        return result;
    }

    const calculateHeightOfObjectiveCard = (objective) => {
        let result = 0;

        result = objective.keyResults.length === 0 ? objectiveCardHeight + objectiveCardMargin : objective.keyResults.length * (keyresultCardHeight + keyresultCardMargin) < (objectiveCardHeight) ? objectiveCardHeight + objectiveCardMargin : keyresultContainerPadding + (objective.keyResults.length * (keyresultCardHeight + keyresultCardMargin))
        result = result - objectiveCardMargin;
        return result;
    }

    if (!items) {
        return <LoadingIndicator message="Strategy Overview"/>;
    } else {
        return (
            <>
                <Tooltip target=".strategic-goal-card" style={{maxWidth: "600px"}} />
                <Tooltip target=".objective-card" style={{maxWidth: "600px"}} />
                <Tooltip target=".keyresult-card"/>
                <div className="strategy-overview-container">
                    <div className="column-header-container">
                        <div className="column-header-strategic-goal">Strategic goals</div>
                        <div className="column-header-objectives">Objectives</div>
                        <div className="column-header-key-results">Key results</div>
                    </div>

                    {
                        items.map(item => (
                            <div key={item.id} className="strategy-element-container">

                                {/* Strategy "Column" */}
                                <div key={item.id + "_strategy_column"} data-pr-tooltip={item.description} className="strategic-goal-card card-basic-ui" style={{height: calculateHeightOfStrategyCard(item.objectives) + 'px', lineHeight: calculateHeightOfStrategyCard(item.objectives) + 'px'}}><p>{item.title}</p>
                                </div>

                                {/* Objectives "column" */}
                                <div key={item.id + "_objectives_column"} className="objectives-container">
                                    {
                                        item.objectives.map(objective => (
                                            <div key={objective.id + "_objective_header"} data-pr-tooltip={objective.description} className="objective-card card-basic-ui" style={{height: calculateHeightOfObjectiveCard(objective), lineHeight: calculateHeightOfObjectiveCard(objective) + 'px'}}>
                                                <p>{objective.title}</p>
                                            </div>
                                        ))
                                    }
                                </div>

                                {/* Results "column" */}
                                {
                                    item.objectives.map(objective => {
                                        return (
                                                <div key={objective.id + "_objective_results_container"} className="keyresult-container card-basic-ui" style={{height: calculateHeightOfObjectiveCard(objective) + 'px'}}>
                                                    {
                                                        objective.keyResults.map(keyresult => (
                                                            <div key={keyresult.id} className="keyresult-card" data-pr-tooltip={"Q" + keyresult.quarter + "-" + keyresult.year + " " + keyresult.title} style={{borderLeft: '4px solid #' + keyresult.levelColorHex}}>
                                                                <div key={keyresult.id + "_Q_" + keyresult.quarter} title={keyresult.year} className="quarter">Q{keyresult.quarter}</div>{keyresult.title}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }
}

