import React, {useContext, useState} from 'react';
import './OrganisationalOverviewColumnBased.css';
import {KeyResultList} from "./KeyResultList";
import {LoadingIndicator} from "./LoadingIndicator";
import {OrganisationalListHeader} from "./OrganisationalListHeader";
import {FilterButton} from "../navigation/FilterButton";
import {globalStateContext} from "../../App";
import {GetDisplaySlotsArray, GetQuartersDisplayArray, GetYearFromSelectedTimeSpan} from "../../global-code/hooks/useGenericFunctions";

export const OrganisationalOverviewColumnBased = props => {
    const globalContext = useContext(globalStateContext);
    const [showResponsible, setShowResponsible] = useState(true);
    const [showLive, setShowLive] = useState(false);

    const handleShowHideResponsibleClick = () => {
        setShowResponsible(!showResponsible);
    }

    const handleShowHideLiveClick = () => {
        setShowLive(!showLive);
    }

    const getItemsForQuarterSlot = (items, quarter, year) => {
        return items.map((item) => {
            return {
                ...item,
                involvedInKeyResults: item.involvedInKeyResults.filter((keyresults) =>
                    keyresults.quarter === quarter && keyresults.year === year
                ),
            }
        });
    }

    const refreshAfterSave = () => {
        props.updateDependentData();
    }
    
    if (!props.data) {
        return <LoadingIndicator message="Organisational Overview Data" />;
    } else {
        return (
            <>
                <div className="team-overview-column-based-container">
                    <div className={`filter-buttons-container ${props.showFilters ? '' : 'hidden-class'}`}>
                        <div className={`filter-button-container ${props.showFilters ? '' : 'hidden-class'}`}>
                            <FilterButton title="Responsible" description="Show less information but more Key Results" value={showResponsible} onChange={handleShowHideResponsibleClick} />
                        </div>
                        <div className="filter-button-container">
                            <FilterButton title="Alive" description="Fade Key Results not updated last 30 days" value={showLive} onChange={handleShowHideLiveClick} />
                        </div>
                    </div>
                    
                    <div className="teams-and-results-container">
                        <table>
                            <tbody>
                                <tr>
                                    <td>

                                    </td>
                                    {props.data.map(team => (
                                        <td key={team.id + "_header"}>
                                            <div className="team-header-container">
                                                <OrganisationalListHeader id={team.id} teamId={team.id} title={team.title} shortTitle={team.shortTitle} strategicGoalTitle={team.strategicGoalTitle} identificationColorHexValue={team.identificationColorHexValue} hasImage={team.hasImage} tokenId={globalContext.activeTokenId} />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                
                                {GetDisplaySlotsArray(globalContext.chosenQuarterAndYear).map((slotData, index) => (
                                    <React.Fragment key={slotData.quarter + "_X"}>
                                        <tr key={slotData.quarter + "_Q_Row1"}>
                                            <td className="time-span-label-td">
                                                <div className="time-span-label-container">
                                                    {slotData.quarter}.&nbsp;QUARTER&nbsp;{slotData.year}
                                                </div>
                                            </td>
                                            {getItemsForQuarterSlot(props.data, slotData.quarter, slotData.year).map(team => (
                                                <td key={team.id} className="keyresult-list-td">
                                                    <KeyResultList 
                                                        id={team.id} 
                                                        objectiveId={team.id} 
                                                        items={team.involvedInKeyResults} 
                                                        timeSpanTitle="1. QUARTER" 
                                                        quarter={slotData.quarter} 
                                                        showResponsible={showResponsible} 
                                                        showLive={showLive} 
                                                        showEditOptions={false} 
                                                        liveThreshold={25} 
                                                        callAfterSave={refreshAfterSave}  
                                                    />
                                                </td>
                                            ))}
                                        </tr>

                                        <tr key={slotData.quarter + "_Q_Row2"}>
                                            <td>
                                            </td>
                                            <td>
                                                <div className="spacer-row-between-time-periods" />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </>
        )
    }
}

