import React from 'react';
import './StrategicGoalOverview.css';

export const StrategicGoalOverview = props => {
    const heightOfKeyResult = 80;
    const objectiveWidth = 500;
    const spaceBetweenKeyResults = 15;
    const widthOfStrategicGoal = 500;
    const horisontalSpaceFromObjectiveToKeyResult = 150;
    const arrowSizeFactor = 0.2;
    const keyResultWidth = 500;
    const data = props.data;
    
    function ObjectiveTop(objectives, currentIndex) {
        let top = 0;
        objectives.map((objective, index) => {
                if (index < currentIndex) {
                    top += objective.keyResults.length * heightOfKeyResult;
                }
            }
        );
        return top;
    }
    
    function WidthOfFigureContainer() {
        return (objectiveWidth + widthOfStrategicGoal + horisontalSpaceFromObjectiveToKeyResult + keyResultWidth);
    }

    function WidthOfObjectiveArrow(objective) {
        let objectiveHeight = ObjectiveHeight(objective);
        return (arrowSizeFactor * objectiveHeight);
    }
    
    function WidthOfObjectiveTextContainer(objective) {
        return 0.75 * objectiveWidth;
    }
    
    function KeyResultLeft(objectives) {
        let width = 0;
        objectives.map( objective => {
            let widthOfObjective = WidthOfObjective(objective);
            if (width < widthOfObjective) {
                
                width = widthOfObjective;
            }
        })
        return (widthOfStrategicGoal * 0.75) + width + horisontalSpaceFromObjectiveToKeyResult;
    }    

    function KeyResultTop(objectives, objectiveCurrentIndex, keyResultCurrentIndex) {
        let top = 0;
        let leadVerticalSpaceFromPreviousObjectives = 0;

        objectives.map((objective, objectiveIndex) => {
            if (objectiveIndex <= objectiveCurrentIndex) {
                if (objectiveIndex < objectiveCurrentIndex) {
                    leadVerticalSpaceFromPreviousObjectives += objective.keyResults.length * heightOfKeyResult;
                }
                else {
                    objective.keyResults.map((keyResult, keyResultIndex) => {
                            if (keyResultIndex < keyResultCurrentIndex) {
                                top += heightOfKeyResult;
                            }
                        }
                    );
                }
            }
        })

        top += leadVerticalSpaceFromPreviousObjectives;

        return top;
    }

    function ObjectiveHeight(objective) {
        let numberOfKeyResults = objective.keyResults.length;
        return heightOfKeyResult * numberOfKeyResults;
    }

    function CalcHeightOfStrategyContainer(objectives, heightOfKeyResult) {
        let height = 0;
        objectives.map( objective => (
            height += objective.keyResults.length * heightOfKeyResult
        ))
        return height;
    }
    
    function WidthOfObjective(objective) {
        return (objectiveWidth * 0.75) + WidthOfObjectiveArrow(objective);
    }

    function ObjectiveClipPath(objective) {
        let objectiveHeight = ObjectiveHeight(objective);
        let arrowStartPoint = 0.75 * objectiveWidth;
        let arrowEndPoint = (arrowSizeFactor * objectiveHeight) + arrowStartPoint;

        let result = "polygon(0 0, " + arrowStartPoint + "px 0, " + arrowEndPoint + "px " + (objectiveHeight/2) + "px, " + arrowStartPoint + "px " + objectiveHeight + "px, 0 "  + objectiveHeight + "px";
        return result;
    }
    
    function CalcProgressBarWidth(percentDone) {
        return keyResultWidth * (percentDone / 100);
    }
    
    function ProgressionText(keyResult) {
        let result = "";
        if (keyResult.percentDoneEarlierStage !== null) {
            let change = keyResult.percentDone - keyResult.percentDoneEarlierStage;
            if (change > 0) result = "+" + change;
            if (change < 0) result = "" + change;
        }
        return result;
    }

    return (
        <>
            <div key={data.id} id={"figure_" + data.id} className="figure-container" style={{height: CalcHeightOfStrategyContainer(data.objectives, heightOfKeyResult) + "px", width: WidthOfFigureContainer() + "px"}}>
                <div className="strategic-goal-polygon-container">
                    <div className="strategic-goal-polygon" style={{width: widthOfStrategicGoal + "px", height: CalcHeightOfStrategyContainer(data.objectives, heightOfKeyResult)  + "px"}}>
                        <div className="strategic-text-container">
                            <div className="strategic-text">{data.title}</div>
                        </div>
                    </div>
                </div>

                {data.objectives.map((objective, objectiveIndex) => (

                    <div key={objective.id}>
                        <div className="objective-polygon-container" style={{width: "400px"}}>
                            <div className="objective-polygon" style={{width: WidthOfObjective(objective) + "px", top: ObjectiveTop(data.objectives, objectiveIndex) + "px", height: ObjectiveHeight(objective) + "px", clipPath: ObjectiveClipPath(objective)}}>
                                <div className="objective-text-container" style={{width: WidthOfObjectiveTextContainer(objective) + "px"}}>
                                    <div className="objective-text">{objective.title}</div>
                                </div>
                            </div>
                        </div>

                        {objective.keyResults.map ( (keyResult, keyResultIndex) => (
                            <div key={keyResult.id} className="key-result-container" style={{width: keyResultWidth + "px", left: KeyResultLeft(data.objectives) + "px", top: KeyResultTop(data.objectives, objectiveIndex, keyResultIndex) + "px", height: heightOfKeyResult + "px"}}>
                                <div className="key-result-text-container" style={{height: heightOfKeyResult-spaceBetweenKeyResults + "px"}}>{keyResult.title}</div>
                                <div className="key-result-percent-container" style={{backgroundColor: "#" + keyResult.levelColorHex, left: CalcProgressBarWidth(keyResult.percentDone) + 'px'}}>{keyResult.percentDone}%</div>
                                { (keyResult.percentDoneEarlierStage !== null && keyResult.percentDone !== keyResult.percentDoneEarlierStage) && <div className="key-result-percent-container-earlier-stage" style={{left: CalcProgressBarWidth(keyResult.percentDoneEarlierStage) + 'px'}}>{keyResult.percentDoneEarlierStage}%</div> }
                                <div className="key-result-progress-bar" style={{backgroundColor: "#" + keyResult.levelColorHex, width: CalcProgressBarWidth(keyResult.percentDone) + 'px'}} />
                                <div className="key-result-progression-percent-point" style={{display: ProgressionText(keyResult) === '' ? 'none' : ''}}><div className="key-result-progression-percent-point-text">{ProgressionText(keyResult)}</div></div>
                            </div>
                        ))}

                    </div>
                ))}
            </div>
        </>
    )
}
