import React, {useContext, useEffect, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from"primereact/dropdown";
import './EditForms.css'
import {Tooltip} from "primereact/tooltip";
import {SetFocusOnElement, years} from "../../global-code/hooks/useGenericFunctions";
import {globalStateContext} from "../../App";
import {Button} from "primereact/button";

export const EditObjective = props => {
    const globalContext = useContext(globalStateContext);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const GetEditDataObject = () => {
        return {
            id: props.data.id,
            title: props.data.title,
            description: props.data.description,
            strategicGoalId: props.data.strategicGoalId,
            validFromYear: props.data.validFromYear,
            validUntilYear: props.data.validUntilYear
        };
    }

    const [values, setValues] = useState(GetEditDataObject());

    useEffect( () => {
        setValues(GetEditDataObject());
        SetFocusOnElement("objectiveTitle");
    }, [props.data]);
    
    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validateFormInput(values));
        setIsSubmitting(true);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    const postData = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "objectives/CreateUpdateObjective", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    id: props.data.id,
                    title: values.title,
                    description: values.description === null ? "" : values.description,
                    strategicGoalId: values.strategicGoalId,
                    organisationId: globalContext.activeOrganisationId,
                    tokenId: props.activeToken,
                    validFromYear: values.validFromYear,
                    validUntilYear: values.validUntilYear
                }),
            });

            await res.json();

            if (res.status === 201) {
                props.callAfterSave();
                props.callToHideDialog();
            } else {
                alert("An error occurred. Your changes were not saved");
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            postData();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, isSubmitting]);

    function validateFormInput(values) {
        let errors = {};

        if (!values.title.trim()) {
            errors.title = "Please enter a title for your objective";
        }

        if (values.strategicGoalId === '0') {
            errors.strategicGoalId = "Please choose the strategic goal your objective is supporting";
        }

        if (values.validFromYear > values.validUntilYear) {
            errors.validFromYear = "The 'from' year needs to be the same or less than 'until' year";
        }

        return errors;
    }
    
    function getSelectItemsStrategyList(jsonList) {
        var listdata = [];
        
        listdata.push({label: 'Not chosen', value: '0'})
        jsonList.map(item => (
            listdata.push({label: item.title, value: item.id})    
        ));
        
        return listdata;
    }

    const DeleteObjectiveOnServer = async (id) => {
        try {
            console.log("DeleteObjectiveOnServer: " + id);
            let res = await fetch(process.env.REACT_APP_API_URL + 'objectives/DeleteObjective/' + id + '?tokenId=' + props.activeToken);
            await res.text();

            if (res.status === 304) {
                alert ("The Objective cannot be deleted as it has associated Key Results. Delete all associated Key Results for all years and try again.");
            }
            
            if (res.status === 200) {
                props.callAfterSave();
            }
        } catch (err) {
            console.log(err);
        }
    }

    function ConfirmDeleteOfObjective(id) {
        if (window.confirm("Are you sure you wish to delete this objective? You can only delete an objective if all associated Key Results are deleted first.")) {
            DeleteObjectiveOnServer(id);
        }
    }
    
    return (
            <>
                <div className="edit-container">
                    <form id="editObjective" onSubmit={handleSubmit} >
                        <div className="form-explanation left-separator-objective">
                            <p>Objectives are <b>WHAT</b> you want to accomplish (Key Results are <b>HOW</b>).</p> 
                            <p>State what your goal (objective) is for the next time period related to the top strategic goal of your organisation.</p>
                            <p>Make your objective clear, concrete and understandable so that everyone in your organisation knows what you're trying to achieve.</p>
                        </div>
                        
                        <div className="field-wrapper">
                            <Tooltip target=".icoTitle" style={{width: "400px"}} position="left">Concrete, understandable and short (example: Introduce new product in Norway | Win the champions league)</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text" >Objective Headline</span><span className="material-icons-outlined icoTitle">info</span></div>
                            <span className="p-float-label">
                                <InputText id="objectiveTitle" name="title" className="fill-container-width" type="text" value={values.title} onChange={handleChange} />
                                {errors.title && <p className="error-message">{errors.title}</p>}
                            </span>
                        </div>
        
                        <div className="field-wrapper">
                            <Tooltip target=".icoDescription" style={{width: "400px"}} position="left">Explain objective in more detail and also WHY it's important to achieve this objective</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text" >Description</span><span className="material-icons-outlined icoDescription">info</span></div>
                            <span className="p-float-label">
                                <InputTextarea name="description" className="fill-container-width" rows={5} cols={30} value={values.description} onChange={handleChange} />
                                {errors.description && <p className="error-message">{errors.description}</p>}
                            </span>
                        </div>
        
                        <div className="field-wrapper">
                            <Tooltip target=".icoStrategicGoal" style={{width: "400px"}} position="left">All objectives should be a part of a company wide strategic goal</Tooltip>
                            <div className="input-field-title-container"><span className="input-field-title-text" >Associated with Strategic Goal</span><span className="material-icons-outlined icoStrategicGoal">info</span></div>
                            <span className="p-float-label">
                                <Dropdown name="strategicGoalId" className="fill-container-width" options={getSelectItemsStrategyList(props.strategyList)} value={values.strategicGoalId} onChange={handleChange} />
                                {errors.strategicGoalId && <p className="error-message">{errors.strategicGoalId}</p>}
                            </span>
                        </div>

                        <div className="number-container">
                            <div className="number-input-field-container">
                                <Tooltip target=".helpValidToAndFrom" style={{width: "400px"}} position="left">The Valid From Year and Valid Until Year fields determines which Objectives will be shown/avaiable for the selected year</Tooltip>
                                <label htmlFor="validFromYear" className="input-number-field-title helpValidToAndFrom">Valid from this year</label>
                                <Dropdown name="validFromYear" options={years} value={values.validFromYear.toString()} onChange={handleChange} style={{width: "140px"}} />
                            </div>

                            <div className="number-input-field-container">
                                <Tooltip target=".helpValidToAndFrom" style={{width: "400px"}} position="left">The Valid From Year and Valid Until Year fields determines which Objectives will be shown/avaiable for the selected year</Tooltip>
                                <label htmlFor="validUntilYear" className="input-number-field-title helpValidToAndFrom">Until this year</label>
                                <Dropdown name="validUntilYear" options={years} value={values.validUntilYear.toString()} onChange={handleChange} style={{width: "140px"}} />
                            </div>
                            {errors.validFromYear && <p className="error-message" style={{marginTop: "10px"}}>{errors.validFromYear}</p>}
                        </div>

                        <div className="button-container" style={{padding: "0 40px 0 40px"}}>
                            <Button label="Close" icon="pi pi-times" type="button" className="dialog-button-text p-button-close" onClick={props.callToHideDialog} style={{float: "left", marginLeft: "10px"}} />
                            <Button label="Save" icon="pi pi-check" className="dialog-button-text p-button-save" />
                            <Button label="Delete ..." icon="pi pi-minus" type="button" className="dialog-button-text p-button-delete" onClick={(event) => ConfirmDeleteOfObjective(values.id)} />
                        </div>
                    </form>
                </div>
            </>
    );
}