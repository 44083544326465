import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import "./LoadingIndicator.css";

export const LoadingIndicator = props => {

    return  (
        <>
            <div className="progress-symbol-container">
                <div className="progress-symbol">
                    <ProgressSpinner />
                    <div>{props.message}</div>
                </div>
            </div>
        </>
    )
}
