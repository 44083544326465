import React from 'react';
import './OrganisationalListHeader.css';
import {TeamImage} from "./TeamImage";

export const OrganisationalListHeader = (props) => {
        return (
            <div key={props.id}>
                <div id={props.id} className="team-list-header-container">
                    <div className="team-image center-contents-horizontally">
                        <TeamImage id={props.id} title={props.title} shortTitle={props.shortTitle} hasImage={props.hasImage} imageSize={80} tokenId={props.tokenId} borderThickness={3} borderColor={props.identificationColorHexValue} />
                        {/*<img src={`/resources/user-images/${props.teamId}.jpg`} alt={'Team image ' + props.title} style={{border: "3px solid #" + props.identificationColorHexValue}} />*/}
                    </div>
                    <div className="title">{props.title}</div>
                </div>
            </div>
        )
}
