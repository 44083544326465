import {useState, useEffect} from 'react';

export const useHttp = (url, dependencies) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        console.log('Sending Http request to URL: ' + url);
        // console.log(useHttp.prototype.displayName);
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch.');
                }
                return response.json();
            })
            .then(data => {
                setIsLoading(false);
                setFetchedData(data);
            })
            .catch(err => {
                console.log("Error on url: " + url);
                console.log(err);
                setIsLoading(false);
            }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return [isLoading, fetchedData];
};

export const useHttpOnCondition = (condition, url, dependencies) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState(null);

    useEffect(() => {
        if (condition) {
            setIsLoading(true);
            console.log('Sending Http request to URL: ' + url);
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch.');
                    }
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false);
                    setFetchedData(data);
                })
                .catch(err => {
                    console.log("Error on url: " + url);
                    console.log(err);
                    setIsLoading(false);
                }); // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, dependencies);
    return [isLoading, fetchedData];
};