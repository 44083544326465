import React, {useContext, useEffect, useRef, useState} from 'react';
import './KeyResultFullInformation2.css';
import {useHttp} from "../../../global-code/hooks/useHttp";
import {globalStateContext} from "../../../App";
import {IsValueEmptyNullOrUndefined, UserImageHtml} from "../../../global-code/hooks/useGenericFunctions";
import {AddActivityElement} from "./AddActivityElement";
import {ActivityEditLine} from "./ActivityEditLine";
import {KeyResultUpdates} from "./KeyResultUpdates";
import {KeyResultNewUpdate} from "./KeyResultNewUpdate";
import {EditKeyResult} from "../../EditComponents/EditKeyResult";
import {CloseButton} from "../../../global-code/hooks/useButtons";
import {UserMultiSelectList} from "../UserMultiSelectList";

export const KeyResultFullInformation2 = props => {
    const globalContext = useContext(globalStateContext);
    const [updateKeyResult, setUpdateKeyResult] = useState(false);
    const [chosenTabNumber, setChosenTabNumber] = useState('1');

    const updateKeyResultData = () => {
        setUpdateKeyResult(!updateKeyResult);
        props.callAfterSave();
    }

    const saveKeyResultMembers = async (memberIds) => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "keyResults/SaveKeyResultTeam/" + props.id + "?memberIds=" + memberIds + "&tokenId=" + globalContext.activeTokenId  , {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: ""
            });
            await res.text();
            if (res.status === 201) {
                updateKeyResultData();
            } else {
                alert("An error occurred. Your changes were not saved");
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    const [isLoading, keyResultData] = useHttp(
        process.env.REACT_APP_API_URL + 'KeyResults/' + props.id + "?tokenId=" + globalContext.activeTokenId + "&organisationId=" + globalContext.activeOrganisationId,
        [updateKeyResult]
    );

    const ChangeActivityCheckedStatus = (e) => {
        console.log(e.target.id);
    }
    
    const HandleActivityDelete = () => {
        setUpdateKeyResult(!updateKeyResult);
    }
    
    const HandleActivityUpdate = () => {
        setUpdateKeyResult(!updateKeyResult);
    }
    
    let memberIndex = 480;
    
    const placeMember = () => {
        let result = memberIndex;
        memberIndex += 40;
        return result;
    }

    const ChangeToTab = (tabNumber) => {
        setChosenTabNumber(tabNumber);
    }
    
    const SaveTeamMembers = (selectedMembers) => {
        let memberIds = "";
        for (let i = 0; i <= selectedMembers.length-1; i++) {
            memberIds += selectedMembers[i].id + ",";
        }
        memberIds.length > 0 ? memberIds = memberIds.substring(0, memberIds.length-1) : memberIds = 'null';
        
        saveKeyResultMembers(memberIds);
        op.current.hide();
    }
    
    const ScrollToTop = () => {
        let element = document.getElementsByClassName('content-body')[0];
        element.scrollTop = 0;
    }
    
    useEffect(() => {
        let element = document.getElementsByClassName('content-body')[0];
        if (!IsValueEmptyNullOrUndefined(element)) {
            element.scrollTop = 0;
        }
    })
    
    const op = useRef(null);

    if (keyResultData) {
        return (
            <>
                <div className="key-result-full-information-container2">
                    <div className="responsible-team-tag-container center-contents-vertically-horizontally">
                        <div className="responsible-team-tag center-contents-vertically-horizontally" style={{backgroundColor: "#" + keyResultData.anchorTeamIdentificationHexColor}}>{keyResultData.anchorTeamTitle}</div>
                    </div>
                    
                    {globalContext.userIsAdministrator &&
                        <>
                            <div
                                className={"tab-switch tab-switch-activities-and-updates " + (chosenTabNumber === '1' ? " tab-switch-selected" : "")} onClick={() => ChangeToTab('1')}>
                                Main
                            </div>
                            <div className={"tab-switch tab-switch-definition" + (chosenTabNumber === '2' ? " tab-switch-selected" : "")} onClick={() => ChangeToTab('2')}>
                                Definition
                            </div>
                        </>
                    }
                    
                    <div id="main_contents" className="card-container center-contents-horizontally" style={{display: "block"}}>
                        <div className="top-header-container" onClick={ScrollToTop}>
                            <div className="close-button"><CloseButton id="closeButtonKeyResultFullInformationDialog" sizePixel={35} onClick={props.callToHideDialog} /></div>
                            <div className="team-members-container">
                                {keyResultData.teamMembers.map((member) => (
                                    <span key={member.id}>
                                        <UserImageHtml
                                            borderColor={member.primaryTeamHexColor}
                                            onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel"
                                            userId={member.hasPhoto ? member.id : ""} 
                                            style={{top: "0", left: placeMember() + "px", border: "2px solid #" + member.primaryTeamHexColor}} 
                                            tokenId={globalContext.activeTokenId} 
                                            initials={member.initials} 
                                            className="update-user-image" 
                                            title="Working on Key Result"
                                        />
                                    </span>
                                    
                                ))}
                                {globalContext.userIsAdministrator &&
                                    <span>
                                        <UserImageHtml userId="" borderColor="white" onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" style={{top: "0", left: placeMember() + "px", fontSize: "25px", fontFamily: "Roboto", cursor: "pointer"}} tokenId={globalContext.activeTokenId} initials="+" className="update-user-image" title="Add colleague to Key Result" />
                                    </span>
                                }
                            </div>
                            <UserMultiSelectList users={globalContext.userList} selectedUsers={keyResultData.teamMembers} op={op} tokenId={globalContext.activeTokenId} style={{position: "absolute", top: "0", left: placeMember() + "px", width: "350px"}} handleUserSelectionChange={SaveTeamMembers}  />
                            {keyResultData.anchorTeamHasImage && <img src={process.env.REACT_APP_API_URL + "teams/teamImage?tokenId=" + globalContext.activeTokenId + "&teamId=" + keyResultData.anchorTeamId} alt={"Team Image " + keyResultData.anchorTeamTitle} className="background-image" /> }
                            {!keyResultData.anchorTeamHasImage && <img src="/resources/basic-graphics/default-team-bg.jpg" alt={"Team Image " + keyResultData.anchorTeamTitle} className="background-image" /> }
                            {/*<img className="background-image" src={"/resources/user-images/" + keyResultData.anchorTeamId + ".jpg"} />*/}
                            <UserImageHtml userId={keyResultData.responsiblePersonHasPhoto ? keyResultData.responsiblePersonId : ""} tokenId={globalContext.activeTokenId} initials={keyResultData.responsiblePersonInitials} className="user-image" />
                            <div className="key-result-header">{keyResultData.title}</div>
                            <div className="objective-header">{keyResultData.objectiveTitle}</div>
                        </div>
                        <div className="content-body" style={{display: (chosenTabNumber === '1' ? "block" : "none")}}>
                            <div className="section-container" style={{backgroundColor: "rgba(253,181,40,1)", border: "0px"}}>
                                <div className="section-header">Purpose of this Key Result</div>
                                <div className="what-to-do-text">
                                    {IsValueEmptyNullOrUndefined(keyResultData.whatToDo) ? "Purpose is not defined" : keyResultData.whatToDo}
                                </div>
                            </div>

                            {(keyResultData.allowUserToAddActivities || keyResultData.activities.length > 0) &&
                                <div className="section-container">
                                    <div className="section-header">Actions we need to take (to-do's)</div>
                                    <div className="activity-container">
                                        {keyResultData.activities.map( activity => (
                                            <ActivityEditLine id={activity.id} key={activity.id} activity={activity} onChange={(e) => ChangeActivityCheckedStatus(e)} tokenId={globalContext.activeTokenId} organisationId={globalContext.activeOrganisationId} callAfterDelete={HandleActivityDelete} callAfterUpdate={HandleActivityUpdate} />
                                        ))}
                                        {keyResultData.allowUserToAddActivities &&
                                            <AddActivityElement style={{width: "100%", height: "35px", marginTop: "20px"}} onSubmit={updateKeyResultData} activeTokenId={globalContext.activeTokenId} activeOrganisationId={globalContext.activeOrganisationId} keyResultId={keyResultData.id} />
                                        }
                                    </div>
                                </div>
                            }
                            
                            <div className="section-container">
                                <div className="section-header">Updates - how are we progressing</div>
                                <div className="update-container">
                                    <KeyResultUpdates updates={keyResultData.statusUpdates} tokenId={globalContext.activeTokenId} callAfterDelete={updateKeyResultData} />
                                </div>
                                <KeyResultNewUpdate defaultText="" keyResult={keyResultData} tokenId={globalContext.activeTokenId} activeUserId={globalContext.activeUserId} callAfterSave={updateKeyResultData} />
                            </div>
                        </div>
                        <div className="content-body" style={{visibility: (chosenTabNumber === '2' ? "visible" : "hidden"), padding: "0 40px 0 40px"}}>
                            <EditKeyResult
                                id={keyResultData.id}
                                data={keyResultData}
                                objectiveList={globalContext.objectiveList}
                                teamList={globalContext.teamList}
                                userList={globalContext.userList}
                                callAfterSave={updateKeyResultData}
                                callToHideDialog={props.callToHideDialog}
                                isUserAdministrator={globalContext.userIsAdministrator}
                                isUserReadOnly={globalContext.userIsReadOnly}
                                isUserUser={globalContext.userIsUser}
                                activeToken={globalContext.activeTokenId}
                                showCloseButton={true}
                            />
                        </div>
                    </div>
                    
                </div>
            </>
        )
    }
    
    return null;
}