export const SendWelcomeMail = (tokenId, organisationId, welcomeUserId, callWhenDone) => {
    const postData = async () => {
        fetch(process.env.REACT_APP_API_URL + "communication/sendwelcomemail?organisationid=" + organisationId + "&tokenid=" + tokenId + "&welcomeuserid=" + welcomeUserId, {
            method: "POST",
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: ""
        })
            .then(callWhenDone)
            .catch(err => {
                console.log("Error when trying to send Welcome Mail");
                console.log(err);
            });
    }
    
    postData();
}

export const SendForgottenPasswordMail = (email, callWhenDone) => {
    const postData = async () => {
        fetch(process.env.REACT_APP_API_URL + "communication/sendforgottenpasswordmail?useremail=" + email, {
            method: "POST",
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: ""
        })
            .then(callWhenDone)
            .catch(err => {
                console.log("Error when trying to send Welcome Mail");
                console.log(err);
            });
    }

    postData();
}

export const SendChangePasswordEMail = (token, callWhenDone) => {
    const postData = async () => {
        fetch(process.env.REACT_APP_API_URL + "communication/SendChangePasswordEMail?tokenid=" + token, {
            method: "POST",
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: ""
        })
            .then(callWhenDone)
            .catch(err => {
                console.log("Error when trying to send Change Password Mail");
                console.log(err);
            });
    }

    postData();
}