import React, {useEffect, useState} from 'react';
import './TabNavigationButton.css';
import {GetPersistedValue, PersistValue} from "../../global-code/hooks/useGenericFunctions";
import {PERSIST_TAB_NAVIGATION_SELECTION_PREFIX} from "../../global-code/hooks/useGlobalSettings";

export const TabNavigationButton = props => {
    let persistSelectedValueKey = PERSIST_TAB_NAVIGATION_SELECTION_PREFIX + props.title
    const [buttonValue, setButtonValue] = useState(Number(GetPersistedValue(persistSelectedValueKey, props.values[0])));

    function handleOnChange(selectedValue) {
        PersistValue(persistSelectedValueKey, selectedValue)
        props.callOnClick(selectedValue);
    }

    function switchView() {
        let index = props.values.indexOf(Number(props.selectedValue));

        if (index === -1) {
        } else {
            index = index === 0 ? 1 : 0;
            setButtonValue(props.values[index]);
            handleOnChange(props.values[index]);
        }
    }
    
    return (
        <>
            <div className="tab-navigation-button-container">
                <input key={"radio-button-" + buttonValue} value={buttonValue} id={"radio-button-" + buttonValue} type="radio" className="radio-button" name="tabs" checked={props.selectedValue == buttonValue} onChange={() => handleOnChange(buttonValue)}/>
                <label className="tab" htmlFor={"radio-button-" + buttonValue}>{props.title} {props.notificationNumber !== 0 && <span className="notification">{props.notificationNumber}</span>}</label>
                {props.values.length > 1 &&
                    <div key={"switch_" + props.value} className="view-switcher grow" onClick={() => switchView()} title="Switch between row and column view">
                        <span className="material-icons">
                            next_plan
                        </span>
                    </div>
                }
            </div>
        </>
    )

}