import React from 'react';
import './ButtonTabNavigation.css';
import {ButtonTabNavigationButton} from "./ButtonTabNavigationButton";

export const ButtonTabNavigation = (props) => {
    return (
        <>
            <div className="button-tab-navigation-container">
                {
                    props.tabs.map( tab => (
                        <div key={tab.value}>
                            <ButtonTabNavigationButton title={tab.title} icon={tab.icon} value={tab.value} tabChangeEventHandler={props.tabChangeEventHandler} selectedTabValue={props.value} enabled={tab.enabled} />
                        </div>
                    ))
                }
            </div>
        </>
    )
}