import React, {useState} from 'react';
import './ProgressionOverview.css';
import {LoadingIndicator} from "./LoadingIndicator";
import {useHttp} from "../../global-code/hooks/useHttp";
import {Calendar} from "primereact/calendar";
import {StrategicGoalOverview} from "./StrategicGoalOverview";
import UpdateHistory from "./UpdateHistory";
import {FilterButton} from "../navigation/FilterButton";
import {NodeToPngDialog} from "../system-components/NodeToPngDialog";
import { StrategicGoalAndKeyResultProgression } from "../report-components/strategic-goal-overview/StrategicGoalAndKeyResultProgression";
import {GetDifferenceInDatesInDays, GetDateXDaysAgo} from "../../global-code/hooks/useGenericFunctions";
import {ButtonTabNavigation} from "../navigation/ButtonTabNavigation/ButtonTabNavigation";

export const ProgressionOverview = props => {
    const [comparisonDate, setComparisonDate] = useState(GetDateXDaysAgo(30));
    const [showDownloadButtons, setShowDownloadButtons] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);

    const [isLoadingProgressionData, progressionData] = useHttp(
        process.env.REACT_APP_API_URL + 'KeyResults/ProgressionKeyResults/' + props.activeOrganisationId + '?tokenId=' + props.activeToken + '&cutOffNumberOfDaysAgo=' + GetDifferenceInDatesInDays(comparisonDate, new Date()) + "&startQuarterAndYear=" + props.startQuarterAndYear, 
        [props.startQuarterAndYear, props.activeOrganisationId, comparisonDate, props.keyResultsUpdatedFlag]
    );
    
    const [isLoadingTextUpdates, textUpdatesData] = useHttp(
        process.env.REACT_APP_API_URL + 'KeyResults/KeyResultsStatusUpdates/' + props.activeOrganisationId + '?tokenId=' + props.activeToken + '&cutOffNumberOfDaysAgo=' + GetDifferenceInDatesInDays(comparisonDate, new Date()),
        [props.startQuarterAndYear, props.activeOrganisationId, comparisonDate, props.keyResultsUpdatedFlag]
    )

    const getTabs = () => {
        return [
            { title: 'Strategy Progress View 1', icon: 'tour', value: 1, enabled: true},
            { title: 'Strategy Progress View 2', icon: 'sports_score', value: 2, enabled: true},
            { title: 'All status updates', icon: 'chat', value: 3, enabled: true}
        ];
    }

    const changeTab = (tabNumber) => {
        setSelectedTab(tabNumber);
    }

    function handleShowHideDownloadButtonsClick() {
        setShowDownloadButtons(!showDownloadButtons);
    }

    let content = "" 
        if (!progressionData) content = <LoadingIndicator message="Progression Overview Data"/>

    if (progressionData && textUpdatesData) {
        content = ( 
                    <>
                        <div className="progression-overview-container">
                            <div className={`filter-buttons-container ${props.showFilters ? '' : 'hidden-class'}`}>
                                <div className="filter-button-container">
                                    <label>Comparison date</label>
                                    <Calendar id="basic" value={comparisonDate} onChange={(e) => setComparisonDate(e.value)} style={{width: "130px"}} />
                                </div>
                            </div>
                            
                            <ButtonTabNavigation tabs={getTabs()} tabChangeEventHandler={changeTab} value={selectedTab} />
                            
                            <div className={"progress-overview-tab" + (selectedTab === 1 ? "" : " hide")}>
                                {progressionData.map((goal) => (

                                    <div key={"strategic-goal-and-key-results-overview-" + goal.id} id={"strategic-goal-and-key-results-overview-" + goal.id} className="strategic-goal-overview-render-container">

                                        { goal.objectives.length > 0 &&
                                            <>
                                                { goal.objectives.map (objective => (
                                                        <div key={"objective_container_" + objective.id} id={"objective_container_" + objective.id}>
                                                            { props.showFilters && goal.objectives.length > 0 && <div style={{paddingBottom: "55px"}}><NodeToPngDialog saveElementId={"wrapper1_" + objective.id} showBorderAroundImage={false} /></div> }


                                                            <div key={"wrapper1_" + objective.id} id={"wrapper1_" + objective.id} style={{paddingBottom: "5px"}}>
                                                                <StrategicGoalAndKeyResultProgression objectiveId={objective.id} strategicGoalTitle={goal.title} objectiveTitle={objective.title} data={objective.keyResults} spaceBetweenKeyResultsInPixels={20} comparisonDateText={comparisonDate} tokenId={props.activeToken} activeOrganisationId={props.activeOrganisationId} />
                                                                <div style={{marginTop: "20px"}}/>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        }
                                    </div>

                                ))}
                            </div>

                            <div className={"progress-overview-tab" + (selectedTab === 2 ? "" : " hide")}>
                                {progressionData.map((goal, index) => (

                                    <div key={"strategic-goal-overview-" + goal.id} id={"strategic-goal-overview-" + goal.id} className="strategic-goal-overview-render-container">
                                        { showDownloadButtons === true && goal.objectives.length > 0 && <div style={{paddingBottom: "55px"}}><NodeToPngDialog saveElementId={"wrapper_" + goal.id} showBorderAroundImage={true} /></div> }
                                        { goal.objectives.length > 0 &&
                                            <>
                                                <div key={"wrapper_" + goal.id} id={"wrapper_" + goal.id} style={{paddingTop: "20px"}}>
                                                    <StrategicGoalOverview key={progressionData[index].id} data={progressionData[index]} />
                                                </div>
                                                <div style={{height: "100px"}} />
                                            </>
                                        }
                                    </div>

                                ))}
                            </div>

                            <div className={"progress-overview-tab" + (selectedTab === 3 ? "" : " hide")}>
                                <div className="center-contents-vertically" style={{width: "100%", marginTop: "50px"}}>
                                    <div style={{width: "770px"}}>
                                        <UpdateHistory data={textUpdatesData} cutOffNumberOfDaysAgo={GetDifferenceInDatesInDays(comparisonDate, new Date())-1} activeToken={props.activeToken} />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </>
                )
    }
    
    return content;
}

