import React from 'react';
import './ToBeImplemented.css';
import logo from '../resources/basic-graphics/VisualOKRLogoBlack.png';
export const ToBeImplemented = props => {
    return (
        <>
            <div className="message-container">
                <div className="row">
                    <img src={logo} alt='Company Logo'/>
                    <div className="message-headline">Function is under<br/> implementation</div>
                </div>
            </div>
        </>
    );
}

