import React, {useState} from 'react';
import './KeyResultCardCompact.css';
import { Dialog } from 'primereact/dialog';
import {KeyResultFullInformation} from './KeyResultFullInformation';
import {EditKeyResult} from "../EditComponents/EditKeyResult";
import {Button} from "primereact/button";

export const KeyResultCardCompact = (props) => {

    const [displayFullInformation, setDisplayFullInformation] = useState(false);
    const [displayEdit, setDisplayEdit] = useState(false);

    function showDialog(e) {
        e.preventDefault();
        if (props.showEditOptions) {
            setDisplayEdit(true);
        } else {
            setDisplayFullInformation(true);
        }
    }
    
    function hideDialog() {
        if (props.showEditOptions) {
            setDisplayEdit(false);
        } else {
            setDisplayFullInformation(false);
        }
    }

    function submitForm(e) {
        document.getElementById('editKeyResult').dispatchEvent(new Event('submit', { 'bubbles' : true, 'cancelable': true }));
    }

    function renderHeader() {
        let text = (<><span>Key Result: {props.item.title}</span></>);
        return text;
    }
    
    const DeleteKeyResultOnServer = async (id) => {
        try {
            fetch(process.env.REACT_APP_API_URL + 'keyResults/DeleteKeyResult/' + id + '?tokenId=' + props.activeToken)
                .then(() => props.callAfterSave());
        } catch (err) {
            console.log(err);
        }
    }

    function ConfirmDeleteOfKeyResult(id) {
        if (window.confirm("Are you sure you wish to delete this key result?")) {
            DeleteKeyResultOnServer(id);
        }
    }

    return (
        <>

            <div id={props.item.id} daysSinceResultUpdated={props.item.daysSinceResultUpdated} key={"container_" + props.item.id} className={"keyresult-card-compressed-container" + (props.showLive && props.item.daysSinceResultUpdated > props.liveThreshold ? " faded" : "")} style={{borderLeft: '4px solid #' + props.item.levelColorHex}} onClick={showDialog} >
                { props.showEditOptions ? <div className="edit-symbol-container"><span className="material-icons-outlined edit-symbol">edit</span></div> : null}
                <div className={props.showEditOptions ? " faded" : ""}>
                    <div key={"card_title_" + props.item.id} className="card-title">{props.item.title}</div>
                    <div key={"percent_done_" + props.item.id} className="percent-done" style={{backgroundColor: '#' + props.item.levelColorHex}}>{props.item.percentDone}%</div>
                </div>
            </div>
            
            <Dialog  key={"status_dialog_" + props.item.id} header="" position="center" blockScroll={true} showHeader={true} visible={displayFullInformation} resizable={false} style={{ width: '830px', height: '950px;', backgroundColor: "#f1f5f9" }} onHide={hideDialog} dismissableMask={false}>
                <KeyResultFullInformation 
                    id={props.item.id} 
                    activeUserId={props.activeUserId} 
                    activeToken={props.activeToken} 
                    isUserAdministrator={props.isUserAdministrator} 
                    isUserReadOnly={props.isUserReadOnly} 
                    isUserUser={props.isUserUser}
                    callAfterSave={props.callAfterSave}
                />
            </Dialog>

            <Dialog key={"edit_dialog_" + props.item.id} header={renderHeader()} position="center" blockScroll={true} showHeader={true} visible={displayEdit} style={{ width: '844px', height: '950px' }} onHide={hideDialog} dismissableMask={false}>
                <EditKeyResult 
                    id={props.item.id} 
                    activeToken={props.activeToken} 
                    data={props.item} 
                    objectiveList={props.objectiveList} 
                    teamList={props.teamList} 
                    userList={props.userList} 
                    callAfterSave={props.callAfterSave} 
                    callToHideDialog={hideDialog} 
                    isUserAdministrator={props.isUserAdministrator} 
                    isUserReadOnly={props.isUserReadOnly} 
                    isUserUser={props.isUserUser}
                    showCloseButton={true}
                />
            </Dialog>
            
        </>
    )
}
