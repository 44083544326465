import Cookies from "universal-cookie";
import {useEffect, useState} from "react";
import {
    IsValueEmptyNullOrUndefined,
    IsValueEmptyOrUndefined,
    IsValueEmptyOrUndefinedThenUseThisValue
} from "./useGenericFunctions";

const queryParams = new URLSearchParams(window.location.search);

export const TOKEN_COOKIE_NAME = 'TOKEN_COOKIE';
export const ACTIVE_ORGANISATION_ID_COOKIE_NAME = 'ACTIVEORGANISATIONID';

export const isUserAdministrator = (userRolesArray, organisationId) => {
    let role = userRolesArray.filter(role => role.roleId.toUpperCase() === 'C918AE0D-D3E3-4585-BE52-D930443664CB' && role.organisationId.toUpperCase() === organisationId.toUpperCase());
    return role.length > 0;
};

export const isUserUser = (userRolesArray, organisationId) => {
    let role = userRolesArray.filter(role => role.roleId.toUpperCase() === '928322CF-C1BC-4FF9-B800-04BB7E15EC19' && role.organisationId.toUpperCase() === organisationId.toUpperCase());
    return role.length > 0;
};

export const isUserReadOnly = (userRolesArray, organisationId) => {
    let role = userRolesArray.filter(role => role.roleId.toUpperCase() === '1E046EA2-86F0-43D9-9B93-9F52795002AC' && role.organisationId.toUpperCase() === organisationId.toUpperCase());
    return role.length > 0;
};

export const deleteAllSessionVariablesAndCookies = () => {
    const cookies = new Cookies();
    cookies.remove(TOKEN_COOKIE_NAME, {path: '/', sameSite: 'lax'});
    cookies.remove(ACTIVE_ORGANISATION_ID_COOKIE_NAME, {path: '/', sameSite: 'lax'});
    sessionStorage.removeItem(TOKEN_COOKIE_NAME);
    sessionStorage.removeItem(ACTIVE_ORGANISATION_ID_COOKIE_NAME);
}

export async function userLogOut() {
    let token = getActiveToken();
    const cookies = new Cookies();
    cookies.remove(TOKEN_COOKIE_NAME, {path: '/', sameSite: 'lax'});
    sessionStorage.removeItem(TOKEN_COOKIE_NAME);
    cookies.remove(ACTIVE_ORGANISATION_ID_COOKIE_NAME, {path: '/', sameSite: 'lax'});
    sessionStorage.removeItem(ACTIVE_ORGANISATION_ID_COOKIE_NAME);
    var result = await deleteTokenOnServer(token);
    console.log(window.location.origin);
    if (result === 'success') {
        window.location.href = window.location.origin;
    }
}

const deleteTokenOnServer = async (tokenId) => {
    try {
        let res = await fetch(process.env.REACT_APP_API_URL + "security/DeleteToken/" + tokenId, {
            method: "GET",
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
        });

        let resJson = await res.text();

        if (res.status === 200) {
            return "success";
        }

    } catch (err) {
        console.log(err);
        return "error";
    }
}

/* Find a stored token either as a cookie or in the session variable or as a uri parameter */
export function getActiveToken() {
    let token = null;

    token = queryParams.get('tokenid');
    if (token != null && token != 'undefined') {
        return token;
    }

    const cookies = new Cookies();
    token = cookies.get(TOKEN_COOKIE_NAME);
    if (token != undefined && token != 'undefined') {
        return token;
    }

    token = sessionStorage.getItem(TOKEN_COOKIE_NAME);
    if (token != undefined && token != 'undefined') {
        return token;
    }

    return token;
}

export function saveToken(token) {
    const cookies = new Cookies();
    cookies.set(TOKEN_COOKIE_NAME, token, {path: '/', sameSite: 'lax'});
    sessionStorage.setItem(TOKEN_COOKIE_NAME, token);
}

export function getAndSetActiveOrganisationId() {
    let organisationIdFromUrl = queryParams.get('activeorganisationid');
    if (IsValueEmptyNullOrUndefined(organisationIdFromUrl) === false) {
        saveActiveOrganisation(organisationIdFromUrl);
        return organisationIdFromUrl;
    }

    let organisationIdFromSession = sessionStorage.getItem(ACTIVE_ORGANISATION_ID_COOKIE_NAME);
    if (IsValueEmptyNullOrUndefined(organisationIdFromSession) === false) {
        saveActiveOrganisation(organisationIdFromSession);
        return organisationIdFromSession;
    }

    const cookies = new Cookies();
    let organisationIdFromCookie = cookies.get(ACTIVE_ORGANISATION_ID_COOKIE_NAME);
    if (IsValueEmptyNullOrUndefined(organisationIdFromCookie) === false) {
        saveActiveOrganisation(organisationIdFromCookie);
        return organisationIdFromCookie;
    }

    return null;
}

export function saveActiveOrganisation(organisationId) {
    sessionStorage.setItem(ACTIVE_ORGANISATION_ID_COOKIE_NAME, organisationId);
    const cookies = new Cookies();
    cookies.set(ACTIVE_ORGANISATION_ID_COOKIE_NAME, organisationId, {path: '/', sameSite: 'lax'});
}

const getUserInformationFromBackend = async (tokenId) => {
    try {
        let res = await fetch(process.env.REACT_APP_API_URL + "security/GetUserInformation/" + tokenId, {
            method: "GET",
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
        });

        let resJson = await res.json();

        if (res.status === 200) {
            return resJson;
        }

        if (res.status === 401) {
            return null;
        }
    } catch (err) {
        return (err);
    }
}

export const CheckIfUserIsLoggedIn = (getUserInformation) => {
    const [userInformation, setUserInformation] = useState(null);
    const [isValidatingToken, setIsValidatingToken] = useState(false);

    useEffect(() => {
        let tokenId = getActiveToken();
        setIsValidatingToken(true);
        if (!IsValueEmptyNullOrUndefined(tokenId)) {
            fetch(process.env.REACT_APP_API_URL + "security/GetUserInformation/" + tokenId, {
                method: "GET",
                headers: {'Content-Type': 'application/json;charset=UTF-8'},
            }).then(response => {
                if (!response.ok) {
                    setIsValidatingToken(false);
                }
                return response.json();
            }).then(data => {
                if (data !== false) {
                    setUserInformation(data);
                }
                setIsValidatingToken(false);
            })
        } else {
            setIsValidatingToken(false);
        }
    }, [getUserInformation])

    return [isValidatingToken, userInformation];
}
