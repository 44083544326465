import React from 'react'
import {PageHeader} from "../components/navigation/PageHeader";
import './Help.css';
import {VideoCard} from "../components/content-elements/VideoCard";

const Help = props => {

    return (
        <React.Fragment>
            <>
                <PageHeader icon={props.iconName} pageName="help" organisationName={props.organisationTitle}/>
                {/*<Confirmdialog title="Are you sure?" question="Do you wish to delete this row?" cancelText="Cancel" confirmText="Delete" />*/}
                
                <div className="help-container">
                    <div className="video-container"><VideoCard title="The New OKR Crash Course: An introduction to Objectives & Key Results" width={420} height={315} youTubeId="EIcpFZ5rbHc" /></div>
                    <div className="video-container"><VideoCard title="OKR in 7 Simple Steps (Secrets From Successful Serial Founder)" width={420} height={315} youTubeId="xaH2tMQzyjA" /></div>
                    <div className="video-container"><VideoCard title="How to Set Good OKRs With Examples" width={420} height={315} youTubeId="qYAnz1PwpRA" /></div>
                    <div className="video-container"><VideoCard title="Why the secret to success is setting the right goals | John Doerr" width={420} height={315} youTubeId="L4N1q4RNi9I" /></div>
                </div>
            </>
        </React.Fragment>
    );
};

export {Help};