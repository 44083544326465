import React from 'react';
import './VideoCard.css';
import {Card} from "primereact/card";

export const VideoCard = (props) => {
    return (
        <div className="video-card-container">
            <Card>
                <iframe title={props.title} width={props.width} height={props.height} src={"https://www.youtube.com/embed/" + props.youTubeId}>
                </iframe>
            </Card>
        </div>
    )
}
