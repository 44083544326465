import React, {useEffect, useRef, useState} from 'react';
import './UserMultiSelectList.css';
import {OverlayPanel} from "primereact/overlaypanel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {UserImage} from "./UserImage";
import {InputText} from "primereact/inputtext";
import {useHttpOnCondition} from "../../global-code/hooks/useHttp";
import {getActiveToken} from "../../global-code/hooks/useSecurity";
import {Button} from "primereact/button";

export const UserMultiSelectList = (props) => {
    const [selectedUsers, setSelectedUsers] = useState(props.selectedUsers);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState(null);

    const initFilters = () => {
        setFilters({
            'global': { value: null, matchMode: "contains" },
            'name': { value: null, matchMode: "contains" },
        });
        setGlobalFilterValue('');
    }

    const clearFilter = () => {
        initFilters();
    }
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['name'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <span className="p-input-icon-left" style={{width: "100%"}}>
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" autoFocus={true} />
                </span>
            </div>
        )
    }
    
    const renderFooter = () => {
        return (<Button type="button" icon="pi pi-save" label="Save" className="p-button-outlined" onClick={CallSaveChanges} />)
    }
    
    const bodyUserImageTemplate = (rowData) => {
        return (
            <UserImage id={rowData.id} name={rowData.name} initials={rowData.initials} hasPhoto={rowData.hasPhoto} tokenId={props.tokenId} imageSize="35px" />
        )
    }
    
    const CallSaveChanges = () => {
        props.handleUserSelectionChange(selectedUsers);
    }
    
    useEffect(() => {
        initFilters();
    }, [])

    return (
        <div className="user-multi-select-list-container">
            <OverlayPanel id="user_multi_select_list" ref={props.op} showCloseIcon className="overlaypanel-demo" style={props.style}>
                <DataTable value={props.users} selectionMode="multiple" rows={5} scrollable scrollHeight="400px" selection={selectedUsers} onSelectionChange={e => setSelectedUsers(e.value)} dataKey="id" header={renderHeader()} footer={renderFooter()} filters={filters} >
                    <Column selectionMode="multiple" style={{maxWidth: "50px"}} headerStyle={{ maxWidth: '50px' }}></Column>
                    <Column body={bodyUserImageTemplate} style={{maxWidth: '70px'}} headerStyle={{maxWidth: '70px'}} />
                    <Column field="name" style={{verticalAlign: "middle"}} />
                </DataTable>
            </OverlayPanel>
        </div>
    )
}