import React from 'react';
import './ButtonTabNavigationButton.css';

export const ButtonTabNavigationButton = (props) => {
    let tab_selected_class = props.selectedTabValue === props.value ? " tab-selected" : "";
    let disabled_class = props.enabled ? "" : " tab-disabled";
    
    const handleClickEvent = (value) => {
        if (props.enabled) {
            props.tabChangeEventHandler(value);
        }
    }

    return (
        <>
            <div className={"button-tab-navigation-button-container center-contents-vertically" + tab_selected_class + disabled_class} onClick={() => handleClickEvent(props.value)}>
                <span className="icon material-symbols-outlined">
                    {props.icon}
                </span>
                <span className="title">{props.title}</span>
            </div>
        </>
    )
}