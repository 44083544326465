import React, {useState} from 'react';
import './UserList.css';
import {useHttp} from "../../global-code/hooks/useHttp";
import {LoadingIndicator} from "./LoadingIndicator";
import {UserListRow} from "./UserListRow";
import {RefreshAllImages} from "../../global-code/hooks/useGenericFunctions";

export const UserList = (props) => {
    const [refreshUserList, setRefreshUserList] = useState(false);
    const [isLoadingUsers, users] = useHttp(
        process.env.REACT_APP_API_URL + 'Users/OrganisationUsers/' + props.organisationId + "?tokenId=" + props.activeToken,
        [refreshUserList]
    );

    const [isLoadingRolesList, rolesList] = useHttp(
        process.env.REACT_APP_API_URL + 'Users/Roles/' + props.organisationId + "?tokenId=" + props.activeToken,
        []
    );


    function updateUserList() {
        setRefreshUserList(!refreshUserList);
        RefreshAllImages();
    }
    
    function GetEmptyUserObject() {
        return (
            {
                "id": "",
                "name": "",
                "initials": "",
                "primaryTeamTitle": "",
                "primaryTeamHexColor": "",
                "active": true,
                "userRoles": [{
                    roleId: "928322CF-C1BC-4FF9-B800-04BB7E15EC19",
                    organisationId: props.organisationId
                }]
            }
        )
    }
    
    let hideClass = props.visible ? "" : " hide";

    if (users && rolesList) {
        return (
            <>
                <div className={"user-list-container" + hideClass}>
                    {
                        users.map ( user => (
                            <UserListRow key={user.id} user={user} updateUserListFunction={updateUserList} rolesList={rolesList} teamList={props.teamList} organisationId={props.organisationId} activeToken={props.activeToken} isUserAdministrator={props.isUserAdministrator} addNew={false} />
                        ))
                    }
                    <UserListRow key={'new_user'} user={GetEmptyUserObject()} updateUserListFunction={updateUserList} rolesList={rolesList} teamList={props.teamList} organisationId={props.organisationId} activeToken={props.activeToken} isUserAdministrator={props.isUserAdministrator} addNew={true} />
                </div>
            </>
        );
    }
    
    return null;
};