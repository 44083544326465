import React from 'react';
import './KeyResultUpdateRow.css';
import {UserImageHtml} from "../../../global-code/hooks/useGenericFunctions";

export const KeyResultUpdateRow = (props) => {
    const DeleteKeyResultStatusUpdateOnServer = async (id) => {
        try {
            fetch(process.env.REACT_APP_API_URL + 'keyResults/DeleteKeyResultStatusUpdate/' + id + '?tokenId=' + props.tokenId)
                .then(() => props.callAfterDelete());
        } catch (err) {
            console.log(err);
        }
    }

    const ConfirmDeleteOfKeyResultStatusUpdate = (id) => {
        if (window.confirm("Are you sure you wish to delete this update?")) {
            DeleteKeyResultStatusUpdateOnServer(id);
        }
    }

    return (
        <div className="update-row">
            <div className="update-text" style={{backgroundColor: '#' + props.update.levelColorHex}}>
                <UserImageHtml userId={props.update.userHasPhoto ? props.update.createdByUserId : ""} tokenId={props.tokenId} initials={props.update.userInitials} className="updated-by-user-image" />
                <div className="update-percent">{props.update.percentDone}%</div>
                <div className="update-text-arrow " style={{borderTop: "40px solid #" + props.update.levelColorHex }}></div>
                <div className="wrap" style={{marginRight: "5px"}}>{props.update.updateText}</div>
            </div>
            <div className="update-information">
                Update by {props.update.createdByUserName} on the {props.update.friendlyDateText}
                { props.update.allowEditDelete ? <span className="material-icons-outlined edit-symbol" title="You can delete an update up to two days after it has been created" onClick={(e) => ConfirmDeleteOfKeyResultStatusUpdate(props.update.id)}>delete_outline</span> : null }
            </div>
        </div>
    )
}