import React, {useState} from 'react';
import './NodeToPngDialog.css';
import domtoimage from "dom-to-image";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {LoadingIndicator} from "../content-elements/LoadingIndicator";
import {InfoMessage} from "./InfoMessage";
import { saveAs } from 'file-saver';
import {
    browserBrandName,
    DoesBrowserSupportClipboard,
    setToClipboard
} from "../../global-code/hooks/useGenericFunctions";

export const NodeToPngDialog = props => {    
    const [showImageToCopy, setShowImageToCopy] = useState(false);
    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const [showCopyImageMessage, setShowCopyImageMessage] = useState(false);
    
    const snapShot = (sourceId, imageContainerId) => {
        let node = document.getElementById(sourceId);
        let position = node.style.position;
        let left = node.style.left;
        let scale = 4;

        domtoimage.toPng(node, {
            bgcolor: "white",
            height: node.offsetHeight * scale,
            width: node.offsetWidth * scale,
            style: {
                transform: "scale(" + scale + ")",
                transformOrigin: "top left",
                width: node.offsetWidth + "px",
                height: node.offsetHeight + "px",
                position: position,
                left: left
            }
        }).then((dataUrl) => {
            let img = document.getElementById(imageContainerId);
            img.src = dataUrl;
            setImageIsLoaded(true);
            showCopyMessage();
            });
    }
    
    const imageToClipboard = (sourceId, downloadButtonId) => {
        document.getElementById('root').style.cursor = 'wait';
        let currentBackgroundColor = document.getElementById(downloadButtonId).style.backgroundColor;
        document.getElementById(downloadButtonId).style.backgroundColor = '#cccccc';
        let node = document.getElementById(sourceId);
        let position = node.style.position;
        let left = node.style.left;
        let scale = 4;

        domtoimage.toBlob(node, {
            bgcolor: "white",
            height: node.offsetHeight * scale,
            width: node.offsetWidth * scale,
            style: {
                transform: "scale(" + scale + ")",
                transformOrigin: "top left",
                width: node.offsetWidth + "px",
                height: node.offsetHeight + "px",
                position: position,
                left: left
            }
        }).then(function (blob) {
            setToClipboard(blob);
            setImageIsLoaded(true);
            document.getElementById('root').style.cursor = 'default';
            document.getElementById(downloadButtonId).style.backgroundColor = currentBackgroundColor;
        });

    }

    const downloadImage = (sourceId, fileName, downloadButtonId) => {
        document.getElementById('root').style.cursor = 'wait';
        let currentBackgroundColor = document.getElementById(downloadButtonId).style.backgroundColor; 
        document.getElementById(downloadButtonId).style.backgroundColor = '#cccccc';
        let node = document.getElementById(sourceId);
        let position = node.style.position;
        let left = node.style.left;
        let scale = 4;

        domtoimage.toBlob(node, {
            bgcolor: "white",
            height: node.offsetHeight * scale,
            width: node.offsetWidth * scale,
            style: {
                transform: "scale(" + scale + ")",
                transformOrigin: "top left",
                width: node.offsetWidth + "px",
                height: node.offsetHeight + "px",
                position: position,
                left: left
            }
        }).then(function (blob) {
            saveAs(blob, fileName);
            setImageIsLoaded(true);
            document.getElementById('root').style.cursor = 'default';
            document.getElementById(downloadButtonId).style.backgroundColor = currentBackgroundColor;
        });
    }

    const renderFooter = () => {
        return (
            <div>
                <table style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td>
                                <InfoMessage title="Ready to copy" message="Right click image and choose » Copy Image «" visible={showCopyImageMessage} />
                            </td>
                            <td>
                                <div style={{float: "right"}}><Button id="close" label="Close" icon="pi pi-stop" className="p-button-info" style={{backgroundColor: "#1d8abb", border: "0px solid"}} onClick={() => setShowImageToCopy(false)} /></div>
                                <div style={{float: "right"}}><Button id={"download_image_" + props.saveElementId} label="Download Image" icon="pi pi-download" className="p-button-info" style={{backgroundColor: "#1d8abb", border: "0px solid"}} onClick={(e) => handleFileDownloadClick(e)} /></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    const showCopyMessage = () => {
        setShowCopyImageMessage(true);
    }

    const hideCopyMessage = () => {
        setShowCopyImageMessage(false);
    }
    
    const handleFileDownloadClick = (e) => {
        downloadImage(props.saveElementId, "StrategicGoal_" + props.saveElementId, e.currentTarget.id);
        setShowImageToCopy(false);
    }

    const handleContentCopy = (e) => {
        let id = e.currentTarget.id;
        if (browserBrandName() === 'safari') {
            snapShot(props.saveElementId, "image_for" + props.saveElementId);
            setShowImageToCopy(true);
        } else {
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
                console.log(result);
                if (result.state === "denied") {
                    navigator.clipboard.requestPermission( result => {
                        if (result === 'denied') {
                            console.log("Denies access to clipboard");
                        }
                        else {
                            console.log("Granted access to clipboard");
                        }
                    })
                } 
                if (result.state === "granted" || result.state === "prompt") {
                    imageToClipboard(props.saveElementId, id);
                } else {
                    setShowImageToCopy(true);
                }
            }).catch((error) => {
                console.log(error);
                snapShot(props.saveElementId, "image_for" + props.saveElementId);
                setShowImageToCopy(true);
            });
        }
    }
    
    return (
        <div className="node-to-png-component-container">
            <div className="node-to-png-dialog-container">
                <Button id={"copy_image_" + props.saveElementId} label="Copy graphic to Clip Board" icon="pi pi-copy" className="p-button-info" onClick={(e) => handleContentCopy(e)} style={{backgroundColor: "#1d8abb", border: "0px solid #1d8abb", marginRight: "10px"}} />
                <Button id={"download_image_" + props.saveElementId} label="Download graphic" icon="pi pi-download" className="p-button-info" onClick={(e) => handleFileDownloadClick(e)} style={{backgroundColor: "#1d8abb", border: "0px solid #1d8abb"}} />
            </div>
            
            <Dialog visible={showImageToCopy} onHide={() => setShowImageToCopy(false)} position="center" footer={renderFooter} showHeader={true} header="" blockScroll={true} className="image-dialog">
                <div style={{height: "30vw"}}>
                    <img id={"image_for" + props.saveElementId} alt="Strategic Goal To Copy" className="node-to-png-image-container" style={{display: imageIsLoaded ? "block" : "none", border: props.showBorderAroundImage ? "5px solid #cccccc" : "0 px" }} onClick={() => hideCopyMessage()} />
                    { imageIsLoaded === false && <LoadingIndicator message="Generating Image" /> }
                </div>
            </Dialog>
        </div>
    )

}