import React, {useEffect, useState} from 'react';
import {useHttp} from "../global-code/hooks/useHttp";
import {LoadingIndicator} from "../components/content-elements/LoadingIndicator";
import './ProgressTextUpdatesReport.css';
import UpdateHistory from "../components/content-elements/UpdateHistory";

const ProgressTextUpdatesReport = props => {
    const [isValidating, setIsValidating] = useState(false);
    const [isParametersValid, setIsParametersValid] = useState(false);
    const [organisationId, setOrganisationId] = useState(props.activeOrganisationId);
    const [cutOffNumberOfDaysAgo, setCutOffNumberOfDaysAgo] = useState('60');
    const queryParams = new URLSearchParams(window.location.search);

    const [isLoadingTextUpdates, textUpdatesData] = useHttp(
        process.env.REACT_APP_API_URL + 'KeyResults/KeyResultsStatusUpdates/' + organisationId + '?tokenId=' + props.activeToken + '&cutOffNumberOfDaysAgo=' + cutOffNumberOfDaysAgo,
        [props.activeOrganisationId]
    )

    function ValidateQueryParams() {
        let result = false;
        setIsValidating(true);
        if (queryParams.get('organisationid') !== null && queryParams.get('cutoffnumberofdaysago') !== null) {
            setOrganisationId(queryParams.get('organisationid'));
            setCutOffNumberOfDaysAgo(queryParams.get('cutoffnumberofdaysago'));
            result = true;
        }
        setIsValidating(false);
        return result;
    }

    useEffect( () => {
        setIsParametersValid(ValidateQueryParams());
    }, [])
    
    let content = <LoadingIndicator message="Loading Report Data" />
    
    if (!isLoadingTextUpdates && textUpdatesData && !isValidating) {
        content = (
            <>
                <div className="progress-text-updates-report-container center-contents-vertically-horizontally">
                    <UpdateHistory data={textUpdatesData} width="720px" cutOffNumberOfDaysAgo={cutOffNumberOfDaysAgo} activeToken={props.activeToken} />
                </div>
            </>
        )
    } else {
        content = (
            <>
                <div>Please state: organisationid, cutoffnumberofdaysago parameters</div>
            </>
        )
    }

    return content;
};

export default ProgressTextUpdatesReport;