import React, {useEffect, useState} from 'react';
import './KeyResultNewUpdate.css';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {
    IsObjectEmpty,
    IsValueEmptyNullOrUndefined,
    IsValueNumeric
} from "../../../global-code/hooks/useGenericFunctions";

export const KeyResultNewUpdate = (props) => {
    const [inputMode, setInputMode] = useState(false);
    const [errors, setErrors] = useState({});

    const postNewStatusUpdate = async () => {
        try {
            let res = await fetch(process.env.REACT_APP_API_URL + "keyResults/CreateUpdateKeyResultStatusUpdate", {
                method: "POST",
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    id: "",
                    updateText: values.newUpdateText,
                    measurableResult: values.newMeasurableResult,
                    createdByUserId: props.activeUserId,
                    keyResultId: props.keyResult.id,
                    tokenId: props.tokenId,
                }),
            });

            await res.json();

            if (res.status === 201) {
                props.callAfterSave();
                showEditElements(false);
            } else {
                alert("An error occurred. Your changes were not saved");
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    const showEditElements = (show) => {
        if (show) {
            setInputMode(true);
        }
        else {
            values.newUpdateText = "";
            values.newMeasurableResult = "";
            setInputMode(false);
        }
    }
    
    useEffect(() => {
        // Make sure all edit fields are visible when they are revealed by scrolling to the bottom
        let element = document.getElementsByClassName('content-body')[0];
        element.scrollTop = element.scrollHeight;
    },[inputMode])

    function validateFormInput(values) {
        let errors = {};

        if (IsValueEmptyNullOrUndefined(values.newUpdateText)) {
            errors.newUpdateText = "Please enter a text for the update";
        }

        if (!IsValueNumeric(values.newMeasurableResult) || values.newMeasurableResult < 0) {
            errors.newMeasurableResult = "Obtained value must be larger than zero";
        }

        return errors;
    }

    const [values, setValues] = useState({
        newUpdateText: "",
        newMeasurableResult: ""
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    const SaveNewStatusUpdate = () => {
        let errors = validateFormInput(values);
        setErrors(errors);

        if (IsObjectEmpty(errors)) {
            postNewStatusUpdate();
        }
    }

    return (
        <div className="add-new-key-result-update-container" style={{border: inputMode ? "1px  solid #cccccc" : ""}}>
            <div className="update-text-input-header">Enter your update story here</div>
            <InputTextarea id="newUpdateText" name="newUpdateText" value={values.newUpdateText} style={{width: "100%"}} onChange={handleChange} rows={2} cols={80} autoResize placeholder="Click here and tell the rest of your organisation how you are doing on achieving this Key Result and remember to update the number you have achieved" onFocus={(e) => showEditElements(true)} />
            <div>{errors.newUpdateText && <p className="error-message">{errors.newUpdateText}</p>}</div>
            <div className="obtained-result-container" style={{display: inputMode ? "block" : "none"}}>
                <div className="row center-contents-horizontally">
                    <span className="col1">
                        Obtained result so far
                    </span>
                    <span className="result-and-goal-input center-contents-vertically-horizontally col2" style={{backgroundColor: "#" + props.keyResult.levelColorHex }}>
                        {props.keyResult.measurableResult.toLocaleString('da-DK')}
                    </span>
                </div>
                <div className="row center-contents-horizontally">
                    <span className="col1">
                        Goal we're trying to reach
                    </span>
                    <span className="result-and-goal-input green-background center-contents-vertically-horizontally col2">
                        {props.keyResult.measurableGoal.toLocaleString('da-DK')}
                    </span>
                </div>
                <div className="row center-contents-horizontally">
                    <span className="col1">
                        Obtained <b>accumulated</b> result AFTER update:
                    </span>
                    <span className="result-and-goal-input center-contents-vertically-horizontally col2">
                         <input id="newMeasurableResult" name="newMeasurableResult" className="new-result" type="text" style={{width: "132px", margin: "0", padding: "8px", height: "30px"}} value={values.newMeasurableResult} onChange={handleChange} placeholder="Obtained value" />
                    </span>
                </div>
                <div className="row">
                    <span className="col1">
                        
                    </span>
                    <span className="col2">
                        {errors.newMeasurableResult && <p className="error-message">{errors.newMeasurableResult}</p>}
                    </span> 
                </div>
            </div>
            <div className="button-container center-contents-vertically-horizontally " style={{display: inputMode ? "flex" : "none", marginTop: "50px"}}>
                <Button id="save_new_update" icon="pi pi-save" className="p-button-rounded p-button-info p-button-outlined" aria-label="save" style={{marginLeft: "10px", width: "45px", height: "45px", float: "right", fontSize: "20px"}} onClick={() => SaveNewStatusUpdate()} title="Save" />
                <Button icon="pi pi-replay" className="p-button-rounded p-button-info p-button-outlined" aria-label="cancel" style={{marginLeft: "10px", width: "45px", height: "45px"}} onClick={() => setInputMode(false)} title="Cancel" />
            </div>
        </div>
    )
}