import React, {useEffect, useState} from 'react';
import {TabNavigationButton} from "./TabNavigationButton";
import './TabNavigation.css';

export const TabNavigation = props => {
    const [buttonSelected, setButtonSelected] = useState(props.value);
       
    function setSelectedButtonValue(value) {
        setButtonSelected(value);
        props.onChangeEvent(value);
    }
    
    useEffect( () => {
        setButtonSelected(props.value);
    }, [])
    
    return (
        <>
            
            <div className="tab-navigation-container">
                <div className="tabs">

                    {props.buttons.map( (button) => (
                        <div key={button.values}>
                            <TabNavigationButton 
                                values={button.values} 
                                title={button.title} 
                                notificationNumber={button.notificationNumber} 
                                selectedValue={buttonSelected} 
                                callOnClick={setSelectedButtonValue} 
                                defaultSubSelection={button.defaultSubSelection} 
                            />
                        </div>
                    ))}
                    <span className="glider" style={{transform: "translateX(" + (props.selectedTabIndex - 1) * 100 + "%)"}} />
                </div>
            </div>
            
        </>
    )
}