import React from 'react';
import './UpdateHistory.css';
import {GetFormattedDate, UserImageHtml} from "../../global-code/hooks/useGenericFunctions";


const UpdateHistory = props => {

    function GetPreviousUpdateColorHex(previousStatusUpdate) {
        if (previousStatusUpdate === null) {
            return props.ColorIndexForNoProgress;
        }
        
        return previousStatusUpdate.levelColorHex;
    }

    function GetCurrentDateMinusDays(days) {
        let d = new Date();
        d.setDate(d.getDate()-days);

        return d;
    }
    
    const updateData = props.data;

    return (
        <>
            <div className="update-history-container" style={{width: props.width === null ? "800px" : props.width}}>
                <div className="header">What has happend since {GetFormattedDate(GetCurrentDateMinusDays(props.cutOffNumberOfDaysAgo))} in your organisation</div>
                <div className="sub-header">
                    These are the updates from your organisation in the chosen time span across the different
                    Objectives and Key Results.
                </div>

                {updateData.map(update => (
                <div key={update.id}>

                    <div className="user-information-container">
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        <UserImageHtml userId={update.userHasPhoto ? update.createdByUserId : ""} tokenId={props.activeToken} initials={update.userInitials} className="user-image-container" /> 
                                    </td>
                                    <td>
                                        <div className="update-information-text">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            <div className="update-information-text-key-result-title">{update.keyResultTitle}</div>                
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            related to the objective
                                                            "<span className="update-information-text-objective-title">{update.objectiveTitle}</span>"
    
                                                            <div className="update-information-text-date-information">The update was made {update.friendlyDateText} ago by {update.createdByUserName}</div>
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                             
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="update-text wrap">
                        Opdatering: {update.updateText}
                        <div className="update-information-percent-container">
                            <div className="update-information-percent-confinement">
                                <span className="update-information-percent-present" style={{backgroundColor: "#" + update.levelColorHex}}>
                                    {update.percentDone}%
                                </span>
                            </div>
                            <div className="update-information-percent-confinement">
                                <span className="update-information-percent-before">
                                    {update.previousStatusUpdate === null ? 0 : update.previousStatusUpdate.percentDone}%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </>

    );

}

export default UpdateHistory