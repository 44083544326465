import React, {useContext, useState} from 'react';
import './EmployeeOverviewColumnBased.css';
import {KeyResultList} from "./KeyResultList";
import {LoadingIndicator} from "./LoadingIndicator";
import {EmployeeListHeader} from "./EmployeeListHeader";
import {globalStateContext} from "../../App";
import {useHttp} from "../../global-code/hooks/useHttp";
import {GetDisplaySlotsArray} from "../../global-code/hooks/useGenericFunctions";

export const EmployeeOverviewColumnBased = props => {
    const globalContext = useContext(globalStateContext);
    const [refreshOverview, setRefreshOverview] = useState(false);

    const getItemsForQuarterSlot = (employees, quarter, year) => {
        return employees.map((employee) => {
            return {
                ...employee,
                responsibleForKeyResults: employee.responsibleForKeyResults.filter((keyresults) =>
                    keyresults.quarter === quarter && keyresults.year === year
                ),
            }
        });
    }

    const refreshAfterSave = () => {
        setRefreshOverview(!refreshOverview);
        props.updateDependentData();
    }
    
    if (!props.employeesData) {
        return <LoadingIndicator message="Employee Overview Data" />;
    } else {
        return (
            <>
                <div className="employee-overview-container">
                    
                    <div className="employees-and-results-container">
                        <table>
                            <tbody>
                                <tr>
                                    <td>

                                    </td>
                                    {props.employeesData.map(employee => (
                                        <td key={employee.id}>
                                            <div className="employee-header-container">
                                                <EmployeeListHeader data={employee} tokenId={globalContext.activeTokenId} />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                {GetDisplaySlotsArray(props.startQuarterAndYear).map((slotData, index) => {
                                    return <React.Fragment key={slotData.quarter + "Q_Container"}>
                                        <tr>
                                            <td className="time-span-label-td">
                                                <div className="time-span-label-container">{slotData.quarter}. QUARTER {slotData.year}</div>
                                            </td>
                                            {getItemsForQuarterSlot(props.employeesData, slotData.quarter, slotData.year).map(employee => (
                                                <td key={employee.id} className="keyresult-list-td">
                                                    <KeyResultList 
                                                        id={employee.id} 
                                                        objectiveId={employee.id} 
                                                        items={employee.responsibleForKeyResults} 
                                                        timeSpanTitle="1. QUARTER" 
                                                        quarter={slotData} 
                                                        showResponsible={false} 
                                                        showLive={false} 
                                                        showEditOptions={false} 
                                                        liveThreshold={25}  
                                                        callAfterSave={refreshAfterSave}  
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                                <div className="spacer-row-between-time-periods" />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                })}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </>
        )
    }

}

