import React, {useEffect, useState} from 'react';
import Cookies from "universal-cookie";
import {confirmDialog} from "primereact/confirmdialog";

const {ClipboardItem} = window;

export const GetFormattedDate = (dateToBeFormatted) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const endings = ["st", "nd", "rd"];

    let result = months[dateToBeFormatted.getMonth()];
    let lastDigit = GetLastCharacters(dateToBeFormatted.getDate(), 1);

    result += " " + dateToBeFormatted.getDate();
    result += endings[lastDigit - 1] ? endings[lastDigit - 1] : "th";
    result += " " + dateToBeFormatted.getFullYear();

    return result;
}

export const GetLastCharacters = (myText, numberOfCharacters) => {
    let result = "";
    result = myText.toString();
    result = result.substring(result.length - numberOfCharacters);
    return result;
}

export const GetDifferenceInDatesInDays = (startDate, endDate) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    startDate = removeTime(startDate);
    endDate = removeTime(endDate);
    return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
}

export const removeTime = (date = new Date()) => {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
    );
}

export const GetDateXDaysAgo = (days) => {
    let d = new Date();
    d.setDate(d.getDate() - days);

    return d;
}

export const setToClipboard = async blob => {
    const data = [new window.ClipboardItem({['image/png']: blob})];
    await navigator.clipboard.write(data);
}

export const browserBrandName = () => {
    let browserName = "No browser detection";
    if (navigator.userAgentData) {
        for (let n = 0; n < navigator.userAgentData.brands.length; n++) {
            if ("chrome|chromium|crios|google chrome".indexOf(navigator.userAgentData.brands[n].toLower)) {
                browserName = "chrome";
            }
            if ("firefox|fxios".indexOf(navigator.userAgentData.brands[n].toLower) > 0) {
                browserName = "firefox";
            }
            if ("safari".indexOf(navigator.userAgentData.brands[n].toLower) > 0) {
                browserName = "safari";
            }
            if ("opr".indexOf(navigator.userAgentData.brands[n].toLower) > 0) {
                browserName = "opera";
            }
            if ("edg".indexOf(navigator.userAgentData.brands[n].toLower) > 0) {
                browserName = "edge";
                console.log("edg".indexOf(navigator.userAgentData.brands[n].toLower));
            }
        }
    } else {
        let userAgent = navigator.userAgent;
        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = "chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = "firefox";
        } else if (userAgent.match(/safari/i)) {
            browserName = "safari";
        } else if (userAgent.match(/opr\//i)) {
            browserName = "opera";
        } else if (userAgent.match(/edg/i)) {
            browserName = "edge";
        }
    }
    return browserName;
}

export const years = [
    {label: '2022', value: '2022'},
    {label: '2023', value: '2023'},
    {label: '2024', value: '2024'},
    {label: '2025', value: '2025'},
    {label: '2026', value: '2026'},
    {label: '2027', value: '2027'},
    {label: '2028', value: '2028'},
    {label: '2029', value: '2029'},
    {label: '2030', value: '2030'}
]

export const TimeSpanSelectorValues = () => {
    let result = [];

    for (let i = 2022; i <= 2032; i++) {
        result.push({label: 'Q1 ' + i, value: '01' + i})
        result.push({label: 'Q2 ' + i, value: '02' + i})
        result.push({label: 'Q3 ' + i, value: '03' + i})
        result.push({label: 'Q4 ' + i, value: '04' + i})
    }
    return result;
}

const getCurrentQuarter = () => {
    let date = new Date();
    return Math.floor(date.getMonth() / 3 + 1);
}

export const getQuarterFromDate = (date) => {
    return Math.floor(date.getMonth() / 3 + 1);
}

export const GetDefaultTimeSpanValue = () => {
    return "0" + getCurrentQuarter() + new Date().getFullYear().toString();
}

export const GetStartDateOfQuarter = (quarter, year) => {
    switch (quarter) {
        case 1:
            return new Date(year, 0, 1);
        case 2:
            return new Date(year, 3, 1);
        case 3:
            return new Date(year, 6, 1);
        case 4:
            return new Date(year, 9, 1);
        default:
            return new Date(1900, 1, 1);
    }
}

export const GetDisplaySlotsArray = (chosenQuarterAndYear) => {
    return [
        {quarter: GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 1), year: GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 1)},
        {quarter: GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 2), year: GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 2)},
        {quarter: GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 3), year: GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 3)},
        {quarter: GetQuarterFromSelectedTimeSpan(chosenQuarterAndYear, 4), year: GetYearFromSelectedTimeSpan(chosenQuarterAndYear, 4)}
    ];
};

export const GetYearFromSelectedTimeSpan = (timespanSelectorValue, timeslot) => {
    let startYear = Number(timespanSelectorValue.substring(2));
    let startQuarter = Number(timespanSelectorValue.substring(0, 2));
    let startDate = GetStartDateOfQuarter(startQuarter, startYear);
    return new Date(startDate.setMonth(startDate.getMonth() + (3 * (timeslot - 1)))).getFullYear();
}

export const GetQuarterFromSelectedTimeSpan = (timespanSelectorValue, timeslot) => {
    let startYear = Number(timespanSelectorValue.substring(2));
    let startQuarter = Number(timespanSelectorValue.substring(0, 2));
    let startDate = GetStartDateOfQuarter(startQuarter, startYear);
    return getQuarterFromDate(new Date(startDate.setMonth(startDate.getMonth() + (3 * (timeslot - 1)))));
}

export const DoesBrowserSupportClipboard = () => {
    navigator.permissions.query({name: "clipboard-write"}).then(result => {
        if (result.state === "granted" || result.state === "prompt") {
            return true;
        }
    });

    return false;
}

export const GetDummyGuid = () => {
    return 'FCCF163D-16FB-4A21-9E2F-10EC39984BDF';
}

export const UserImageHtml = (props) => {
    return (
        <div className={props.className} title={props.title} style={props.style} onClick={props.onClick}>
            {props.userId !== "" && <img src={process.env.REACT_APP_API_URL + "users/userphoto?tokenId=" + props.tokenId + "&userId=" + props.userId} alt={"User Image " + props.initials} style={{borderColor: "#" + props.borderColor}}/>}
            {props.userId === "" && <div>{props.initials}</div>}
        </div>
    )
}

export const UserImage = (props) => {
    return (
        <img src={process.env.REACT_APP_API_URL + "users/userphoto?tokenId=" + props.tokenId + "&userId=" + props.userId} alt={"User Image " + props.initials} className={props.className} style={props.style}/>
    )
}

const CompanyLogoLoadedWide = (e, widthIfWide, widthIfTall) => {
    let img = document.getElementById(e.target.id);
    let x = img.naturalWidth;
    let y = img.naturalHeight;
    if (x > y) {
        img.style.width = widthIfWide + "px";
    } else {
        img.style.width = widthIfTall + "px";
    }
}

export const CompanyLogoHtmlWide = ({id, tokenId, className, organisationId, organisationName, widthIfWide, widthIfTall}) => {
    if (organisationId !== "") {
        return (<img id={id} src={process.env.REACT_APP_API_URL + "organisations/organisationlogo?tokenId=" + tokenId + "&organisationId=" + organisationId} className={className} alt={organisationName} onLoad={(e) => CompanyLogoLoadedWide(e, widthIfWide, widthIfTall)}/>)
    }

    return "";
}

const CompanyLogoLoadedTall = (e, heightIfWide, heightIfTall) => {
    let img = document.getElementById(e.target.id);
    let x = img.naturalWidth;
    let y = img.naturalHeight;
    if (x > y) {
        img.style.height = heightIfWide + "px";
    } else {
        img.style.height = heightIfTall + "px";
    }
}

export const CompanyLogoHtmlTall = ({id, tokenId, className, organisationId, organisationName, callWhenLoaded, heightIfWide, heightIfTall}) => {
    if (organisationId !== "") {
        return (<img id={id} src={process.env.REACT_APP_API_URL + "organisations/organisationlogo?tokenId=" + tokenId + "&organisationId=" + organisationId} className={className} alt={organisationName} onLoad={(e) => CompanyLogoLoadedTall(e, heightIfWide, heightIfTall)}/>)
    }

    return "";
}

export const GetUriParam = (paramName) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(paramName);
}

export const GotoStartPage = () => {
    window.location.href = window.location.origin;
}

export const IsValueEmptyOrUndefined = (value) => {
    return (value === '' || value === undefined || value === 'undefined');
}

export const IsValueEmptyNullOrUndefined = (value) => {
    return (value === '' || value === undefined || value === 'undefined' || value === null || value === 'null');
}

export const IsValueEmptyOrUndefinedThenUseThisValue = (value, valueToUse) => {
    if (value === '' || value === undefined || value === 'undefined') {
        return valueToUse;
    }

    return value;
}

export const IsValueNumeric = (value) => {
    return !(/\D/.test(value));
}

export const PersistValue = (saveUnderName, value) => {
    const cookies = new Cookies();
    cookies.set(saveUnderName, value, {path: '/', sameSite: 'lax'});
}

export const GetPersistedValue = (getFromName, valueIfNoPreviousStoredValue) => {
    const cookies = new Cookies();
    let value = cookies.get(getFromName);
    if (IsValueEmptyOrUndefined(value)) {
        value = valueIfNoPreviousStoredValue;
    }
    return value;
}

// i.e. {}
export const IsObjectEmpty = (object) => {
    return Object.getPrototypeOf(object) === Object.prototype
}

export const ExtractString = (textToExtractFrom, leadText, endText) => {
    let startPos = textToExtractFrom.indexOf(leadText) + leadText.length;
    let endPos = endText === "" ? textToExtractFrom.length : textToExtractFrom.indexOf(endText, startPos);
    return textToExtractFrom.substring(startPos, endPos);

}

export const SetFocusOnElement = (elementId) => {
    document.getElementById(elementId).focus();
}

export const GetSelectItemsFromJsonList = (jsonList, labelColumnName, includeNotChosenItem) => {
    let listdata = [];

    if (includeNotChosenItem) listdata.push({label: 'Not chosen', value: '0'})
    jsonList.map(item => (
        listdata.push({label: item[labelColumnName], value: item.id})
    ));

    return listdata;
}

export const showMessage = (message) => {
    confirmDialog({
        message: message,
        header: 'System message',
        icon: 'pi pi-exclamation-triangle',
        accept: null,
        acceptLabel: 'OK',
        rejectLabel: 'Close'
    });
};

export const RefreshAllImages = (staticClassName) => {
    let images = document.getElementsByTagName('img');
    for (let i = 0; i < images.length; i++) {
        if (images[i].className.indexOf(staticClassName) === -1) {
            let srcUrl = images[i].src;
            images[i].src = srcUrl + "&v=" + new Date().getTime();
        }
    }
}

export const RoundNumber = (number) => {
    return Math.round(number);
}


