import React from 'react';
import './EmployeeListHeader.css';
import {UserImageHtml} from "../../global-code/hooks/useGenericFunctions";

export const EmployeeListHeader = props => {

    return (
        <>

            <div id={props.id} className="employee-list-header-container">
                <UserImageHtml userId={props.data.hasPhoto ? props.data.id : ""} tokenId={props.tokenId} initials={props.data.initials} className="employee-image" />
                <div className="name">{props.data.name}</div>
                <div className="subtitle">{props.data.primaryTeamTitle}</div>
                <div className="subtitle">{props.data.title}</div>
            </div>

        </>
    )

}