import React, {useState} from 'react';
import './KeyResultCard.css';
import { Dialog } from 'primereact/dialog';
import {UserImageHtml} from "../../global-code/hooks/useGenericFunctions";
import {KeyResultFullInformation2} from "./KeyResultFullInformation/KeyResultFullInformation2";

export const KeyResultCard = (props) => {

    const [displayFullInformation, setDisplayFullInformation] = useState(false);

    function showDialog(e) {
        e.preventDefault();
        setDisplayFullInformation(true);
    }
    
    function hideDialog() {
        setDisplayFullInformation(false);
    }

    return (
        <>

            <div id={props.item.id} key={"container_" + props.item.id} className={"keyresult-card-container" + (props.showLive && props.item.daysSinceResultUpdated > props.liveThreshold ? " faded" : "")} style={{borderLeft: '4px solid #' + props.item.levelColorHex}} onClick={showDialog} >
                <div key={"card_title_" + props.item.id} className="card-title" title={props.item.title}>{props.item.title}</div>
                <div key={"percent_done_" + props.item.id} className="percent-done" style={{backgroundColor: '#' + props.item.levelColorHex}}>{props.item.percentDone}%</div>
                { props.showResponsible && <UserImageHtml userId={props.item.responsiblePersonHasPhoto ? props.item.responsiblePersonId : ""} tokenId={props.activeToken} initials={props.item.responsiblePersonInitials} className="responsible-person" /> }
                <div key={"days_since_updated_" + props.item.id} className="days-since-updated">{props.item.daysSinceResultUpdatedText}</div>
            </div>

            <Dialog key={"status_dialog_" + props.item.id} contentStyle={{backgroundColor: "transparent", padding: "0"}} headerClassName="dialog-reset-header" header="" position="center" blockScroll={true} showHeader={false} visible={displayFullInformation} resizable={false} style={{ width: '800px', height: '950px', backgroundColor: "#f1f5f9", borderRadius: "15px" }} onHide={hideDialog} dismissableMask={false}>
                <KeyResultFullInformation2 id={props.item.id} callToHideDialog={hideDialog} callAfterSave={props.callAfterSave}>
                </KeyResultFullInformation2>
            </Dialog>
            
        </>
    )
}
